import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Main from './main';
import { AnalysisOptionEnum } from '../create/Analysis';
import WorkflowMonitor from './WorkflowMonitor';
import { ConfigSteps } from 'types';

interface analysisConfig {
  onNext: () => void;
  onChange: () => void;
  changeStep: (newStep: ConfigSteps) => void;
}
const AnalysisConfiguration: React.FC<analysisConfig> = ({
  onNext,
  onChange,
  changeStep
}) => {
  const [report, setReport] = useState<string>(AnalysisOptionEnum.MAIN);
  const [selectedReports, setSelectedReports] = useState<string[]>([]);

  const onCancel = () => {
    changeStep(ConfigSteps.ACTIVITY_ANNOTATION);
  };
  const onSelect = (selectedReport: string) => {
    setReport(selectedReport);
  };

  const onSave = (report: string) => {
    //setSelectedReports(prevSelectedReports => [...prevSelectedReports, report]);
  };

  return (
    <>
      <Grid>
        <WorkflowMonitor onCancel={onCancel} onSave={onSave} />
        {/* {report === AnalysisOptionEnum.MAIN && (
          <Main
            report={report}
            onSelect={onSelect}
            selectedReports={selectedReports}
            onNext={onNext}
          />
        )}

        {report === AnalysisOptionEnum.WORKFLOW_MONITORING && (
          <WorkflowMonitor onCancel={onCancel} onSave={onSave} />
        )} */}
      </Grid>
    </>
  );
};

export default AnalysisConfiguration;
