/* eslint-disable  */
import { Drawer, Grid, styled, useMediaQuery } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Appbar } from 'components';
import Navbar from 'components/sidebar';
import { useOnMobile } from 'hooks';

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: '64px',
  minHeight: 'calc(100vh - 64px)',
  overflow: 'auto',
  width: 'calc(100% - 15rem)',
  backgroundColor: '#ededed'
}));

const PrivateLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  // const isMobile = useOnMobile();
  const isMobile = useMediaQuery('(max-width:1200px)');

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Grid container>
      <Appbar
        mobile={isMobile}
        isMenuOpen={drawerOpen}
        onMenuClick={() => setDrawerOpen(!drawerOpen)}
      />

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={drawerOpen}
        anchor="left"
        style={{
          position: 'relative',
          zIndex: 1000,
          width: '200px'
        }}
        PaperProps={{
          sm: { width: '90%' }
        }}
        ModalProps={{ onBackdropClick: handleDrawerToggle }}
      >
        <Navbar />
      </Drawer>
      <Content>{children}</Content>
    </Grid>
  );
};

export default PrivateLayout;
