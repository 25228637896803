import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionProps as MuiAccordionProps
} from '@mui/material';
import { styled } from '@mui/system';

interface AccordionProps extends MuiAccordionProps {
  format?: 'underlined' | 'shadowed';
}

const Accordion = styled(MuiAccordion)<AccordionProps>(
  ({ theme, format = 'shadow' }) => ({
    ...(format === 'underlined' && {
      border: 'none',
      boxShadow: 'none !important',
      borderBottom: '1px solid #000',
      borderTop: 'none',
      marginBottom: '1px',
      borderRadius: '0 !important',
      margin: 0,
      '&:last-of-type': {
        borderBottom: 'none'
      },
      '&::before': {
        display: 'none'
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        // borderBottom: '1px solid #000'
      }
    })
  })
);

const Summary = styled(MuiAccordionSummary)<{
  format?: 'underlined' | 'shadowed';
}>(({ theme, format = 'shadow' }) => ({
  ...(format === 'underlined' && {
    '&.Mui-expanded': {
      borderBottom: '1px solid #000'
    }
  })
}));

export {
  Accordion,
  MuiAccordionDetails as AccordionDetails,
  Summary as AccordionSummary,
  AccordionProps
};
