import {
  MenuItem,
  Select,
  Skeleton,
  styled,
  Checkbox,
  ListItemText
} from '@mui/material';
import React, { useMemo } from 'react';

import { BaseInput, IBaseInputProps } from '../BaseInput';

export type ISelectValueType = any;

export interface ISelectInputOption {
  value?: string;
  label?: string;
}

export interface ISelectInputProps extends IBaseInputProps {
  options: ISelectInputOption[];
  value?: ISelectValueType;
  onChange?: (e: ISelectValueType) => void;
  light?: boolean;
  disabled?: boolean;
  defaultValue?: ISelectValueType;
  placeholder?: string;
  required?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  width?: string | number;
  maxWidth?: string | number;
  loading?: boolean;
  multiple?: boolean; // Added multiple prop
}

const StyledSelect = styled(Select)<
  Pick<ISelectInputProps, 'variant' | 'width' | 'maxWidth'>
>(({ theme, variant, width, maxWidth }) => ({
  width: width || '100%',
  maxWidth: maxWidth || '100%',
  marginTop: '5px',
  minWidth: '150px',
  backgroundColor: 'white',
  borderRadius: '6px',
  boxShadow: '2px 1px 5px 1px grey',
  borderColor: 'red',
  '&.MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: '6px',
      boxShadow: '2px 1px 5px 1px grey'
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: '6px',
      boxShadow: '2px 1px 5px 1px grey'
    }
  },
  '& .MuiList-root': {
    borderRadius: '6px'
  },
  ...(variant === 'outlined' && {
    boxShadow: '2px 1px 5px 1px grey',
    '& .MuiPaper-rounded': {
      borderRadius: '6px'
    }
  }),
  ...(variant === 'standard' && {
    boxShadow: '2px 1px 5px 1px grey'
  })
}));

export const SelectInput: React.FC<ISelectInputProps> = ({
  id,
  name,
  label,
  options,
  placeholder,
  disabled = false,
  loading,
  error,
  helperText,
  required,
  value,
  onChange,
  light,
  variant = 'standard',
  width,
  maxWidth,
  multiple = false // Added multiple prop with default false
}) => {
  const inputId = useMemo(() => id || name, [id, name]);

  return (
    <BaseInput
      id={id}
      name={name}
      label={label}
      required={required}
      helperText={helperText}
      error={error}
      light={light}
      width={width}
      fullwidth={!width}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={40} width={width || '100%'} />
      ) : (
        <StyledSelect
          name={name}
          id={inputId}
          disabled={disabled}
          error={!!error}
          required={required}
          value={value}
          placeholder={placeholder}
          onChange={e =>
            onChange &&
            onChange(
              multiple ? (e.target.value as string[]) : String(e.target.value)
            )
          }
          variant={variant}
          size="small"
          label={variant === 'outlined' ? label : undefined}
          width={width}
          maxWidth={maxWidth}
          multiple={multiple} // Added multiple prop to Select
          renderValue={selected =>
            multiple
              ? (selected as string[] || [])
                  ?.map(val => options.find(opt => opt.value === val)?.label)
                  .join(', ')
              : options.find(opt => opt.value === selected)?.label
          }
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {multiple && (
                <Checkbox
                  checked={(value as string[])?.includes(option.value!)}
                />
              )}
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </StyledSelect>
      )}
    </BaseInput>
  );
};

export default SelectInput;
