/* eslint-disable  */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ConfigSchema,
  ConfigurationCameraSchema,
  ImagesSchema,
  ObjSchemaResponse,
  PipelineStatusResponse,
  Point
} from 'schemas';
import { getVideoDuration } from 'utils';

import { colors, getRandomColor, stringToColorCode } from 'utils/color';

import type { RootState } from './store';

type ConfigCameraBase = Omit<
  ConfigurationCameraSchema,
  'roi' | 'pipeline_status'
>;

interface Roi {
  points: Point[];
  name: string;
  color: string;
  id: string;
  isVisible: boolean;
  isLocked: boolean;
  isEditing: boolean;
}

interface BoundingBox {
  images: string;
  index?: number;
  id: string;
}
interface ClipsSchema {
  id: string;
  start?: number;
  end?: number;
}
interface Activities {
  id: string;
  name: string;
  clips: ClipsSchema[];
}
export enum PipelineStatusEnum {
  QUEUED = 1,
  PROCESSING = 2,
  COMPLETED = 3,
  FAILED = 4,
  INTERRUPTING = 11
}

export interface PipelineStatus extends PipelineStatusResponse {
  label_assist_status: PipelineStatusEnum;
  lable_assist_progress: number;
}

interface ObjectImage
  extends Pick<ImagesSchema, 'image_thumb' | 'image_name'> {}

export interface ConfigCameras extends ConfigCameraBase {
  localPath?: string;
  videoFile?: File;
  roi: Roi[];
  boundingbox: BoundingBox[];
  activities: Activities[];
  clips: ClipsSchema[];

  pipeline_status?: PipelineStatus;
}

export interface ConfigState {
  id?: string;
  name?: string;
  number_of_cameras?: number;
  scene?: string;
  cameras?: ConfigCameras[];
  currentCamera?: ConfigCameras;
  currentCameraIndex?: number;
  objects?: ObjSchemaResponse[];
}

const initialState: ConfigState = {
  name: '',
  number_of_cameras: 0,
  scene: '',
  cameras: [],
  currentCameraIndex: 0,
  objects: []
};

export const configSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<ConfigState>) => {
      state = action.payload;
    },
    updateVideoPath: (
      state,
      action: PayloadAction<{
        cameraId: string;
        videoPath?: string;
        video_url?: string;
        low_res_url?: string;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          if (action.payload.videoPath) {
            camera.localPath = action.payload.videoPath;
          }
          if (action.payload.video_url) {
            camera.video_url = action.payload.video_url;
          }
          if (action.payload.low_res_url) {
            camera.lowres_url = action.payload.low_res_url;
          }
        }
      }
    },
    addCameras: (state, action: PayloadAction<ConfigCameras[]>) => {
      if (state.cameras) {
        state.cameras = [...state.cameras, ...action.payload];
      }
    },
    deleteCamera: (state, action: PayloadAction<string>) => {
      if (state.cameras) {
        state.cameras = state.cameras.filter(
          camera => camera.id !== action.payload
        );
      }
    },
    updateCameraName: (
      state,
      action: PayloadAction<{
        cameraId: string;
        name: string;
        roi?: any;
        crops?: any;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.name = action.payload.name;
          if (action.payload.roi !== undefined) {
            camera.roi = action.payload.roi;
          }
          if (action.payload.crops !== undefined) {
            camera.crops = action.payload.crops;
          }
        }
      }
    },
    setConfigFromApiResponse: (
      state,
      action: PayloadAction<{
        config: ConfigSchema;
        cameraFocus?: string;
      }>
    ) => {
      const { config, cameraFocus } = action.payload;
      state.id = config.id;
      state.name = config.name;
      state.scene = config.scene;
      state.cameras = config.cameras.map(camera => {
        const colorValues = Object.values(colors);

        return {
          ...camera,
          localPath: '',
          videoFile: undefined,
          video_duration: camera.video_path
            ? getVideoDuration(camera.video_path)
            : 0,
          roi: camera.roi.map((roi, idx) => {
            return {
              points: roi,
              isVisible: true,
              isLocked: true,
              isEditing: false,
              name: `ROI ${idx + 1}`,
              color:
                colorValues.length > idx ? colorValues[idx] : getRandomColor(),
              id: `${idx}`
            };
          }),
          boundingbox: [],
          activities: [],
          clips: [],
          lowres_url: camera.lowres_url,
          lowres_path: camera.lowres_path
        };
      });

      if (cameraFocus) {
        const cameraIndex = state.cameras.findIndex(
          camera => camera.id === cameraFocus
        );
        if (cameraIndex !== -1) {
          state.currentCameraIndex = cameraIndex;
        }
      }
    },
    setObjectFromApiResponse: (
      state,
      action: PayloadAction<{
        objects: ObjSchemaResponse[]; // Change this to an array
      }>
    ) => {
      state.objects = action.payload.objects; // Assuming you want to update the state with the array of objects
    },

    setConfigName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setConfigScene: (state, action: PayloadAction<string>) => {
      state.scene = action.payload;
    },
    setCurrentCamera: (state, action: PayloadAction<number>) => {
      state.currentCameraIndex = action.payload;

      if (
        state.cameras &&
        action.payload < state.cameras.length &&
        action.payload >= 0
      ) {
        state.currentCamera = state.cameras[action.payload];
      } else {
        state.currentCamera = undefined;
      }
    },
    applyToAll: (
      state,
      action: PayloadAction<{
        cropIndexes: number[];
        roiIndexes: number[];
      }>
    ) => {
      if (state.currentCameraIndex !== undefined && state.cameras) {
        const currentCamera = state.cameras[state.currentCameraIndex];
        const cropItems = action.payload.cropIndexes.map(
          idx => currentCamera.crops[idx]
        );
        const roiItems = action.payload.roiIndexes.map(
          idx => currentCamera.roi[idx]
        );

        state.cameras.forEach(camera => {
          if (camera.id !== currentCamera.id) {
            camera.crops = [...cropItems];
            camera.roi = currentCamera.roi.map((roi, idx) => {
              if (action.payload.roiIndexes.includes(idx)) {
                return roiItems[action.payload.roiIndexes.indexOf(idx)];
              }
              return roi;
            });
          }
        });
      }
    },
    addCropItem: (
      state,
      action: PayloadAction<{
        start: number;
        end: number;
      }>
    ) => {
      if (state.currentCameraIndex !== undefined && state.cameras) {
        state.cameras[state.currentCameraIndex].crops.push(action.payload);
      }
    },

    deleteCropItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.crops.splice(action.payload.idx, 1);
        }
      }
    },
    editCropItem: (
      state,
      action: PayloadAction<{
        idx: number;
        start: number;
        end: number;
      }>
    ) => {
      if (state.cameras) {
        if (state.currentCameraIndex !== undefined && state.cameras) {
          const crop =
            state.cameras[state.currentCameraIndex].crops[action.payload.idx];
          crop.start = action.payload.start;
          crop.end = action.payload.end;
        }
      }
    },
    toggleROI: (
      state,
      action: PayloadAction<{
        cameraId: string;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          const roi = camera.roi[action.payload.idx];
          roi.isVisible = !roi.isVisible;
        }
      }
    },
    deleteRoiItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        roiId: string; // Change idx to roiId
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          const roiIndex = camera.roi.findIndex(
            roi => roi.id === action.payload.roiId
          );
          if (roiIndex !== -1) {
            camera.roi.splice(roiIndex, 1);
          }
        }
      }
    },
    deleteBoundingBoxItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.boundingbox.splice(action.payload.idx, 1);
        }
      }
    },
    deleteClipItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.clips.splice(action.payload.idx, 1);
        }
      }
    },

    // other reducers...

    addRoiItem: (
      state,
      action: PayloadAction<{
        points: Point[];
        color: string;
        index?: number;
        id: number; // Add the 'id' property to the payload type
      }>
    ) => {
      if (
        state.cameras &&
        state.currentCameraIndex !== undefined &&
        state.currentCameraIndex >= 0
      ) {
        const camera = state.cameras[state.currentCameraIndex];

        if (camera && action.payload.index !== undefined) {
          const roi = camera.roi[action.payload.index];
          roi.points = action.payload.points;
          roi.isVisible = true;
          roi.isLocked = false;
          roi.isEditing = false;
        } else {
          camera.roi.push({
            id: action.payload.id.toString(), // Assign the 'id' from the payload
            points: action.payload.points,
            isVisible: true,
            isLocked: false,
            isEditing: false,
            name: 'ROI',
            color: action.payload.color
          });
        }
      }
    },
    addBondingBoxRoiItem: (
      state,
      action: PayloadAction<{
        images: string;
        index?: number;
        id: string | void; // Add the 'id' property to the payload type
      }>
    ) => {
      if (
        state.cameras &&
        state.currentCameraIndex !== undefined &&
        state.currentCameraIndex >= 0
      ) {
        const camera = state.cameras[state.currentCameraIndex];

        if (camera && action.payload.index !== undefined) {
          const boundingbox = camera.boundingbox[action.payload.index];
          boundingbox.images = action.payload.images;
        } else {
          camera.boundingbox.push({
            id: action.payload.id ?? '', // Assign the 'id' from the payload directly
            images: action.payload.images
          });
        }
      }
    },

    addClipItem: (
      state,
      action: PayloadAction<{
        start: number;
        end?: number;
        id: string | void; // Add the 'id' property to the payload type
      }>
    ) => {
      if (
        state.cameras &&
        state.currentCameraIndex !== undefined &&
        state.currentCameraIndex >= 0
      ) {
        const camera = state.cameras[state.currentCameraIndex];

        if (camera) {
          if (action.payload.id) {
            // If 'id' exists, update existing bounding box or add new one
            const ClipIndex = camera.clips.findIndex(
              box => box.id === action.payload.id
            );
            if (ClipIndex !== -1) {
              // If bounding box with same id exists, update it
              camera.clips[ClipIndex].start = action.payload.start;
              camera.clips[ClipIndex].end = action.payload.end;
            } else {
              // If bounding box with id not found, create a new one
              camera.clips.push({
                id: action.payload.id ?? '',
                start: action.payload.start ?? 0,
                end: action.payload.end ?? 0
              });
            }
          }
        }
      }
    },
    editRoiItem: (
      state,
      action: PayloadAction<{
        idx: number;
      }>
    ) => {
      if (
        state.cameras &&
        state.currentCameraIndex !== undefined &&
        state.currentCameraIndex >= 0
      ) {
        const camera = state.cameras[state.currentCameraIndex];
        if (camera) {
          camera.roi[action.payload.idx].isEditing = true;
        }
      }
    },
    updateObjectName: (
      state,
      action: PayloadAction<{
        id: string;
        name: string;
      }>
    ) => {
      // if (state.cameras && state.currentCameraIndex !== undefined) {
      //   const currentCamera = state.cameras[state.currentCameraIndex];
      //   const object = currentCamera.objects?.find(
      //     object => object.id === action.payload.id
      //   );
      //   if (object) {
      //     object.classname = action.payload.name;
      //     object.category = action.payload.category;
      //   }
      // }
    },
    updateActivityName: (
      state,
      action: PayloadAction<{
        id: string;
        name: string;
      }>
    ) => {
      //todo logical part after Backend Schema update
      if (state.cameras && state.currentCameraIndex !== undefined) {
        const currentCamera = state.cameras[state.currentCameraIndex];
        const activity = currentCamera.activities?.find(
          activity => activity.id === action.payload.id
        );
        if (activity) {
          activity.name = action.payload.name;
        }
      }
    },
    mergeObjects: (
      state,
      action: PayloadAction<{
        selectedObjects: string[];
        new_name: string;
        category: string;
      }>
    ) => {
      // if (state.cameras && state.currentCameraIndex !== undefined) {
      //   const currentCamera = state.cameras[state.currentCameraIndex];
      //   const objects = currentCamera.objects || [];
      //   const firstObject = objects.find(
      //     object => object.id === action.payload.selectedObjects[0]
      //   );
      //   const secondObject = objects.find(
      //     object => object.id === action.payload.selectedObjects[1]
      //   );
      //   if (firstObject && secondObject) {
      //     firstObject.classname = action.payload.new_name;
      //     firstObject.category = action.payload.category;
      //     firstObject.images = [
      //       ...(firstObject.images || []),
      //       ...(secondObject.images || [])
      //     ];
      //     // secondObject.images?.forEach(image => {
      //     //   firstObject.images?.push(image);
      //     // });
      //     objects.splice(
      //       objects.findIndex(object => object.id === secondObject.id),
      //       1
      //     );
      //   }
      // }
    },
    mergeActivities: (
      state,
      action: PayloadAction<{
        selectedActivities: string[];
        new_name: string;
      }>
    ) => {
      //todo logical part after Backend Schema update
    },

    deleteActivity: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      //todo logical part after Backend Schema update
      if (state.cameras && state.currentCameraIndex !== undefined) {
        const currentCamera = state.cameras[state.currentCameraIndex];
        const activities = currentCamera.activities || [];
        const activityIndex = activities.findIndex(
          activity => activity.id === action.payload.id
        );
        if (activityIndex !== -1) {
          activities.splice(activityIndex, 1);
        }
      }
    },
    deleteObjectImages: (
      state,
      action: PayloadAction<{
        objectId: string;
        images: string[];
      }>
    ) => {
      // if (state.cameras && state.currentCameraIndex !== undefined) {
      //   const currentCamera = state.cameras[state.currentCameraIndex];
      //   const objects = currentCamera.objects || [];
      //   const object = objects.find(
      //     object => object.id === action.payload.objectId
      //   );
      //   if (object && object.images) {
      //     object.images = object.images?.filter(
      //       image => !action.payload.images.includes(image.id)
      //     );
      //   }
      // }
    },
    deleteActivityClips: (
      state,
      action: PayloadAction<{
        ActivityId: string;
        clips: string[];
      }>
    ) => {
      //todo logical part after Backend Schema update
      if (state.cameras && state.currentCameraIndex !== undefined) {
        const currentCamera = state.cameras[state.currentCameraIndex];
        const activities = currentCamera.activities || [];
        const activity = activities.find(
          activity => activity.id === action.payload.ActivityId
        );
        if (activity && activity.clips) {
          activity.clips = activity.clips?.filter(
            clips => !action.payload.clips.includes(clips.id)
          );
        }
      }
    },
    deleteAllObjectImages: (state, action: PayloadAction<string>) => {
      // if (state.cameras && state.currentCameraIndex !== undefined) {
      //   const currentCamera = state.cameras[state.currentCameraIndex];
      //   const objects = currentCamera.objects || [];
      //   const object = objects.find(object => object.id === action.payload);
      //   if (object) {
      //     object.images = [];
      //   }
      // }
    },
    deleteAllActivityClips: (state, action: PayloadAction<string>) => {
      //todo logical part after Backend Schema update
      if (state.cameras && state.currentCameraIndex !== undefined) {
        const currentCamera = state.cameras[state.currentCameraIndex];
        const activities = currentCamera.activities || [];
        const activity = activities.find(
          activity => activity.id === action.payload
        );
        if (activity) {
          activity.clips = [];
        }
      }
    },
    moveImagesToOtherObject: (
      state,
      action: PayloadAction<{
        sourceObjectId: string;
        targetObjectId: string;
        images: string[];
      }>
    ) => {
      // if (state.cameras && state.currentCameraIndex !== undefined) {
      //   const currentCamera = state.cameras[state.currentCameraIndex];
      //   const objects = currentCamera.objects || [];
      //   const sourceObject = objects.find(
      //     object => object.id === action.payload.sourceObjectId
      //   );
      //   const targetObject = objects.find(
      //     object => object.id === action.payload.targetObjectId
      //   );
      //   if (sourceObject && targetObject) {
      //     const images = sourceObject.images?.filter(image =>
      //       action.payload.images.includes(image.id)
      //     );
      //     if (!images) return;
      //     if (!sourceObject.images) return;
      //     targetObject.images = [...(targetObject.images || []), ...images];
      //     sourceObject.images = sourceObject.images?.filter(
      //       image => !action.payload.images.includes(image.id)
      //     );
      //   }
      // }
    },
    updatePipelineStatus: (
      state,
      action: PayloadAction<
        Array<{
          id: string;
          status: number;
          objects?: ObjSchemaResponse[];
          progress?: number;
        }>
      >
    ) => {
      // if (state.cameras) {
      //   const statusMap: {
      //     [key: string]: {
      //       id: string;
      //       status: number;
      //       objects?: ObjectsSchema[];
      //       progress?: number;
      //     };
      //   } = {};
      //   action.payload.forEach(item => {
      //     statusMap[item.id] = item;
      //   });
      //   state.cameras.forEach(camera => {
      //     if (!statusMap[camera.id]) {
      //       return;
      //     }
      //     let progress = 0;
      //     if (statusMap[camera.id].status === 1) {
      //       progress = 0;
      //     } else if (statusMap[camera.id].status === 2) {
      //       progress = statusMap[camera.id].progress || 0;
      //     } else if (statusMap[camera.id].status === 3) {
      //       progress = 100;
      //     } else if (statusMap[camera.id].status === 4) {
      //       progress = camera.pipeline_status?.lable_assist_progress || 0;
      //     }
      //     const newStatus = {
      //       id: camera.pipeline_status?.id || '',
      //       label_assist_status: statusMap[camera.id].status,
      //       lable_assist_progress: progress
      //     };
      //     camera.pipeline_status = newStatus;
      //     if (statusMap[camera.id].objects) {
      //       camera.objects = statusMap[camera.id].objects || [];
      //     }
      //   });
      // }
    },

    resetLabelAssistStatus: state => {
      // if (state.cameras) {
      //   state.cameras.forEach(camera => {
      //     camera.pipeline_status = {
      //       id: camera.id,
      //       label_assist_status: PipelineStatusEnum.QUEUED,
      //       lable_assist_progress: 0
      //     };
      //   });
      // }
    }
  }
});

// Export actions
export const {
  setConfigFromApiResponse,
  setConfigName,
  setConfigScene,
  setCurrentCamera,
  applyToAll,
  addCropItem,
  updateVideoPath,
  deleteCamera,
  addCameras,
  toggleROI,
  deleteRoiItem,
  deleteBoundingBoxItem,
  deleteClipItem,
  deleteCropItem,
  editCropItem,
  addRoiItem,
  addBondingBoxRoiItem,
  addClipItem,
  editRoiItem,
  updateCameraName,
  updateObjectName,
  mergeObjects,

  deleteObjectImages,
  deleteAllObjectImages,
  moveImagesToOtherObject,
  updatePipelineStatus,
  updateActivityName,
  setObjectFromApiResponse,
  deleteActivity,
  deleteActivityClips,
  deleteAllActivityClips,
  mergeActivities,
  resetLabelAssistStatus
} = configSlice.actions;

// Export selectors
export const selectConfig = (state: RootState) => {
  return {
    id: state.config.id,
    number_of_cameras: state.config.number_of_cameras,
    scene: state.config.scene,
    name: state.config.name
  };
};

export const selectCameras = (state: RootState) => state.config.cameras;

export const selectCurrentCamera = (state: RootState) =>
  state.config.currentCamera;
export const selectCurrentCameraIndex = (state: RootState) =>
  state.config.currentCameraIndex;

export const selectLabelAssistPipelineStatus = (state: RootState) => {
  if (state.config.cameras) {
    return state.config.cameras.map(camera => {
      return {
        id: camera.id,
        status: camera.pipeline_status?.label_assist_status || null,
        progress: camera.pipeline_status?.lable_assist_progress || 0
      };
    });
  }
  return [];
};

export default configSlice.reducer;
