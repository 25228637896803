/* eslint-disable */
import { Input, Box, IconButton } from '@mui/material';
import { styled } from '@material-ui/core/styles';
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from 'react';

import { MessageLeft, MessageRight } from './message';

const ChatContainer = styled('div')({
  width: '100%',
  height: '100%',
  backgroundColor: '#f2f0f0',
  padding: '10px'
});

const MessagesBody = styled('div')({
  // width: 'calc( 100% - 20px )',
  overflowY: 'auto',
  maxHeight: 'calc( 100% - 80px )',
  minHeight: 'calc( 100vh - 300px )',
  border: 'none !important',
  background: 'none !important'
});

const SubmitContainer = styled('div')({
  width: 'calc( 100% - 20px )',
  position: 'sticky', // move the submit container to the bottom of the chat to bottom
  top: 'calc( 100% - 80px )'
});

const ChatInput = styled(Input)({
  width: '100%',
  marginTop: '5px',
  backgroundColor: 'transparent',
  border: 'none',
  '&:after, &:before': {
    display: 'none'
  }
});

export interface ChatMessage {
  message: string;
  timestamp: string;
  photoURL: string;
  displayName: string;
  avatarDisp: boolean;
  position: 'left' | 'right';
}

export interface ChatProps {
  messages: Array<ChatMessage>;
  onSendMessage?: (message: string) => void;
}

export const Chat: React.FC<ChatProps> = ({ messages, onSendMessage }) => {
  const [message, setMessage] = useState<string>('');

  const onSubmit = () => {
    if (message) {
      onSendMessage && onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <ChatContainer>
      <MessagesBody>
        {messages.map((message, index) => {
          if (message.position === 'left') {
            return (
              <MessageLeft
                message={message.message}
                timestamp={message.timestamp}
                photoURL={message.photoURL}
                displayName={message.displayName}
                avatarDisp={message.avatarDisp}
                key={index}
              />
            );
          } else {
            return (
              <MessageRight
                message={message.message}
                timestamp={message.timestamp}
                photoURL={message.photoURL}
                displayName={message.displayName}
                avatarDisp={message.avatarDisp}
                key={index}
              />
            );
          }
        })}
        <SubmitContainer>
          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Box
              sx={{
                display: 'flex',
                p: 0,
                bgcolor: '#fff',
                borderRadius: 0,
                width: '100%'
              }}
            >
              <ChatInput
                name="message"
                placeholder="Type a message"
                type="text"
                onChange={e => setMessage(e.target.value)}
                value={message}
              />
              <IconButton
                color="primary"
                aria-label="send message"
                component="span"
                onClick={onSubmit}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </form>
        </SubmitContainer>
      </MessagesBody>
    </ChatContainer>
  );
};
