import {
  EmployeeProductivityIcon,
  ProductThroughputIcon,
  SafetyGearMonitor,
  SecurityIcon,
  WorkflowMonitorIcon,
  InventoryManagementIcon,
  ProcessControl
} from 'components/icons';

const AnalysisOptions = [
  {
    label: 'Employee Productivity',
    value: 'EMPLOYEE_PRODUCTIVITY',
    icon: <EmployeeProductivityIcon />
  },
  {
    label: 'Product Throughput',
    value: 'PRODUCT_THROUGHPUT',
    icon: <ProductThroughputIcon />
  },
  {
    label: 'Workflow Monitoring',
    value: 'WORKFLOW_MONITORING',
    icon: <WorkflowMonitorIcon />
  },
  {
    label: 'SafetyGear Monitoring',
    value: 'SAFETYGEAR_MONITORING',
    icon: <SafetyGearMonitor />
  },
  {
    label: 'Inventory Management',
    value: 'INVENTORY_MANAGEMENT',
    icon: <InventoryManagementIcon />
  },
  {
    label: 'Security',
    value: 'SECURITY',
    icon: <SecurityIcon />
  },
  {
    label: 'Process Control',
    value: 'PROCESS_CONTROL',
    icon: <ProcessControl />
  },
  {
    label: 'Quality Control',
    value: 'QUALITY_CONTROL',
    icon: <ProcessControl />
  }
];
export const AnalysisOptionEnum = {
  MAIN: 'MAIN',
  EMPLOYEE_PRODUCTIVITY: 'EMPLOYEE_PRODUCTIVITY',
  PRODUCT_THROUGHPUT: 'PRODUCT_THROUGHPUT',
  WORKFLOW_MONITORING: 'WORKFLOW_MONITORING',
  SAFETY_GEAR_MONITORING: 'SAFETYGEAR_MONITORING',
  INVENTORY_MANAGEMENT: 'INVENTORY_MANAGEMENT',
  SECURITY: 'SECURITY',
  PROCESS_CONTROl: 'PROCESS_CONTROl',
  QUALITY_CONTROL: 'QUALITY_CONTROl'
};
export default AnalysisOptions;
