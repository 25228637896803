export interface User {
  id: string;
  email: string;
  username: string;
}

export interface UserContext {
  user: User | null;
  setUser: (user: User | null) => void;
  isLogged: boolean;
  setIsLogged: (isLogged: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  token: string | null;
  setToken: (token: string | null) => void;
}

export interface VideoMetadata {
  duration: number;
  resolution: {
    height: number;
    width: number;
  };
}

export enum ConfigSteps {
  GENERAL_CAMERA_AND_VIDEO = 'GENERAL_CAMERA_AND_VIDEO',
  ROI = 'ROI',
  OBJECT_CONFIGURATION = 'OBJECT_CONFIGURATION',
  OBJECT_ANNOTATION = 'OBJECT_ANNOTATION',
  ACTIVITY_CONFIGURATION = 'ACTIVITY_CONFIGURATION',
  ACTIVITY_ANNOTATION = 'ACTIVITY_ANNOTATION',
  // REPORTS_CONFIGURATION = 'REPORTS_CONFIGURATION',
  WORKFLOW_ANNOTATION = 'WORKFLOW_ANNOTATION',
  DASHBOARD_CONFIGURATION = 'DASHBOARD_CONFIGURATION',
  ANALYSIS_CONFIGURATION = 'ANALYSIS_CONFIGURATION'
}

export enum PipelineStatus {
  QUEUED = 1,
  PROCESSING = 2,
  COMPLETED = 3,
  FAILED = 4,
  TRAINING = 5,
  UNSET = 6
}
