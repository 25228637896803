import { useState, useEffect } from 'react';
import { useWebSocket } from './websocket';
import Env from 'config/env';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export const useInferenceSocket = () => {
  const siteId = useSelector(
    (data: RootState) => data?.auth?.profile?.session_site?.id
  );

  const socketUrl = siteId
    ? `${Env.WEBSOCKET_URL}/ws/inference/live/${siteId}`
    : '';

  const { socket, connectionStatus, sendMessage } = useWebSocket(socketUrl);

  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', (event: any) => {
        if (event.data instanceof Blob) {
          return;
        }

        const message = JSON.parse(event.data);
        handleMessageReceived(message);
      });
    }
  }, [socket]);

  const handleMessageReceived = (message: any) => {
    const messageType = message?.type;
    const data = message?.data;
  };

  return {
    socket,
    connectionStatus,
    sendMessage
  };
};
