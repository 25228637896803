// Dependency imports
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Slider,
  Stack,
  Switch,
  Tab,
  Tabs
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { useWebSocket } from 'hooks';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Description } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

// Local imports
import { RootState } from 'store';
import AnnotationTable from './annotationTable';
import { Button, Typography, Card, Modal } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SelectInput } from 'components/Form';
import {
  useAddToActivityInference,
  useListConfigurations,
  useListInferenceModels
} from 'api/sdk';
import { AppDispatch, selectSite } from 'store';
import { useSnack } from 'plugins/snack';
import Env from 'config/env';
import SearchSelect from 'components/SearchSelect';
import {
  consumeWebsocketMessage,
  fetchVideos,
  selectInferenceState,
  updateConfigId,
  uploadFiles
} from 'store/inference';
import LiveTable from './liveTable';
import { InferenceMode } from 'schemas';
import { setPreventNavigation } from 'store/preventNavigation/preventNavigationSlice';
import useVideo from 'hooks/video';
import { NotificationList } from './notificationList';

// Styles imports
import './styles.css';

const WorkFlowAnnotation = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const { data: configs } = useListConfigurations();
  const siteId = useSelector(
    (data: any) => data?.auth?.profile?.session_site?.id
  );
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference.currentConfigId
  );
  const [videoReportQuery, setVideoReportQuery] = useState<any>();
  const [videoName, setVideoName] = useState<string | null>('');
  const [isPlay, setPlay] = useState<boolean>(false);
  const [isPause, setPause] = useState<boolean>(true);
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [modelOpen, setModelOpen] = useState<boolean>(false);
  const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [value, setValue] = useState(0);
  const [getCurrentTime, setCurrentTime] = useState<number>(0);
  const [currentVideo, setCurrentVideo] = useState<string | null>();
  const [heatmapVideo, setHeatmapVideo] = useState<string | null>(null);
  const [lastTime, setLastTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const { videoRef, seek, reset } = useVideo();
  const [getVideoCurrentTime, setVicdeoCurrentTime] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);
  const { loading, videos } = useSelector(selectInferenceState);
  const site = useSelector(selectSite);
  const snack = useSnack();
  const dispatch = useDispatch<AppDispatch>();
  const [isVideoUploading, setIsVideoUploading] = useState<boolean>(false);
  const [toggleVideo, setToggleVideo] = useState<boolean>(false);
  const ws_url = `${Env.WEBSOCKET_URL}/ws/inference/${site?.id}`;
  const { socket } = useWebSocket(ws_url);
  const [isInferedVideo, setIsInferedVideo] = useState(false);
  const [loadingProgress, setLoading] = useState<boolean>(true);
  /**
   * handle messages received from websocket
   */
  const handleMessagesReceived = (message: any) => {
    dispatch(consumeWebsocketMessage(message));
  };

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', (event: any) => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
    return () => {
      if (socket) {
        socket.removeEventListener('message', handleMessagesReceived);
        socket.close();
      }
    };
  }, [socket?.url]);

  const [error, setError] = useState<string>('');

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handlePlay = () => {
    setPlay(false);
    setPause(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    setPlay(true);
    setPause(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const {
    mutateAsync: addActivityInference,
    isLoading: addActivityInferenceLoading,
    error: addActivityInferenceError
  } = useAddToActivityInference();

  const {
    data: models,
    refetch: refetchModels,
    isLoading: modelsLoading
  } = useListInferenceModels(
    siteId,
    {
      config_id: currentConfigId
    },
    {
      query: {
        enabled: !!currentConfigId
      }
    }
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentConfigId === 'Select Configuration') {
      setError('Please select configuration');
      return;
    }
    setError('');
    const files = event.target.files;
    if (!files) return;
    dispatch(
      uploadFiles({
        configuration_id: currentConfigId || '',
        files,
        model_id: selectedModel,
        site_id: site?.id || '',
        showDuplicateError
      })
    );
    event.target.value = '';
  };

  useEffect(() => {
    setPause(true);
    setPlay(false);
    setToggleVideo(false);
  }, [videoModalOpen]);

  const showDuplicateError = (message: string) => {
    snack({
      message: message,
      severity: 'error'
    });
  };

  useEffect(() => {
    if (currentConfigId !== 'Select Configuration') {
      console.log('its here');
      refetchModels();
      setSelectedModel('');
      setSelected([]);
      setModelOpen(false);
    }
  }, [currentConfigId]);

  useEffect(() => {
    if (currentConfigId) {
      dispatch(
        fetchVideos({
          config_id: currentConfigId,
          mode: value === 0 ? InferenceMode.OFFLINE : InferenceMode.LIVE
        })
      );
    }
  }, [value, currentConfigId]);

  useEffect(() => {
    const checkBuffering = () => {
      const video = videoRef.current;
      if (video) {
        if (
          videoDuration !== 0 &&
          (video.currentTime === videoDuration ||
            video.currentTime > videoDuration)
        ) {
          setPlay(true);
          setPause(false);
        }

        if (video.currentTime > videoDuration) {
          setLoading(false);
        } else if (
          video.currentTime === lastTime &&
          !isPlay &&
          video.currentTime !== videoDuration
        ) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        setLastTime(video.currentTime);
      }
    };

    const interval = setInterval(checkBuffering, 500); // Check every 500ms

    return () => {
      clearInterval(interval);
    };
  }, [lastTime]);

  function videoCurrentTime(event: SyntheticEvent<HTMLVideoElement, Event>) {
    setVicdeoCurrentTime(event?.currentTarget?.currentTime);
  }

  const handleToggleChange = () => {
    setToggleVideo(!toggleVideo);
    if (videoRef.current) {
      reset(
        toggleVideo === false
          ? `${heatmapVideo}#t=${getVideoCurrentTime}`
          : `${currentVideo}#t=${getVideoCurrentTime}`
      );
      seek(getVideoCurrentTime);
    }

    handlePause();

    setTimeout(() => {
      if (videoRef.current) {
        handlePlay();
        videoRef.current.currentTime = getVideoCurrentTime;
      }
    }, 1000);
  };

  // Setting data to the table on videos change
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < videos.length; i++) {
      if (
        videos[i]['upload_progress'] &&
        (videos as any)[i]['upload_progress'] < 100
      ) {
        setIsVideoUploading(true);
      } else {
        setIsVideoUploading(false);
      }
      let obj: any = {};
      obj['id'] = videos[i]['id'];
      obj['configuration_id'] = videos[i]['configuration_id'];
      obj['created_at'] = videos[i]['created_at']
        ? moment
            .utc(videos[i]['created_at'])
            .local()
            .format('MMM DD, YY, h:mm a')
        : '-';
      obj['duration'] = videos[i]['duration'];
      obj['fail_reason'] = videos[i]['fail_reason'];
      obj['graph_data'] = videos[i]['graph_data'];
      obj['inference_end_time'] = videos[i]['inference_end_time']
        ? moment
            .utc(videos[i]['inference_end_time'])
            .local()
            .format('MMM DD, YY, h:mm a')
        : '-';
      obj['inference_start_time'] = moment
        .utc(videos[i]['inference_start_time'])
        .local()
        .format('MMM DD, YY, h:mm a');
      obj['model'] = videos[i]?.model?.model_name;
      obj['name'] = videos[i]['name'];
      obj['output_url'] = videos[i]['output_url'];
      obj['output_video'] = videos[i]['output_video'];
      obj['output_video_url'] = videos[i]['output_video_url'];
      obj['status'] = videos[i]['status'];
      obj['upload_progress'] = videos[i]['upload_progress'];
      obj['process_progress'] = videos[i]['process_progress'];
      obj['upload_path'] = videos[i]['upload_path'];
      obj['video_url'] = videos[i]['video_url'];
      const isSequenceTrue = (videos[i] as any)?.graph_data?.sequence?.every(
        (seq: any) => seq.every((val: boolean) => val)
      );
      obj['seq_check'] =
        isSequenceTrue === true
          ? 'Valid'
          : isSequenceTrue === false
          ? 'Invalid'
          : '-';
      temp.push(obj);
    }
    setRows(temp);
  }, [videos]);

  // UseEffect to handle navigation on uploading status
  useEffect(() => {
    if (isVideoUploading) {
      dispatch(setPreventNavigation(true));
    } else {
      dispatch(setPreventNavigation(false));
    }

    return () => {
      if (isVideoUploading) {
        dispatch(setPreventNavigation(false));
      }
    };
  }, [isVideoUploading]);

  useEffect(() => {
    if (models?.data && models?.data.length > 0) {
      setSelectedModel(models.data[0].id);
    }
  }, [models]);

  // UseEffect to Handle refresh and backpress on uploading
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isVideoUploading) {
        const confirmationMessage =
          'A video is currently being uploaded. If you leave or refresh the page, the upload will be lost. Are you sure you want to proceed?';
        event.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage; // Required for some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isVideoUploading]);

  // Function to handle video slider change
  const handleSliderChange = (event: Event, value: number | number[]) => {
    const newTime = Array.isArray(value) ? value[0] : value;

    if (newTime === videoDuration) {
      setLoading(false);
    }
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setVicdeoCurrentTime(newTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      setVideoDuration(videoRef.current.duration);
    }
  };

  const handleClickStart = () => {
    addActivityInference({
      configId: currentConfigId || '',
      params: {
        site_id: site?.id || ''
      },
      data: [
        ...selected.map(s => {
          return {
            model_id: selectedModel,
            video_id: s
          };
        })
      ]
    }).then(response => {
      if (response.status === 200) {
        console.log('its here');
        setSelected([]);
        setModelOpen(false);
        if (currentConfigId) {
          dispatch(
            fetchVideos({
              config_id: currentConfigId,
              mode: value === 0 ? InferenceMode.OFFLINE : InferenceMode.LIVE
            })
          );
        }

        snack({
          message: 'Inference started successfully',
          severity: 'success'
        });
      }
    });
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log('new Value', newValue);
    setValue(newValue);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'left'}
          >
            {configs && (
              <SearchSelect
                label="Select Configuration"
                onChange={(value: any) => {
                  appDispatch(updateConfigId(value));
                }}
                selectValue={currentConfigId || ''}
                data={[
                  ...(configs?.data.map((config: any) => ({
                    label: config.name,
                    value: config.id
                  })) || [])
                ]}
              />
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            {currentConfigId !== 'Select Configuration' && (
              <>
                <Button
                  onClick={() =>
                    navigate('/reports/recorded', {
                      state: currentConfigId
                    })
                  }
                  version="light"
                  actionType={'negative'}
                  style={{
                    float: 'right'
                  }}
                >
                  <Description sx={{ marginRight: '6px' }} />
                  Reports
                </Button>
                <Button
                  onClick={() => navigate(`/inference/live`)}
                  style={{
                    float: 'right'
                  }}
                >
                  <>
                    <VideoCameraBackIcon sx={{ marginRight: '6px' }} />
                    Live
                  </>
                </Button>
                <Button
                  onClick={handleButtonClick}
                  style={{
                    float: 'right'
                  }}
                >
                  <>
                    <AddIcon sx={{ marginRight: '6px' }} />
                    Video
                  </>
                </Button>
              </>
            )}
          </Stack>

          <input
            type="file"
            ref={fileInputRef}
            accept="video/*"
            multiple
            capture="user"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Stack>
        <Grid item xs={12} marginTop={'10px'}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Offline"
                    sx={{ fontSize: '14px', fontWeight: 'bold' }}
                  />
                  <Tab
                    label="Live"
                    sx={{ fontSize: '14px', fontWeight: 'bold' }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <AnnotationTable
                  rows={rows}
                  loading={loading}
                  selected={selected}
                  setSelected={setSelected}
                  handleButtonClick={handleButtonClick}
                  isVideoUploading={isVideoUploading}
                  setVideoModalOpen={setVideoModalOpen}
                  setVideoName={setVideoName}
                  setCurrentVideo={setCurrentVideo}
                  setHeatmapVideo={setHeatmapVideo}
                  setVideoReportQuery={setVideoReportQuery}
                  setIsInferedVideo={setIsInferedVideo}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <LiveTable
                  rows={rows}
                  loading={loading}
                  selected={selected}
                  setSelected={setSelected}
                  handleButtonClick={handleButtonClick}
                  setVideoModalOpen={setVideoModalOpen}
                  setVideoName={setVideoName}
                  setCurrentVideo={setCurrentVideo}
                  setVideoReportQuery={setVideoReportQuery}
                  setIsInferedVideo={setIsInferedVideo}
                />
              </CustomTabPanel>
            </Box>
          </Card>

          <Alert severity="error" style={{ display: error ? 'block' : 'none' }}>
            {error} Please select configuration and model
          </Alert>
        </Grid>
        {currentConfigId !== 'Select Configuration' && (
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            marginTop={'20px'}
          >
            <Button
              children={'Start Inference'}
              disabled={selected.length === 0}
              onClick={() =>
                models?.data.filter(item => {
                  if (item.status === 'COMPLETED') {
                    return item;
                  }
                }).length
                  ? setModelOpen(true)
                  : snack({
                      message:
                        'No trained model found, Please train the model to start the inference.',
                      severity: 'warning'
                    })
              }
              size={'medium'}
              style={{
                background: '#2AB5B7',
                opacity: selected.length > 0 ? 1 : 0.6,
                cursor: selected.length > 0 ? 'pointer' : 'not-allowed'
              }}
            />
          </Grid>
        )}
      </Grid>
      <Modal
        open={modelOpen}
        onClose={() => setModelOpen(false)}
        title="Select Model"
      >
        <Stack spacing={2}>
          <SelectInput
            name="model"
            options={
              models?.data
                .filter(item => {
                  if (item.status === 'COMPLETED') {
                    return item;
                  }
                })
                .map(model => ({
                  label: model.model_name,
                  value: model.id
                })) || []
            }
            value={selectedModel}
            variant="outlined"
            loading={modelsLoading}
            onChange={value => setSelectedModel(value)}
          />

          <Button
            children={'Proceed'}
            onClick={handleClickStart}
            disabled={!selectedModel}
            loading={addActivityInferenceLoading}
            size={'medium'}
          />
          {addActivityInferenceError && (
            <Alert severity="error">{addActivityInferenceError.message}</Alert>
          )}
        </Stack>
      </Modal>
      <Modal
        open={videoModalOpen}
        onClose={() => {
          setPlay(false);
          setPause(true);
          setVideoModalOpen(false);
          setIsInferedVideo(false);
          if (videoRef.current) {
            videoRef.current.pause();
            setVicdeoCurrentTime(0);
          }
        }}
        // title={'Video Player'}
        size="xl"
      >
        {currentVideo && (
          <div>
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>{videoName}</Typography>
                {isInferedVideo && (
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={toggleVideo}
                            onChange={handleToggleChange}
                            name="toggleVideo"
                            color="primary"
                            disabled={value === 1}
                          />
                        }
                        label="Hotspot"
                        sx={{
                          color: 'black',
                          zIndex: 20
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                )}
              </Stack>

              <video
                ref={videoRef}
                disablePictureInPicture
                autoPlay
                src={currentVideo}
                style={{ maxWidth: '100%', maxHeight: '70vh' }}
                controls={false}
                onTimeUpdate={videoCurrentTime}
                onLoadedMetadata={handleLoadedMetadata}
              />
              {loadingProgress && (
                <CircularProgress
                  size={60}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              )}

              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={2}
                marginTop={3}
              >
                {isPlay && (
                  <IconButton onClick={handlePlay}>
                    <PlayArrowIcon />
                  </IconButton>
                )}
                {isPause && (
                  <IconButton onClick={handlePause}>
                    <PauseIcon />
                  </IconButton>
                )}
                <Slider
                  value={getVideoCurrentTime}
                  max={videoDuration}
                  onChange={handleSliderChange}
                  aria-labelledby="continuous-slider"
                  style={{ width: '100%' }}
                />
              </Stack>
            </Stack>
            {isInferedVideo && (
              <Stack>
                <NotificationList
                  videoQuery={videoReportQuery}
                  selectedConfig={currentConfigId}
                  getVideoCurrentTime={getVideoCurrentTime}
                  setVicdeoCurrentTime={setCurrentTime}
                  seek={seek}
                  mode={value}
                  // start={start}
                />
              </Stack>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default React.memo(WorkFlowAnnotation);
