import React, { act, FC } from 'react';
import { TimelineAction, TimelineRow } from '@xzdarcy/react-timeline-editor';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { useConfirm } from 'plugins/confirm';
import { useDispatch } from 'react-redux';
import { deleteClip } from 'store/config/activities';
import { Tooltip } from '@mui/material';
import { hrsToMnsToSecToMlSec } from 'utils';

export const CustomRender0: FC<{
  action: TimelineAction;
  row: TimelineRow;
  width: number;
  selected: boolean;
  start: number;
  end: number;
}> = ({ action, selected, row, width, start, end }) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    confirm({
      title: 'Delete Clip',
      content: 'Are you sure you want to delete this clip?'
    }).then(() => {
      dispatch(deleteClip({ clipId: action.id, activityId: row.id }));
    });
  };

  return (
    <Tooltip
      title={`Start: ${hrsToMnsToSecToMlSec(start)} End: ${hrsToMnsToSecToMlSec(
        end
      )}`}
    >
      <div className={`effect0 ${selected ? 'selected' : ''}`}>
        <div className={`effect0-action-icon`} onClick={handleDeleteClick}>
          <CancelIcon />
        </div>
      </div>
    </Tooltip>
  );
};
