import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const ProcessControlIcon: React.FC<IProps> = ({
  height = 50,
  width = 50
  // color = '#fff'
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 77 77"
      >
        <path
          id="Path_67192"
          data-name="Path 67192"
          d="M37.147,58.84v-19.6H33.516v19.6h3.631M10.529,54.566a5.529,5.529,0,0,0,5.323-4.075l.394-1.449-.394-1.449a5.523,5.523,0,1,0-5.323,6.974m63.9-3.709V47.23h-19.6v3.627h19.6M10.257,26.662V7.064H6.63v19.6h3.627M69.451,22.47l1.4-.532a5.556,5.556,0,0,0,2.58-2,5.451,5.451,0,0,0,1-3.159A5.533,5.533,0,0,0,68.9,11.253a5.51,5.51,0,0,0-5.139,3.514l-.548,1.416.243,1.491a5.5,5.5,0,0,0,4.5,4.54l1.5.257M46.966,17.536V13.9H27.372v3.635H46.966m-8.5,46.3H32.191a3.672,3.672,0,0,1-3.675-3.675V51.8H20.678a10.525,10.525,0,1,1,0-5.519h7.838V37.917a3.677,3.677,0,0,1,3.675-3.675h6.276a3.681,3.681,0,0,1,3.679,3.675v8.364h7.684V45.9a3.674,3.674,0,0,1,3.675-3.671h13.6V27.14a10.523,10.523,0,0,1-8.583-8.664H51.966v.381a3.673,3.673,0,0,1-3.675,3.679H26.047a3.676,3.676,0,0,1-3.675-3.679v-.381H15.257v9.511a3.675,3.675,0,0,1-3.675,3.675H5.305A3.672,3.672,0,0,1,1.63,27.987V5.743A3.676,3.676,0,0,1,5.305,2.064h6.276a3.678,3.678,0,0,1,3.675,3.679v7.213h7.111V12.58A3.678,3.678,0,0,1,26.043,8.9H48.291a3.676,3.676,0,0,1,3.675,3.679v.381H59.1A10.522,10.522,0,1,1,72.626,26.614V42.23H75.75a3.678,3.678,0,0,1,3.679,3.675v6.276a3.673,3.673,0,0,1-3.679,3.675H53.506a3.672,3.672,0,0,1-3.675-3.675V51.8H42.147v8.364A3.678,3.678,0,0,1,38.467,63.84Z"
          transform="translate(0 -2.064)"
          fill="#102633"
        />
      </svg>
    </>
  );
};

export default ProcessControlIcon;
