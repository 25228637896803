// Dependency imports
import { useEffect, useMemo, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

// Local imports
import { useLiveInference } from 'hooks';
import { Button, Modal } from 'components';
import LiveInferenceImage from '../../assets/images/liveInference.svg';
import CircularProgressWithLabel from 'components/progress/circularProgress';
import { useListConfigurations, useListInferenceModels } from 'api/sdk';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { SelectInput } from 'components/Form';
import { useListCameraDetails } from 'api/sdk';
import StreamLoader from '../../assets/images/loader.svg';
import StreamErrorImage from '../../assets/images/error.svg';
import { Stop } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import SearchSelect from 'components/SearchSelect';
import Swal from 'sweetalert2';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DependancyGraph from 'pages/configuration/AnalysisConfiguration/WorkflowMonitor/dependancyGraph';
import { setLiveState } from 'store/config/cameraLive/cameraSlice';

interface notificationDataTypes {
  activity: string;
  sequence: boolean | string;
  current_second: boolean;
  start_time: number;
  end_time: number;
}

const LiveInference = () => {
  const {
    startLive,
    started,
    loading,
    setLoading,
    endInference,
    startStream,
    streamError,
    endStream,
    activityData,
    setModelData,
    setStreamError,
    image,
    setImage,
    modelLoaded,
    sendMessage,
    isReseting,
    setResetting,
    socket
  } = useLiveInference();
  const dispatch = useDispatch();
  const [openDependencyModal, setOpenDependencyModal] =
    useState<boolean>(false);
  const { data: configs, isLoading: configsLoading } = useListConfigurations();
  const profile = useSelector((state: RootState) => state.auth.profile);
  const [configData, setConfigData] = useState<any[]>([]);
  const [activities, setActivities] = useState<any>(null);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const [selectedCamera, setSelectedCamera] = useState<string | null>(null);
  const [cameraId, setCameraId] = useState<string | null>();
  const divRef = useRef<any>(null);
  const [deps, setDeps] = useState<any>([]);
  const [rows, setRows] = useState<any[]>([]);
  const { data } = useListCameraDetails();
  const siteId = useSelector(
    (data: any) => data?.auth?.profile?.session_site?.id
  );
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference.currentConfigId
  );
  const cameraLiveState = useSelector(
    (state: RootState) => state.cameraLive.liveState
  );

  const {
    data: models,
    refetch: refetchModels,
    isLoading: modelsLoading
  } = useListInferenceModels(
    siteId,
    {
      config_id: currentConfigId
    },
    {
      query: {
        enabled: !!currentConfigId
      }
    }
  );

  useEffect(() => {
    let temp = [
      ...(configs?.data.map((config: any) => ({
        label: config.name,
        value: config.id
      })) || [])
    ];
    setConfigData(temp);
  }, [configs, currentConfigId]);

  useEffect(() => {
    if (data !== undefined && socket?.OPEN && models) {
      setTimeout(() => {
        if (cameraLiveState.liveConfig === currentConfigId) {
          setSelectedCamera(cameraLiveState.liveCameraUrl);
          setSelectedModel(cameraLiveState.liveModel);
          setLoading(true);
          setResetting(false);
          endStream();
          const selectedCameraObj = data?.data.find(
            camera => camera.url === cameraLiveState.liveCameraUrl
          );
          const camId: string = selectedCameraObj?.id || '';
          setCameraId(camId);
          startStream(cameraLiveState.liveCameraUrl, camId);
          if (models) {
            const extractedModelData = getModelData(cameraLiveState.liveModel);
            setModelData(extractedModelData);
          }
          setStreamError(null);
        }
      }, 2000);
    }

    return () => {
      if (started) {
        endInference();
      }
      if (image) {
        endStream();
      }
    };
  }, [data, models]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollBy({ top: 80, behavior: 'smooth' });
    }
  }, [activityData]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (image || started) {
        const confirmationMessage =
          'Live Stream is in progress! Are you sure you want to move out?';
        event.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setImage('');
    }, 1); // Execute repeatedly with a 1 ms delay

    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 1000); // Stop after 10 ms

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [cameraId]);

  useEffect(() => {
    let temp: any[] = [];
    for (let i = 0; i < activityData?.length; i++) {
      let obj: any = [];
      obj['id'] = i;
      obj['activity_name'] = activityData[i]['activity'];
      obj['status'] = activityData[i]['sequence'];
      obj['duration'] = activityData[i]['activity_duration'];
      temp.push(obj);
    }
    setRows(temp);
  }, [activityData]);

  useEffect(() => {
    if (modelLoaded) {
      Swal.close();
    }
  }, [modelLoaded]);

  function setNotificationColor(data: number | string) {
    if (data === 1) {
      return 'rgb(0,248,0)';
    } else if (data === 0) {
      return 'red';
    } else {
      return 'orange';
    }
  }

  function getModelData(modelName: string) {
    const model = models?.data?.find(item => item.id === modelName);

    if (!model) {
      return null;
    }

    setActivities(Object.values(model?.workflow_config?.activities || {}));
    setDeps(model?.workflow_config?.workflow);
    return {
      configuration_id: currentConfigId,
      org_id: profile?.organization?.id,
      model_ckpt: model?.model_path,
      model_config: model?.model_cfg,
      model_id: model?.id,
      class_map: model?.class_map,
      workflow_steps: model?.workflow_config?.workflow,
      model_created_at: model?.created_at
    };
  }

  // Memoized nodes
  const nodes: any[] = useMemo(() => {
    return deps.map((item: any, index: number) => ({
      id: item.id,
      data: {
        mendatory: item.mendatory,
        label:
          activities?.find((activity: any) => activity.id === item.activity_id)
            ?.activity ?? ''
      },
      position: { x: 50 * index, y: 100 * index }
    }));
  }, [deps, activities]);

  // Memoized edges
  const edges = useMemo(() => {
    return deps
      .filter(
        (item: any) =>
          Array.isArray(item.dependency) && item.dependency.length > 0
      )
      .flatMap((item: any) =>
        item.dependency.map((dep: any) => ({
          id: `e-${dep}-${item.id}`, // Update to use item.id as target
          source: dep,
          target: item.id
        }))
      );
  }, [deps]);

  return (
    <Stack
      width="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        {configs !== undefined && (
          <SearchSelect
            label="Select Configuration"
            selectValue={currentConfigId || ''}
            data={[
              ...(configs?.data.map((config: any) => ({
                label: config.name,
                value: config.id
              })) || [])
            ]}
            disabled={true}
          />
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
        >
          {activities && !started && (
            <InfoOutlinedIcon
              onClick={() => setOpenDependencyModal(true)}
              style={{ cursor: 'pointer' }}
            />
          )}
          <SelectInput
            name="model"
            options={
              models?.data?.map(model => ({
                label: model.model_name,
                value: model.id
              })) || []
            }
            label="Select Model"
            disabled={started}
            value={selectedModel}
            defaultValue="Select Model"
            variant="outlined"
            loading={modelsLoading}
            onChange={value => {
              dispatch(
                setLiveState({
                  liveConfig: currentConfigId,
                  liveCameraUrl: selectedCamera,
                  liveModel: value
                })
              );
              const extractedModelData = getModelData(value);
              setModelData(extractedModelData);
              setSelectedModel(value);
            }}
          />

          <SelectInput
            name="camera"
            options={
              data?.data.map(camera => ({
                label: camera.name,
                value: camera.url
              })) || []
            }
            label="Select Camera"
            disabled={started}
            value={selectedCamera}
            defaultValue="Select Camera"
            variant="outlined"
            loading={modelsLoading}
            onChange={value => {
              setLoading(true);

              setResetting(false);
              endStream();
              const selectedCameraObj = data?.data.find(
                camera => camera.url === value
              );
              const camId: string = selectedCameraObj?.id || '';
              setCameraId(camId);
              dispatch(
                setLiveState({
                  liveConfig: currentConfigId,
                  liveCameraUrl: value,
                  liveModel: selectedModel
                })
              );
              startStream(value, camId);
              setSelectedCamera(value);
              setStreamError(null);
            }}
          />
        </Stack>
      </Stack>
      {!streamError ? (
        <div
          style={{
            position: 'relative',
            width: '60%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {image && !loading && (
            <img
              src={image}
              alt="live-inference"
              draggable={false}
              style={{ maxHeight: '700px', minWidth: '100%' }}
            />
          )}
          {loading && (
            <div
              style={{
                height: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <img src={StreamLoader} style={{ width: '200px' }} />
              <p style={{ fontSize: '16px' }}>
                🔄 Starting camera... Please wait up to 30 seconds while we
                connect you! ⏳
              </p>
            </div>
          )}
          {!selectedCamera && (
            <img
              src={LiveInferenceImage}
              style={{
                width: '400px',
                marginTop: '100px',
                marginBottom: '100px'
              }}
            />
          )}
          <div
            style={{
              position: 'absolute',
              height: '200px',
              width: '300px',
              top: 10,
              right: 0,
              zIndex: 4
            }}
          >
            {activityData.length > 0 && (
              <div className="notify-container">
                {/* <LoopIcon
                  style={{
                    height: '30px',
                    width: '30px',
                    color: 'white'
                  }}
                /> */}
                <div className="notify-main-data">
                  <p style={{ margin: 0, padding: 0 }}>
                    Sequence:{' '}
                    {/* <span>
                      {activityData[activityData.length - 1]?.ncycles || 0}
                    </span> */}
                    <IconButton
                      disableRipple
                      style={{
                        color:
                          activityData[activityData.length - 1]?.sequence === 1
                            ? 'green'
                            : 'red',
                        alignContent: 'center'
                      }}
                    >
                      {activityData[activityData.length - 1]?.sequence == 1 ? (
                        <CheckCircleIcon />
                      ) : (
                        <CancelRoundedIcon />
                      )}
                    </IconButton>
                  </p>
                </div>
              </div>
            )}
            <Modal
              open={openDependencyModal}
              onClose={() => setOpenDependencyModal(false)}
              size="xl"
            >
              <DependancyGraph nodes={nodes} edges={edges} />
            </Modal>
            <div className="notify-main-block" ref={divRef}>
              {activityData?.map((data: any, index: number) => {
                return (
                  <div className="notify-container">
                    <CircularProgressWithLabel
                      style={{
                        height: '30px',
                        width: '30px',
                        color: data?.color
                          ? data?.color
                          : setNotificationColor(data?.sequence)
                      }}
                      variant={
                        index === activityData.length - 1
                          ? 'indeterminate'
                          : 'determinate'
                      }
                      value={
                        <IconButton
                          disableRipple
                          style={{
                            color: data?.color
                              ? data.color
                              : setNotificationColor(data?.sequence),
                            alignContent: 'center'
                          }}
                        >
                          {data?.missing === 0 ? (
                            <CheckCircleIcon />
                          ) : (
                            // ) : data?.missing === 1 ? (
                            <ErrorOutlineTwoToneIcon />
                          )}
                        </IconButton>
                      }
                      size={60}
                      // disableShrink
                    />
                    <div className="notify-main-data">
                      <div className="notification-head">
                        <div>{`${index + 1} `} </div>
                        <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                          -
                        </div>
                        <div className="notification-head">
                          {data?.activity}
                        </div>
                      </div>
                      {/* <div className="timer">
                        {moment
                          .utc(data?.activity_duration * 1000)
                          .format('HH:mm:ss')}
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {started && (
            <div style={{ position: 'absolute', top: 10, left: 30 }}>
              <svg height="10" width="10" className="blinking">
                <circle cx="5" cy="5" r="5" fill="red" />
              </svg>
              <span style={{ color: 'red' }}> Live</span>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            textAlign: 'center',
            height: '600px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <img
            src={StreamErrorImage}
            alt="error_stream"
            style={{ width: '350px' }}
          />
          <p style={{ fontSize: '16px' }}>{streamError}</p>
        </div>
      )}
      {!streamError && started && !modelLoaded ? (
        <button
          style={{
            background: 'red',
            padding: '8px 20px',
            border: 'none',
            borderRadius: '6px',
            color: 'white',
            opacity: 0.6,
            cursor: 'not-allowed',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '4px'
          }}
        >
          <Stop /> End Inference
        </button>
      ) : !streamError && started && modelLoaded ? (
        <button
          style={{
            background: 'red',
            padding: '8px 20px',
            border: 'none',
            borderRadius: '6px',
            color: 'white',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '4px',
            cursor: 'pointer'
          }}
          onClick={endInference}
        >
          <Stop /> End Inference
        </button>
      ) : (
        !streamError && (
          <button
            onClick={() => {
              if (selectedModel === null || selectedCamera === null || !image) {
              } else {
                if (selectedCamera) {
                  Swal.fire({
                    title: 'Model Loading...',
                    toast: true,
                    allowOutsideClick: false,
                    position: 'bottom-right',
                    icon: 'info',
                    showConfirmButton: false,
                    timer: 10000
                  });
                  startLive(selectedCamera, cameraId || '');
                }
              }
            }}
            style={{
              background: '#102633',
              padding: '8px 20px',
              border: 'none',
              borderRadius: '6px',
              color: 'white',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '4px',
              opacity:
                selectedModel === null || selectedCamera === null || !image
                  ? 0.8
                  : 1,
              cursor:
                selectedModel === null || selectedCamera === null || !image
                  ? 'not-allowed'
                  : 'pointer'
            }}
          >
            <NotStartedIcon /> Start Inference
          </button>
        )
      )}
      {!streamError && (selectedModel === null || selectedCamera === null) && (
        <p>Please select Model and Camera to Start Inference</p>
      )}
      {streamError && (
        <Button
          startIcon={<NotStartedIcon />}
          disabled={isReseting}
          loading={isReseting}
          onClick={() => {
            setResetting(true);
            sendMessage(
              JSON.stringify({
                event: 'kill_all'
              })
            );
          }}
          variant="outlined"
        >
          Kill All
        </Button>
      )}
    </Stack>
  );
};

export default LiveInference;
