/* eslint-disable */
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData
} from 'chart.js';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import BarchartSkeleton from './barchartSkeleton';
import { deepMerge } from 'utils/object';

export interface BarchartProps {}

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

type T =
  | {
      x: Date[] | string[] | number[];
      y: number | string;
    }
  | string
  | number;

export interface BarchartProps {
  data: ChartData<'bar', any, any>;
  options?: ChartOptions<'bar'>;
  loading?: boolean;
  yAxisTitle?: string;
  openState?: number;
  plugin?: Array<any>;
}

export const Barchart: React.FC<BarchartProps> = ({
  data: chartData,
  yAxisTitle,
  options = {},
  loading = false,
  openState,
  plugin
}) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const matchesLg = useMediaQuery('(min-width:1300px)');

  const defaultOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          display: true,
          grid: {
            display: false
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            font: {
              size: matchesLg ? 10 : 8
            }
          }
        }
      },
      plugins: plugin
    };
  }, [matchesLg]);

  const chartOptions = useMemo(() => {
    return deepMerge(defaultOptions, options);
    ``;
  }, [defaultOptions, options]);

  const barKey = useMemo(() => {
    return Math.random().toString(36).substring(7) + new Date().getTime();
  }, []);

  /**
   * Side effect to set the width of the chart
   * @param {any} (
   * @returns {any}
   */
  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.clientWidth);
      setHeight(containerRef.current.clientHeight);
    }
  }, [containerRef.current, openState]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (containerRef.current) {
        setWidth(containerRef.current.clientWidth);
        setHeight(containerRef.current.clientHeight);
      }
    });

    return () => {
      window.removeEventListener('resize', () => {
        if (containerRef.current) {
          setWidth(containerRef.current.clientWidth);
        }
      });
    };
  }, []);

  useEffect(() => {}, []);

  return (
    <div ref={containerRef}>
      {loading ? (
        <BarchartSkeleton width={width} />
      ) : (
        <>
          <Grid
            container
            style={{
              width: '100%'
              // minHeight: 100
            }}
          >
            <Grid item>
              <div>
                <div
                  style={{
                    width: width,
                    float: 'left'
                  }}
                >
                  <Bar
                    id={`bar-chart-${barKey}`}
                    key={barKey}
                    options={chartOptions}
                    data={chartData}
                    style={{
                      width: '100%',
                      height: openState === 0 ? 300 : 450
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
