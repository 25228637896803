/* eslint-disable */
import { Modal } from 'components';
import React, { useState } from 'react';

import CameraNameForm from './cameraNameForm';

interface ICreateConfigModalProps {
  open: boolean;
  onClose: () => void;
  onSucess?: () => void;
}

interface IformValues {
  config_name: string;
  number_of_cameras: number | null;
  analysisOptions: string[];
}

export const CreateConfigModal: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  onSucess
}) => {
  const [values, setValues] = useState<IformValues>({
    config_name: '',
    number_of_cameras: 1,
    analysisOptions: []
  });
  const resetValues = () => {
    setValues({
      config_name: '',
      number_of_cameras: null,
      analysisOptions: []
    });
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetValues(); // Reset values when modal is closed
      }}
      title="Project Configuration"
      size="xl"
    >
      <CameraNameForm
        initialValues={{
          config_name: values.config_name,
          number_of_cameras: 1,
          analysisOptions: ['workflow_monitoring']
        }}
        onSubmit={val => {
          setValues({ ...values, ...val });
          resetValues(); // Reset values
          onSucess && onSucess(); // Close modal
        }}
        onCancel={() => {
          onClose(); // Close modal
          resetValues(); // Reset values
        }}
      />
    </Modal>
  );
};
