import React from 'react';

interface ICloseIconProps {
  height?: number;
  width?: number;
}

/**
 * Close Icon
 */
const CloseIcon: React.FC<ICloseIconProps> = ({ height = 24, width = 24 }) => {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        height={height}
        width={width}
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        style={{ background: '#FFFFFF', borderRadius: '20px' }} // Set the background to white
      >
        <g>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
        </g>
      </svg>
    </>
  );
};

export default CloseIcon;
