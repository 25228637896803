import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

interface BarchartSkeletonProps extends IContentLoaderProps {}

const BarchartSkeleton: React.FC<BarchartSkeletonProps> = ({
  width,
  ...props
}) => {
  const currentWidth: number = Number(
    typeof width === 'string' ? parseInt(width, 10) : width
  );
  const barCount = Math.floor(currentWidth / 30);

  return (
    <ContentLoader width={width} viewBox="0 0 500 200" {...props}>
      {Array.from({ length: barCount }).map((_, i) => {
        const randomHeight = Math.floor(Math.random() * 170);
        return (
          <rect
            key={`bar-${i}`}
            x={i * 30}
            y={200 - randomHeight}
            rx="0"
            ry="0"
            width="25"
            height={randomHeight}
          />
        );
      })}
    </ContentLoader>
  );
};

export default BarchartSkeleton;
