import { ThemeOptions } from '@mui/material';

import { PALETTE_COLORS } from './colors';

/**
 * MUI theme options for "Light Mode"
 */
export const LIGHT_THEME: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      // main: '#102633'
    },

    // background: {
    //   paper: '#f5f5f5', // Gray 100 - Background of "Paper" based component
    //   default: '#FFFFFF',
    // },
    ...PALETTE_COLORS
  },

  typography: {
    fontWeightBold: 700
  },

  // mediaQuery
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0px',
          minWidth: '100px'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          backgroundColor: '#f5f5f5',
          borderColor: '#ededed',
          padding: '5px 10px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: '#f5f5f5'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          shrink: true,
          flexShrink: 0,
          fontSize: '1.3rem'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          color: '#000000'
        }
      }
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          background: 'none'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          borderRadius: '0px'
        }
      }
    }

    // MuiTextField: {
    //   defaultProps: {
    //     variant: 'outlined'
    //   }
    // }
  }
};

export default LIGHT_THEME;
