import { Checkbox, styled } from '@mui/material';

export const Check = styled(Checkbox)<{
  overlap?: boolean;
  version?: 'light' | 'dark';
}>(({ theme, overlap, version }) => ({
  padding: 0,
  ...(overlap && {
    position: 'absolute',
    marginTop: 2,
    marginLeft: 2,
    version: version,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 10
  }),
  ...(version === 'light' && {
    '& svg': {
      fill: '#fff'
    }
  })
}));

export default Check;
