/* eslint-disable  */
// Dependency imports
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// Local imports
import {
  useAddCameras,
  useDeleteConfigurationsCamera,
  useGetConfiguration,
  useUpdateVideoPath
} from 'api/sdk';
import { useSnack } from 'plugins/snack';
import { VideoMetadata } from 'schemas';
import {
  addCamera as addCameraAction,
  deleteCamera as deleteCameraAction,
  selectCameras,
  selectConfig,
  setConfigFromApiResponse,
  updateVideoPath
} from 'store/config/configSlice';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';

export const useVideoUpload = (onContinue: () => void | undefined) => {
  const cameras = useSelector(selectCameras);
  const { id: configId } = useSelector(selectConfig);
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference?.currentConfigId
  );
  const dispatch = useDispatch();
  const snack = useSnack();
  const [videoError, setError] = useState<string | null>(null);
  const { refetch, isLoading } = useGetConfiguration(
    {
      config_id: configId || ''
    },
    {
      query: {
        enabled: !!configId
      }
    }
  );
  const { mutateAsync } = useUpdateVideoPath();
  const { isLoading: isAddingCameras } = useAddCameras();
  const { mutateAsync: deleteCamera } = useDeleteConfigurationsCamera();

  // Upload video
  const handleUpload = useCallback(
    async (
      videoPath: string,
      url: string,
      cameraId: string,
      meta: VideoMetadata
    ) => {
      try {
        await mutateAsync({
          data: {
            metadata: meta,
            video_path: videoPath,
            filename: url
          },
          params: {
            camera_id: cameraId,
            config_id: currentConfigId || ''
          }
        });
        dispatch(
          updateVideoPath({
            cameraId,
            video_url: url
          })
        );
        snack({
          message: 'Video uploaded successfully',
          severity: 'success'
        });
      } catch (error) {
        snack({
          message: 'Failed to upload video',
          severity: 'error'
        });
      }
    },
    [dispatch, mutateAsync, snack]
  );

  // Delete video
  const handleDelete = async (cameraId: string, refresh: any) => {
    Swal.fire({
      title: 'Delete Video',
      text: 'Are you sure you want to delete this video?',
      allowOutsideClick: false,
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      confirmButtonColor: '#E33030',
      cancelButtonColor: '#102633',
      cancelButtonText: 'No, cancel!',
      focusCancel: true,
      customClass: {
        title: 'swal2-title',
        container: 'swal2-html-container',
        actions: 'swal2-actions'
      }
    }).then(async result => {
      if (result.isConfirmed) {
        await deleteCamera({
          params: {
            camera_id: cameraId
          }
        });
        deleteCameraAction(cameraId);
        refresh();
        snack({
          message: 'Video deleted successfully',
          severity: 'success'
        });
      }
    });
  };

  const refresh = useCallback(() => {
    refetch().then(response => {
      response.data &&
        dispatch(
          setConfigFromApiResponse({
            config: response.data.data
          })
        );
    });
  }, [dispatch, refetch, configId]);

  const handleContinue = useCallback(async () => {
    if (cameras?.some(camera => !camera.video_url)) {
      setError('Please upload video for all cameras');
      return;
    }
    setError(null);
    refetch().then(response => {
      response.data &&
        dispatch(
          setConfigFromApiResponse({
            config: response.data.data
          })
        );
    });
    onContinue?.();
  }, [cameras, setError]);

  useEffect(() => {
    refresh();
  }, [configId]);

  return {
    cameras,
    configId,
    isAddingCameras,
    isLoading,
    handleUpload,
    handleDelete,
    // handleAddCamera,
    videoError,
    setError,
    handleContinue,
    refresh
  };
};
