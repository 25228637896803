import {
  InputLabel as MInputLabel,
  InputLabelProps,
  styled
} from '@mui/material';

interface IInputLabelProps extends InputLabelProps {
  light?: boolean;
}

const StyledInputLabel = styled(MInputLabel)<IInputLabelProps>(
  ({ theme, light }) => ({
    color: light ? theme.palette.text.secondary : theme.palette.text.primary,
    fontSize: '1rem',
    marginLeft: '5px',
    fontWeight: 500,
    lineHeight: '1.25rem',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '5px',
    '&.Mui-focused': {
      color: theme.palette.primary.main
    },
    zIndex: 1
  })
);

const StyledRequiredAsterisk = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

export const RequiredAsterisk: React.FC = () => {
  return <StyledRequiredAsterisk>*</StyledRequiredAsterisk>;
};

export const InputLabel: React.FC<IInputLabelProps> = ({
  children,
  required,
  light,
  ...props
}) => {
  return (
    <StyledInputLabel {...props} light={light}>
      {required && <RequiredAsterisk />} {children}
    </StyledInputLabel>
  );
};
