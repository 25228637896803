// Dependency imports
import { Stack } from '@mui/material';
import { useState } from 'react';

// Local imports
import { Button, Typography } from 'components';
import { TextInput } from 'components/Form';
import { useSnack } from 'plugins/snack';
import { useUpdateCameraData } from 'api/sdk';

interface EditCameraProps {
  url: string;
  setUrl: any;
  name: string;
  setName: any;
  setOpenEditModel: any;
  cameraId: string;
  refetch: () => void;
}

const EditCamera = ({
  url,
  setUrl,
  name,
  setName,
  setOpenEditModel,
  cameraId,
  refetch
}: EditCameraProps) => {
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: updateCameras } = useUpdateCameraData();
  const snack = useSnack();

  const validateForm = () => {
    setError(null);

    if (name.trim().length === 0) {
      setError('Camera name is required!');
      return;
    }

    if (name.trim().length < 5) {
      setError('Camera Name should alteast have 5 characters');
      return;
    }

    if (name.trim().length > 20) {
      setError("Camera Name can't be more than 20 characters");
      return;
    }

    if (!name.match(/^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])[a-zA-Z0-9\W_]+$/)) {
      setError('Invalid characters. Camera Name should be Alphanumeric');
      return;
    }

    if (url.trim().length === 0) {
      setError('RTSP url cannot be empty');
      return;
    }

    if (
      !url.match(
        /^rtsp:\/\/(?:[a-zA-Z0-9_-]+\:[a-zA-Z0-9_-]+@)?[a-zA-Z0-9.-]+(?::\d+)?\/?.*$/
      )
    ) {
      setError('Wrong rtsp url format. Ex format: rtsp://example.com/stream');
      return;
    }

    if (error === null) {
      editCameraToConfig();
    }
  };

  const editCameraToConfig = async () => {
    await updateCameras({
      id: cameraId,
      data: {
        name: name,
        url: url
      }
    })
      .then((res: any) => {
        snack({
          message: res?.data?.message,
          severity: 'success'
        });
        setName('');
        setUrl('');
        setError(null);
        setOpenEditModel(false);
        refetch();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
      <Stack direction="row" width={'100%'}>
        <Typography style={{ fontSize: '16px' }}>Edit Camera</Typography>
      </Stack>
      <Stack spacing={2} alignContent={'center'} marginTop="20px">
        <TextInput
          label="Camera Name"
          name="name"
          variant="outlined"
          size="small"
          value={name}
          onChange={value => setName(value)}
        />
        <TextInput
          label="Camera URL"
          name="url"
          variant="outlined"
          size="small"
          value={url}
          onChange={value => setUrl(value)}
        />
        <p style={{ color: 'red', fontSize: 12, fontWeight: 'bold' }}>
          {error}
        </p>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={4}
        marginTop={'20px'}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setName('');
            setUrl('');
            setOpenEditModel(false);
          }}
        >
          Cancel
        </Button>
        <Button onClick={validateForm}>Save</Button>
      </Stack>
    </div>
  );
};

export default EditCamera;
