import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const SafetyGear: React.FC<IProps> = ({ height = 50, width = 50 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 69.674 76.027"
      >
        <g id="safety-vest-svgrepo-com" transform="translate(2 2)">
          <path
            id="Path_63974"
            data-name="Path 63974"
            d="M68.124,24.412V73.527h-23a9.822,9.822,0,0,1-9.834-9.809h0a9.822,9.822,0,0,1-9.834,9.809h-23V24.412a9.846,9.846,0,0,0,6.976-2.861A9.8,9.8,0,0,0,12.318,14.6V1.5H58.256V14.6a9.822,9.822,0,0,0,9.868,9.809Z"
            transform="translate(-2.45 -1.5)"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <path
            id="Path_63975"
            data-name="Path 63975"
            d="M21.315,31.036,8.18,1.5H34.45Z"
            transform="translate(11.522 -1.5)"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_1074"
            data-name="Line 1074"
            y1="32.837"
            transform="translate(32.837 29.356)"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_1075"
            data-name="Line 1075"
            x2="23.003"
            transform="translate(0 39.272)"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_1076"
            data-name="Line 1076"
            x2="23.003"
            transform="translate(0 55.667)"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_1077"
            data-name="Line 1077"
            x2="23.003"
            transform="translate(42.671 39.272)"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_1078"
            data-name="Line 1078"
            x2="23.003"
            transform="translate(42.671 55.667)"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
        </g>
      </svg>
    </>
  );
};

export default SafetyGear;
