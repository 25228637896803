import { createSlice } from '@reduxjs/toolkit';

export const preventNavigationSlice = createSlice({
  name: 'preventNavigation',
  initialState: {
    preventNavigation: false,
    endProcess: false
  },
  reducers: {
    setPreventNavigation: (state, action) => {
      state.preventNavigation = action.payload;
    },
    setEndProcess: (state, action) => {
      state.endProcess = action.payload;
    }
  }
});

export const { setPreventNavigation, setEndProcess } =
  preventNavigationSlice.actions;
export default preventNavigationSlice.reducer;
