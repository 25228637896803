import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const EmployeeProductivityIcon: React.FC<IProps> = ({
  height = 50,
  width = 50
  // color = '#fff'
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 74.1 69.394"
        height={height}
        width={width}
      >
        <path
          id="employee-salary-business-svgrepo-com"
          d="M26.3,7.368a9.788,9.788,0,1,1-9.788,9.788A9.788,9.788,0,0,1,26.3,7.368ZM10.528,44.052a1.157,1.157,0,0,1,1.233-1.233,1.144,1.144,0,0,1,1.233,1.233V71.394h26.9V44.052a1.233,1.233,0,1,1,2.466,0V71.394h8.554V41.585A12.257,12.257,0,0,0,38.684,29.358H36.217l-7.19,11.178-.866-8.371,1.181-2.834h-5.3l1.181,2.834L24.3,40.588l-7.19-11.231H14.228A12.257,12.257,0,0,0,2,41.585V71.394h8.554V44.052h-.026ZM59.35,8.486,57.273,2H69.145L66.6,8.486Zm-9.1,13.655a12.865,12.865,0,0,1,6.941-11.5H69.078a12.912,12.912,0,1,1-18.827,11.5Zm14.124,3.1c0,.764-.7,1.152-1.7,1.152a6.89,6.89,0,0,1-3.015-.777l-.536,2.077a7.762,7.762,0,0,0,3.082.777v1.7h1.769V28.318a3.415,3.415,0,0,0,3.243-3.323c0-1.621-.858-2.613-3.015-3.39-1.541-.536-2.157-.924-2.157-1.541,0-.469.389-1.005,1.541-1.005a5.671,5.671,0,0,1,2.626.616l.536-2.01a6.6,6.6,0,0,0-2.626-.616V15.427H62.351v1.688c-1.93.389-3.082,1.621-3.082,3.243,0,1.782,1.313,2.707,3.243,3.323C63.825,24.15,64.442,24.539,64.375,25.236Z"
          transform="translate(-2 -2)"
        />
      </svg>
    </>
  );
};

export default EmployeeProductivityIcon;
