import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider
} from '@ariakit/react';
import * as RadixSelect from '@radix-ui/react-select';
import { startTransition, useEffect, useMemo, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon, SearchIcon } from './icons';
import Styles from './searchselect.module.scss';

interface selectData {
  data?: any;
  onChange?: any;
  label?: string;
  selectValue?: any;
  disabled?: boolean;
}

export default function SearchSelect({
  data,
  onChange,
  label,
  selectValue,
  disabled = false
}: selectData) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(selectValue === label ? '' : selectValue);
  const [searchValue, setSearchValue] = useState('');

  const matches = useMemo(() => {
    if (!searchValue) return data;

    const matches = data.filter((item: any) =>
      item.label.toLowerCase().includes(searchValue.toLowerCase())
    );

    const selectedData = data.find((data: any) => data.value === value);
    if (selectedData && !matches.includes(selectedData)) {
      matches.push(selectedData);
    }

    return matches;
  }, [searchValue, value, data]);

  useEffect(() => {
    setValue(selectValue === label ? '' : selectValue);
  }, [selectValue]);

  return (
    <RadixSelect.Root
      value={value}
      onValueChange={value => {
        setValue(value);
        onChange(value);
      }}
      open={open}
      disabled={disabled}
      onOpenChange={setOpen}
    >
      <ComboboxProvider
        open={open}
        setOpen={setOpen}
        resetValueOnHide
        includesBaseElement={false}
        setValue={value => {
          startTransition(() => {
            setSearchValue(value);
          });
        }}
      >
        <RadixSelect.Trigger
          aria-label="Language"
          className={Styles.select}
          style={{
            opacity: disabled ? 0.6 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer'
          }}
        >
          <RadixSelect.Value placeholder={value === '' ? label : value} />
          <RadixSelect.Icon className={Styles.selectIcon}>
            <ChevronUpDownIcon />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Content
          role="dialog"
          aria-label="Languages"
          position="popper"
          className={Styles.popover}
          sideOffset={4}
          alignOffset={-16}
        >
          <div className={Styles.comboboxWrapper}>
            <div className={Styles.comboboxIcon}>
              <SearchIcon />
            </div>
            <Combobox
              autoSelect
              placeholder={label}
              className={Styles.combobox}
              onBlurCapture={event => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          </div>
          <ComboboxList className={Styles.listbox}>
            {matches.map(
              ({ label, value }: { label: string; value: string }) => (
                <RadixSelect.Item
                  key={value}
                  value={value}
                  asChild
                  className={Styles.item}
                >
                  <ComboboxItem>
                    <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                    <RadixSelect.ItemIndicator className={Styles.itemIndicator}>
                      <CheckIcon />
                    </RadixSelect.ItemIndicator>
                  </ComboboxItem>
                </RadixSelect.Item>
              )
            )}
          </ComboboxList>
        </RadixSelect.Content>
      </ComboboxProvider>
    </RadixSelect.Root>
  );
}
