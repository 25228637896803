// Dependency imports
import { IconButton } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import Swal from 'sweetalert2';

// Local imports
import { useDeleteInferenceVideos } from 'api/sdk';
import { Snack } from 'plugins/snack/Snack';

interface IProps {
  video_id: string;
  disabled: boolean;
  onDelete: () => void;
}

const DeleteAction: React.FC<IProps> = ({ video_id, onDelete, disabled }) => {
  const { isLoading, mutateAsync } = useDeleteInferenceVideos();

  const handleDeleteClick = async () => {
    Swal.fire({
      title: 'Delete Video',
      text: 'Are you sure you want to delete this video?',
      allowOutsideClick: false,
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      confirmButtonColor: '#E33030',
      cancelButtonColor: '#102633',
      cancelButtonText: 'No, cancel!',
      focusCancel: true,
      animation: true,
      customClass: {
        title: 'swal2-title',
        container: 'swal2-html-container',
        actions: 'swal2-actions'
      }
    }).then(async result => {
      if (result.isConfirmed) {
        await mutateAsync({
          videoId: video_id
        });
        Snack({
          message: 'Video deleted successfully',
          severity: 'success'
        });
        onDelete();
      }
    });
  };

  return (
    <IconButton
      onClick={disabled ? () => {} : () => handleDeleteClick()}
      disableRipple
      style={{
        opacity: disabled ? 0.6 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer'
      }}
    >
      {isLoading ? <AutoDeleteIcon /> : <DeleteIcon />}
    </IconButton>
  );
};

export default DeleteAction;
