import { TimelineState } from '@xzdarcy/react-timeline-editor';
import React, { FC, useEffect, useState } from 'react';
import { Grid, LinearProgress, Stack, Tooltip } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  hrsToMnsToSecToMlSec,
  secondsToFriendlyTime,
  secondsToHms
} from 'utils';
import AddIcon from '@mui/icons-material/Add';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { Typography } from 'components';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import RedoSharpIcon from '@mui/icons-material/RedoSharp';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { addActivity } from 'store/config/activities';
import { useDispatch } from 'react-redux';

const TimelinePlayer: FC<{
  timelineState: React.MutableRefObject<TimelineState | null>;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  maxEndtime: number;
  loadingVideo: boolean;
  disableCrop: boolean;
  showText: boolean;
  onClickAdd: () => void;
  setZoomCOunt: React.Dispatch<React.SetStateAction<number>>;
  zoomCount: number;
  time: number;
  activityData: any;
  activityDataPointer: number;
  setActivityDataPointer: React.Dispatch<React.SetStateAction<number>>;
  onClickCrop: () => void;
  handlePlay: () => void;
  handlePause: () => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  getCropState: any;
}> = ({
  timelineState,
  videoRef,
  handlePlay,
  handlePause,
  showText,
  loadingVideo,
  onClickAdd,
  isPlaying,
  onClickCrop,
  time,
  disableCrop,
  getCropState,
  activityData,
  setActivityDataPointer,
  setIsPlaying,
  activityDataPointer,
  setZoomCOunt,
  zoomCount
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!timelineState) return;
    if (!timelineState.current) return;

    const engine = timelineState.current;

    if (!isPlaying) {
      engine.listener.on('afterSetTime', ({ time }) => {
        if (videoRef.current) videoRef.current.currentTime = time;
      });
    }

    return () => {
      if (!engine) return;
      engine.pause();
      engine.listener.offAll();
    };
  }, [isPlaying]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (timelineState.current && event.code === 'Space') {
        event.preventDefault();
        if (isPlaying) {
          videoRef.current?.pause();
          setIsPlaying(false);
          timelineState.current.pause();
        } else {
          videoRef.current?.play();
          setIsPlaying(true);
          timelineState.current.play({ autoEnd: true });
        }
      }
      if (
        event.ctrlKey &&
        event.key === 'x' &&
        !getCropState?.start
          .toString()
          .includes(videoRef.current?.currentTime.toString())
      ) {
        event.preventDefault();
        return !disableCrop && !loadingVideo && !showText && onClickCrop();
      }
      if (event.ctrlKey && event.key === 'a') {
        event.preventDefault();
        return !loadingVideo && !showText && onClickAdd();
      }
      if (event.ctrlKey && event.key === 'z') {
        // Ctrl+Z for undo
        event.preventDefault();
        if (activityDataPointer > 1) {
          setActivityDataPointer(activityDataPointer - 1);
        }
      } else if (event.ctrlKey && event.key === 'y') {
        // Ctrl+Y for redo
        event.preventDefault();
        if (activityDataPointer < activityData.length - 1) {
          setActivityDataPointer(activityDataPointer + 1);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activityDataPointer, activityData, isPlaying]);

  return (
    <>
      <Grid
        container
        gap={1}
        style={{
          borderBottom: '1px solid #e0e0e0'
        }}
        m={1}
      >
        <Grid item xs={4}>
          <Stack
            spacing={1}
            direction="row"
            pl={2}
            height={'100%'}
            alignItems={'center'}
            alignContent={'center'}
          >
            <div className="play-control">
              <Tooltip title="Label Assist" placement="top-start">
                <AutoFixHighIcon
                  style={{
                    height: 20,
                    fill: 'grey'
                  }}
                />
              </Tooltip>
            </div>
            <div className="play-control">
              <Tooltip title="Add Activity" placement="top-start">
                <AddIcon
                  style={{
                    height: 20,
                    cursor: 'pointer',
                    fill: !loadingVideo && !showText ? 'black' : 'grey'
                  }}
                  onClick={() => !loadingVideo && !showText && onClickAdd()}
                />
              </Tooltip>
            </div>
            {/* HERE */}
            <div className="play-control">
              <Tooltip title="Undo" placement="top-start">
                <UndoSharpIcon
                  style={{
                    height: 20,
                    cursor:
                      activityDataPointer > 1 && !loadingVideo && !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      activityDataPointer > 1 && !loadingVideo && !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    !loadingVideo &&
                    !showText &&
                    activityDataPointer > 1 &&
                    setActivityDataPointer(activityDataPointer - 1)
                  }
                />
              </Tooltip>
            </div>
            <div className="play-control">
              <Tooltip title="Redo" placement="top-start">
                <RedoSharpIcon
                  style={{
                    height: 20,
                    cursor:
                      activityDataPointer < activityData.length - 1 &&
                      !loadingVideo &&
                      !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      activityDataPointer < activityData.length - 1 &&
                      !loadingVideo &&
                      !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    !loadingVideo &&
                    !showText &&
                    activityDataPointer < activityData.length - 1 &&
                    setActivityDataPointer(activityDataPointer + 1)
                  }
                />
              </Tooltip>
            </div>
            <div className="play-control">
              <Tooltip title="Cut Activity" placement="top-start">
                <ContentCutIcon
                  style={{
                    height: 20,
                    cursor:
                      !disableCrop &&
                      !loadingVideo &&
                      !showText &&
                      !getCropState?.start
                        .toString()
                        .includes(videoRef.current?.currentTime.toString())
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      getCropState?.start
                        .toString()
                        .includes(videoRef.current?.currentTime.toString()) ||
                      disableCrop ||
                      loadingVideo ||
                      showText
                        ? 'grey'
                        : 'black'
                  }}
                  onClick={
                    !getCropState?.start
                      .toString()
                      .includes(videoRef.current?.currentTime.toString()) &&
                    !disableCrop &&
                    !loadingVideo &&
                    !showText
                      ? onClickCrop
                      : () => {}
                  }
                />
              </Tooltip>
            </div>
            {/* ZOOM  */}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            height={'100%'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <Typography variant="caption">
              {hrsToMnsToSecToMlSec(time || 0)} /
              {hrsToMnsToSecToMlSec(videoRef.current?.duration || 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3.5}>
          <Stack
            spacing={1}
            direction="row"
            pl={2}
            height={'100%'}
            alignItems={'center'}
            alignContent={'center'}
            justifyContent={'flex-end'}
          >
            <div className="play-control">
              <Tooltip title="Zoom out" placement="top-start">
                <ZoomOutIcon
                  style={{
                    height: 20,
                    cursor:
                      zoomCount > 0 && !loadingVideo && !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      zoomCount > 0 && !loadingVideo && !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    !loadingVideo &&
                    !showText &&
                    zoomCount > 0 &&
                    setZoomCOunt(zoomCount - 1)
                  }
                />
              </Tooltip>
            </div>

            <div className="play-control">
              <Tooltip title="Zoom in" placement="top-start">
                <ZoomInIcon
                  style={{
                    height: 20,
                    cursor:
                      zoomCount < 2 && !loadingVideo && !showText
                        ? 'pointer'
                        : 'not-allowed',
                    fill:
                      zoomCount < 2 && !loadingVideo && !showText
                        ? 'black'
                        : 'grey'
                  }}
                  onClick={() =>
                    !loadingVideo &&
                    !showText &&
                    zoomCount < 2 &&
                    setZoomCOunt(zoomCount + 1)
                  }
                />
              </Tooltip>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default TimelinePlayer;
