import { Grid, Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import Background from 'assets/images/background.webp';
import logo from 'assets/images/logo.png';

// import { useOnMobile } from 'hooks';

interface IPublicLayoutProps extends PropsWithChildren {}

const PublicLayout: React.FC<IPublicLayoutProps> = ({ children }) => {
  // const _onMobile = useOnMobile();
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Stack>
      {/* Top container */}
      <Grid
        container
        ref={containerRef}
        style={{
          minHeight: '100vh',
          width: '100vw',
          backgroundImage: `linear-gradient(to bottom, #050F12,#295D65)`,
          backgroundPosition: 'center',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item lg={3} md={4} sm={6} xs={10}>
          <Stack
            textAlign={'center'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <img src={logo} alt="logo" style={{ maxWidth: '200px' }} />
          </Stack>
          <Stack>{children}</Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PublicLayout;
