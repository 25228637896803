import { useGetConfiguration } from 'api/sdk';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setConfigFromApiResponse } from 'store/config/configSlice';
import { ConfigSteps } from 'types';
import {
  NumberParam,
  createEnumParam,
  useQueryParam,
  withDefault
} from 'use-query-params';

import { useConfigWebsocket } from './useConfigWebsocket';

export const useConfig = (fetchData: boolean) => {
  const { id } = useParams<{ id: string }>();

  const { camera } = useParams<{ camera: string }>();
  const dispatch = useDispatch();

  /**
   * Handle step navigation
   */
  const StepEnumParam = createEnumParam(Object.values(ConfigSteps));
  const [step, setStep] = useQueryParam(
    'step',
    withDefault(StepEnumParam, ConfigSteps.GENERAL_CAMERA_AND_VIDEO),
    {}
  );

  /**
   * Get camera id from the query params
   */
  const cameraId = useQueryParam('camera', withDefault(NumberParam, 0), {});

  /**
   * Start websocket connection
   */
  const { socket } = useConfigWebsocket(id || '');

  /**
   * Fetch configuration data
   */
  const { isLoading, isError, error, isSuccess, refetch } = useGetConfiguration(
    {
      config_id: id || ''
    },
    {
      query: {
        enabled: !!id
      }
    }
  );

  /**
   * Fetch the configuration data on mount.
   */
  useEffect(() => {
    if (id && fetchData) {
      refetch().then(response => {
        response.data &&
          dispatch(
            setConfigFromApiResponse({
              config: response.data.data
            })
          );
      });
    }
  }, [id]);

  /**
   * Change the current step
   */
  const changeStep = useCallback(
    (newStep: ConfigSteps) => {
      setStep(newStep);
    },
    [setStep]
  );

  /**
   * Update current camera index to the redux store
   */
  useEffect(() => {
    // TODO: find the camera index and update the current camera index in the redux store
  }, [cameraId]);

  return {
    id,
    camera,
    step,

    isLoading,
    isError,
    error,
    isSuccess,

    changeStep
  };
};
