/* eslint-disable */
import { Box, styled } from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { Typography } from '../../../Typography/Typography';

const UploadZoneContainer = styled(Box)<{ isDragActive?: boolean }>(
  ({ theme, isDragActive }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
    width: '100%',
    border: `2px ${isDragActive ? 'dashed' : 'solid'} ${
      theme.palette.primary.main
    }`,
    borderRadius: '0px',
    backgroundColor: isDragActive ? theme.palette.primary.light : '#EEEEEE',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  })
);

interface UploadZoneProps {
  onChange?: (file: File) => void;
  showBtn?: boolean;
  deletable?: boolean;
}

const UploadZone: React.FC<UploadZoneProps> = ({
  onChange,
  showBtn,
  deletable
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    onChange && onChange(file);
    // const reader = new FileReader();
    // reader.onabort = () => console.log('file reading was aborted');
    // reader.onerror = () => console.log('file reading has failed');
    // reader.onload = () => {
    //   const binaryStr = reader.result;
    //   console.log(binaryStr);
    // };
    // reader.readAsArrayBuffer(file);
    // console.log(reader);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {!showBtn ? (
        <UploadZoneContainer {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} multiple={false} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <ContentPasteGoIcon fontSize="large" />
              <Typography variant="body2" color="textPrimary">
                Import Video
              </Typography>
            </>
          )}
        </UploadZoneContainer>
      ) : (
        <UploadZoneContainer>
          {/* <ContentPasteGoIcon fontSize="large" /> */}
          <Typography variant="body2" color="textPrimary">
            Import Video
          </Typography>
        </UploadZoneContainer>
      )}
    </>
  );
};

export default UploadZone;
