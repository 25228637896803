import React from 'react';

const NotFound = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '10%'
      }}
    >
      <img src="/404-not-found.png" alt="404" style={{ maxWidth: '100%' }} />
    </div>
  );
};

export default NotFound;
