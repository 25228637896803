/* eslint-disable */
import {
  Box,
  BoxProps,
  Grid,
  Icon,
  IconButton,
  IconButtonProps,
  Popover,
  Typography,
  styled
} from '@mui/material';
import Card from 'components/Card';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseFullscreenSharpIcon from '@mui/icons-material/CloseFullscreenSharp';
import InfoIcon from '@mui/icons-material/Info';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import Switch from '@mui/material/Switch';
import ZoomOut from '../../assets/images/zoomOut.png';
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks';

type IWidgetInfo =
  | string
  | {
      title: string;
      content: string | React.ReactNode;
    };

interface ISwitchProps {
  state: boolean;
  onToggle: (state: boolean) => void;
  enabled: boolean;
  title: string;
}

interface ExtraItemsProps {
  icon: React.ReactNode;
  onClick: () => void;
  content: React.ReactNode;
}

interface IWidgetProps {
  hiddable?: boolean;
  hidden?: boolean;
  zoomIn?: boolean;
  onResize?: boolean;
  onToggle?: () => void;
  title?: string;
  className?: string;
  info?: IWidgetInfo;
  extra?: ExtraItemsProps | ExtraItemsProps[];
  switchProps?: ISwitchProps; // Add switchProps to IWidgetProps
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  padding: 0
}));

export const Widget: React.FC<PropsWithChildren<IWidgetProps>> = ({
  title,
  hiddable,
  hidden = false,
  zoomIn = false,
  onToggle,
  onResize,
  info,
  children,
  switchProps // Destructure switchProps
}) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `widget-${Math.random()}`
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const newSize = containerRef.current.offsetWidth; // or any other logic to determine size
        if (onResize) {
          // Trigger re-render or update size-related state here
        }
      }
    };

    if (onResize) {
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial call to set the size
    }

    return () => {
      if (onResize) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [onResize]);

  return (
    <Card
      style={{
        display: hidden ? 'none' : 'block'
      }}
      ref={containerRef}
    >
      <Box sx={{ display: 'flex' }}>
        <Box display="flex" flexGrow={1}></Box>

        <Box display="flex" flexGrow={1}>
          <Typography variant="subtitle2" fontWeight={'bold'}>
            {title}
          </Typography>
        </Box>

        <Box display="flex" sx={{ width: 'fit-content' }} gap={3}>
          {switchProps && ( // Check if switchProps exist
            <Box display="flex" flexGrow={1}>
              <Switch size={'small'} {...switchProps} />
              <Typography variant="subtitle2" style={{ marginTop: '3px' }}>
                {switchProps?.title}
              </Typography>
            </Box>
          )}
          {info && (
            <Box flexGrow={1}>
              <StyledIconButton>
                <InfoIcon fontSize="small" {...bindTrigger(popupState)} />
                <Popover
                  {...bindMenu(popupState)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Box p={2}>
                    {typeof info === 'string' ? (
                      <Typography variant="subtitle2" fontWeight={'bold'}>
                        {info}
                      </Typography>
                    ) : typeof info === 'object' ? (
                      <>
                        <Typography variant="subtitle1" fontWeight={'bold'}>
                          {info.title}
                        </Typography>
                        <Typography variant="body2">{info.content}</Typography>
                      </>
                    ) : (
                      info
                    )}
                  </Box>
                </Popover>
              </StyledIconButton>
            </Box>
          )}

          {hiddable ? (
            <Box display="flex" flexGrow={1}>
              <StyledIconButton onClick={() => onToggle && onToggle()}>
                {hidden ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <RemoveRedEyeIcon fontSize="small" />
                )}
              </StyledIconButton>
            </Box>
          ) : (
            <Box display="flex" flexGrow={1}>
              <StyledIconButton onClick={() => onToggle && onToggle()}>
                {hidden ? (
                  <CloseFullscreenSharpIcon fontSize="small" />
                ) : (
                  // <RemoveRedEyeIcon fontSize="small" />
                  <img src={ZoomOut} alt="Zoom Out" />
                )}
              </StyledIconButton>
            </Box>
          )}
        </Box>
      </Box>
      <Box>{children}</Box>
    </Card>
  );
};
