import { Stack, Typography, IconButton } from '@mui/material';
import { Card } from 'components';
import { Barchart } from 'components/charts/barchart';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const SingleReport = ({
  getVideo,
  indefenceVideoData,
  setOpenState,
  openState
}: any) => {
  const videoData = indefenceVideoData
    ? indefenceVideoData?.filter((data: any) => data?.name === getVideo)
    : [];

  const labels = videoData[0]?.graph_data?.activities || [];
  let xAxisLabels = [];
  for (let i = 1; i < videoData[0]?.graph_data?.activities?.length; i++) {
    xAxisLabels.push(`${i}-${videoData[0]?.graph_data?.activities[i]}`);
  }

  const getTrimmedLabel = (label: string) => {
    return label.length > 10 ? label.slice(0, 10) + '...' : label;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Duration',
        data: videoData[0]?.graph_data?.duration || [],
        backgroundColor: videoData[0]?.graph_data?.sequence
          ?.flat()
          .map((value: boolean) => (value ? '#3F9827' : '#D02220'))
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
        align: 'end' as const,
        labels: {
          usePointStyle: true,
          font: {
            size: 10
          }
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipitems: any) => {
            const tooltipItem = tooltipitems[0];
            const datasetIndex = tooltipItem.datasetIndex;
            const dataIndex = tooltipItem.dataIndex;
            const modelName = videoData[0]?.model?.model_name;
            const originalTitle = tooltipItem.label;
            return modelName
              ? `${originalTitle} / ${modelName}`
              : originalTitle;
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          display: true,
          autoSkip: false,
          minRotation: 30,
          maxRotation: 30,
          callback: (value: any, index: number, values: any) => {
            const originalLabel = labels[index];
            return getTrimmedLabel(originalLabel);
          }
        },
        title: {
          display: true,
          text: 'Activity'
        },
        afterFit(scale: any) {
          scale.width = 90;
        }
      },
      y: {
        title: {
          display: true,
          text: 'Duration(sec)'
        }
      }
    }
  };

  return videoData?.length ? (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width={'100%'}
      >
        <Typography fontWeight={'bold'}>
          Activity Duration- {getVideo}{' '}
        </Typography>
        <Stack>
          {openState === 0 ? (
            <IconButton onClick={() => setOpenState(2)}>
              <OpenInFullIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpenState(0)}>
              <CloseFullscreenIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Barchart
        data={data}
        options={options}
        openState={openState}
        yAxisTitle="Unit ->"
      />
    </Card>
  ) : (
    ''
  );
};

export default SingleReport;
