import { Stack } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import routes from 'routes/urls';

import { useVerifyAdminRegister } from 'api/sdk';
import { Button, Typography } from 'components';
import { TextField } from 'components/Form';
import { useSnack } from 'plugins/snack';
import { convertServerErrors } from 'utils/form';

interface IFormValues {
  username: string;
  password: string;
  confirmPassword: string;
}

const ValidateRegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const snack = useSnack();
  const [queryParameters] = useSearchParams();

  const token = queryParameters.get('token');

  const initialValues: IFormValues = {
    username: '',
    password: '',
    confirmPassword: ''
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .required('Username is required')
      .min(5, 'Username must be at least 5 characters'),
    password: Yup.string()
      .trim()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), 'null'],
      'Confirm password must match password'
    )
  });

  const { mutateAsync, isLoading, isError, error } = useVerifyAdminRegister();

  return (
    <Stack spacing={3} style={{ marginTop: 50 }}>
      {!token && (
        <Typography variant="h6" color={'white'} textAlign={'center'}>
          Invalid token
        </Typography>
      )}

      {token && (
        <Formik<IFormValues>
          initialValues={initialValues}
          onSubmit={(values, formikHelper) => {
            mutateAsync({
              data: {
                token,
                username: values.username,
                password: values.password
              }
            })
              .then(() => {
                snack({
                  message: 'Account created successfully. You can now login',
                  severity: 'success'
                }).then(() => {
                  navigate(routes.LOGIN.generate());
                });
              })
              .catch(err => {
                if (err.response?.status === 422) {
                  formikHelper.setErrors(
                    convertServerErrors(err.response.data.detail)
                  );
                }
              });
          }}
          validationSchema={validationSchema}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h6" color={'white'}>
                  Register New Account
                </Typography>
                <TextField
                  name="username"
                  label="Username"
                  placeholder="Enter username"
                  boootstrap
                  light
                />
                <TextField
                  name="password"
                  label="Password"
                  placeholder="Enter password"
                  type="password"
                  boootstrap
                  light
                />
                <TextField
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm password"
                  type="password"
                  boootstrap
                  light
                />
                <Typography variant="body2" color="red" align="right">
                  {isError && error.response?.status !== 422
                    ? String(error?.response?.data?.detail)
                    : ' '}
                </Typography>
                <Button type="submit" fullWidth>
                  {isLoading ? 'Submitting...' : 'Submit'}
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default ValidateRegisterPage;
