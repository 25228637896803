import { FormControl, FormHelperText } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { InputLabel } from './Inputs/InputLabel';

export interface IBaseInputProps {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
  helperText?: string;
  error?: string;
  light?: boolean;
  fullwidth?: boolean;
  width?: string | number;
}

export const BaseInput: React.FC<IBaseInputProps & PropsWithChildren> = ({
  id,
  name,
  label,
  required,
  helperText,
  error,
  light,
  fullwidth,
  width,
  children
}) => {
  const inputId = id || name;

  return (
    <FormControl
      variant="standard"
      fullWidth={fullwidth}
      style={{
        width: width || '100%',
        background: 'transparent'
      }}
    >
      {label && (
        <InputLabel
          htmlFor={inputId}
          shrink
          required={required}
          light={light}
          size="small"
          sx={{ background: 'white' }}
        >
          {label}
        </InputLabel>
      )}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};
