import { useState } from 'react';

import { AppLoading } from 'components';
import { useAuth } from 'hooks';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

/**
 * Renders routes depending on Authenticated or Anonymous users
 * @component Routes
 */
const Routes = () => {
  const [refresh] = useState(0);
  const { isLoggedIn, loading } = useAuth();

  if (loading) {
    return <AppLoading />;
  }

  return (
    <>
      {isLoggedIn ? (
        <PrivateRoutes key={refresh} />
      ) : (
        <PublicRoutes key={refresh} />
      )}
    </>
  );
};
export default Routes;
