import React from 'react';

interface IProps {
  height?: number;
  width?: number;
}

const EmployeeProductivityIcon: React.FC<IProps> = ({
  height = 50,
  width = 50
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 80 75.999"
      >
        <g id="Group_56280" data-name="Group 56280" transform="translate(0 0)">
          <g id="Group_56279" data-name="Group 56279">
            <path
              id="Path_63967"
              data-name="Path 63967"
              d="M63.744,353.615a4.007,4.007,0,1,0-4.007-4.006A4.006,4.006,0,0,0,63.744,353.615Zm0-5.342a1.335,1.335,0,1,1-1.335,1.335A1.335,1.335,0,0,1,63.744,348.273Z"
              transform="translate(-50.388 -293.612)"
            />
            <path
              id="Path_63968"
              data-name="Path 63968"
              d="M132.01,353.615A4.007,4.007,0,1,0,128,349.609,4.006,4.006,0,0,0,132.01,353.615Zm0-5.342a1.335,1.335,0,1,1-1.335,1.335A1.335,1.335,0,0,1,132.01,348.273Z"
              transform="translate(-108.006 -293.612)"
            />
            <path
              id="Path_63969"
              data-name="Path 63969"
              d="M200.277,353.615a4.007,4.007,0,1,0-4.007-4.006A4.006,4.006,0,0,0,200.277,353.615Zm0-5.342a1.335,1.335,0,1,1-1.335,1.335A1.335,1.335,0,0,1,200.277,348.273Z"
              transform="translate(-165.609 -293.612)"
            />
            <path
              id="Path_63970"
              data-name="Path 63970"
              d="M268.544,353.615a4.007,4.007,0,1,0-4.007-4.006A4.006,4.006,0,0,0,268.544,353.615Zm0-5.342a1.335,1.335,0,1,1-1.335,1.335A1.335,1.335,0,0,1,268.544,348.273Z"
              transform="translate(-223.211 -293.612)"
            />
            <path
              id="Path_63971"
              data-name="Path 63971"
              d="M336.81,353.615a4.007,4.007,0,1,0-4.006-4.006A4.006,4.006,0,0,0,336.81,353.615Zm0-5.342a1.335,1.335,0,1,1-1.336,1.335A1.335,1.335,0,0,1,336.81,348.273Z"
              transform="translate(-280.812 -293.612)"
            />
            <path
              id="Path_63972"
              data-name="Path 63972"
              d="M405.077,353.615a4.007,4.007,0,1,0-4.007-4.006A4.006,4.006,0,0,0,405.077,353.615Zm0-5.342a1.335,1.335,0,1,1-1.335,1.335A1.335,1.335,0,0,1,405.077,348.273Z"
              transform="translate(-338.432 -293.612)"
            />
            <path
              id="Path_63973"
              data-name="Path 63973"
              d="M78.667,86.135H76v-8h.842A3.157,3.157,0,0,0,80,74.978V62.626a3.155,3.155,0,0,0-2.666-3.115V54.775a3.307,3.307,0,0,0-2.667-3.243v-37.4A1.333,1.333,0,0,0,73.333,12.8H6.667a1.333,1.333,0,0,0-1.333,1.333v37.4a3.308,3.308,0,0,0-2.667,3.243V59.51A3.156,3.156,0,0,0,0,62.626V74.978a3.159,3.159,0,0,0,3.159,3.157H4v8H1.334a1.333,1.333,0,1,0,0,2.667H78.667a1.333,1.333,0,1,0,0-2.667ZM8,15.469H72v36H66.667V22.135A1.333,1.333,0,0,0,65.334,20.8H14.667a1.333,1.333,0,0,0-1.333,1.333V51.468H8Zm42.666,36V46.135A1.333,1.333,0,0,0,49.334,44.8H30.667a1.333,1.333,0,0,0-1.333,1.333v5.333H16v-28H29.334V28.8H26.667V27.468a1.333,1.333,0,0,0-2.667,0V32.8a1.333,1.333,0,1,0,2.667,0V31.468h2.667v4A1.333,1.333,0,0,0,30.667,36.8h4v4A1.333,1.333,0,0,0,36,42.135h8A1.333,1.333,0,0,0,45.333,40.8v-4h4a1.333,1.333,0,0,0,1.333-1.333v-4h2.667V32.8A1.333,1.333,0,1,0,56,32.8V27.468a1.333,1.333,0,0,0-2.667,0V28.8H50.667V23.469H64v28Zm-2.667,0H32v-4H48Zm0-17.333H32V23.469H48ZM37.334,36.8h5.333v2.667H37.334V36.8Zm-32,17.973a.641.641,0,0,1,.64-.64H74.027a.641.641,0,0,1,.64.64v4.693H5.334Zm-2.667,20.2V62.626a.492.492,0,0,1,.492-.491H76.842a.491.491,0,0,1,.491.491V74.978a.491.491,0,0,1-.491.491H3.159A.492.492,0,0,1,2.667,74.978Zm62.667,3.157v8H14.667v-8Zm-58.666,8v-8H12v8Zm61.333,0v-8h5.333v8Z"
              transform="translate(0 -12.802)"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default EmployeeProductivityIcon;
