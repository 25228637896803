import { createSlice } from '@reduxjs/toolkit';

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {
    rowsPerPage: 10, // Default value
    pagenumber: 0,
    sortModel: []
  },
  reducers: {
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pagenumber = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    }
  }
});

export const { setRowsPerPage, setPageNumber, setSortModel } =
  paginationSlice.actions;
export default paginationSlice.reducer;
