import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Height } from '@mui/icons-material';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import { overflowWrap } from 'html2canvas/dist/types/css/property-descriptors/overflow-wrap';
import './index.css';
type DroppableItemProps = {
  children: React.ReactNode;
  id: string;
  rows?: any;
};

const Droppable = ({ id, children, rows }: DroppableItemProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id
  });

  const tableRef = useRef<any>();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }
  }, [rows.length]);

  const style: CSSProperties = {
    transition: 'border 0.3s ease',
    marginTop: '5px',
    height: '100%',
    maxHeight: '56vh',
    overflowY: 'auto',
    border: isOver ? '2px dotted #000' : '2px solid transparent'
  };

  return (
    <div ref={tableRef} style={style}>
      <div ref={setNodeRef}>{children}</div>
    </div>
  );
};

export default Droppable;
