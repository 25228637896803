import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useState
} from 'react';

import { Snack, SnackbarOptions } from './Snack';

// eslint-disable
const snackServiceContext = createContext<
  (options: Omit<SnackbarOptions, 'onClose'>) => Promise<any>
>(Promise.reject);
// eslint-enable

export const useSnack = () => useContext(snackServiceContext);

interface providerProps {
  children?: React.ReactNode;
  defaultOptions?: SnackbarOptions;
}

const defaultOptions = {
  snackbarProps: {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    autoHideDuration: 3000
  }
} as SnackbarOptions;

export const SnackServiceProvider: React.FC<providerProps> = props => {
  const [options, setOptions] = useState<Omit<SnackbarOptions, 'onClose'>>({
    ...defaultOptions,
    ...props.defaultOptions
  });
  const [resolveReject, setResolveReject] = useState(
    // eslint-disable-next-line
    [] as ((a?: any) => void)[]
  );
  const [resolve, _] = resolveReject;

  const handleClose = useCallback(() => {
    resolve();
    setResolveReject([]);
  }, [resolve]);

  const snack = useCallback(
    (customOptions: Omit<SnackbarOptions, 'onClose'>) => {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        setOptions({
          ...options,
          ...customOptions
        });
        setResolveReject([resolve, reject]);
      });
    },
    []
  );

  return (
    <Fragment>
      <snackServiceContext.Provider value={snack}>
        {props.children}
      </snackServiceContext.Provider>
      <Snack
        {...options}
        snackbarProps={{
          ...options.snackbarProps,
          open: resolveReject.length === 2
        }}
        onClose={handleClose}
      ></Snack>
    </Fragment>
  );
};
