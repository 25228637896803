import { Box, LinearProgress, Stack, styled } from '@mui/material';
import React from 'react';
import { PipelineStatus } from 'types';

import { Button } from 'components/Button/Button';
import Card from 'components/Card';
import { Typography } from 'components/Typography/Typography';

export interface ProgressBarItem {
  title: string;
  id: string;
  status: PipelineStatus;
  progress: number;
}

interface ProgressBarProps {
  items: ProgressBarItem[];
  title: string;
}

const ProgressBarRow = styled('tr')({});

const Column = styled('td')({
  paddingTop: '10px',
  paddingBottom: '10px'
});

const getProgressType = (status: PipelineStatus, progress: number) => {
  if (
    status === PipelineStatus.FAILED ||
    status === PipelineStatus.COMPLETED ||
    status === PipelineStatus.QUEUED
  ) {
    return 'determinate';
  }
  if (status === PipelineStatus.TRAINING) {
    return 'indeterminate';
  }

  if (
    status === PipelineStatus.PROCESSING &&
    (progress === 0 || progress === 99)
  ) {
    return 'indeterminate';
  }

  return 'determinate';
};

const ProgressBarAction: React.FC<{
  status: PipelineStatus;
  onViewClick: () => void;
  onInterruptClick: () => void;
  disableInterrupt: boolean;
}> = ({ status, onViewClick, onInterruptClick, disableInterrupt }) => {
  const renderStatus = () => {
    switch (status) {
      case PipelineStatus.QUEUED:
        return (
          // <Button
          //   variant="contained"
          //   color="primary"
          //   version="light"
          //   size="small"
          // >
          //   Cancel
          // </Button>
          <></>
        );
      case PipelineStatus.PROCESSING:
        return (
          <Button
            variant="contained"
            color="primary"
            version="light"
            size="small"
            onClick={onInterruptClick}
            disabled={disableInterrupt}
          >
            {disableInterrupt ? 'Interrupting' : 'Interrupt'}
          </Button>
        );
      case PipelineStatus.COMPLETED:
        return (
          <Button
            variant="contained"
            color="primary"
            version="light"
            size="small"
            onClick={onViewClick}
          >
            View
          </Button>
        );
      case PipelineStatus.FAILED:
        return (
          <Button
            variant="contained"
            color="error"
            version="light"
            size="small"
          >
            Retry
          </Button>
        );
      case PipelineStatus.TRAINING:
        return (
          <Button
            variant="contained"
            color="primary"
            version="light"
            size="small"
            disabled
          >
            TRAINING
          </Button>
        );
      default:
        return (
          // <Button
          //   variant="contained"
          //   color="primary"
          //   version="light"
          //   size="small"
          //   onClick={onInterruptClick}
          //   disabled={disableInterrupt}
          // >
          //   {disableInterrupt ? 'Interrupting' : 'Interrupt'}
          // </Button>
          <></>
        );
    }
  };

  return renderStatus();
};

const PipelineProgressBar: React.FC<
  ProgressBarProps & {
    onViewClick: (cameraId: string) => void;
    onInterruptClick: (cameraId: string) => void;
    disableInterrupt: boolean;
  }
> = ({ items, title, onViewClick, onInterruptClick, disableInterrupt }) => {
  return (
    <Box>
      <Typography variant="body1">{title}</Typography>
      <table
        width={'100%'}
        style={{
          marginTop: '20px'
        }}
      >
        {items?.length > 0 &&
          items.map((item, idx) => {
            return (
              <>
                <ProgressBarRow>
                  <Column>
                    <Typography
                      style={{
                        whiteSpace: 'nowrap',
                        width: 'fit-content'
                      }}
                      variant="body2"
                    >
                      {item.title}
                    </Typography>
                  </Column>
                  <Column style={{ width: '100%' }}>
                    <Stack spacing={2} direction={'column'}>
                      <LinearProgress
                        variant={getProgressType(item.status, item.progress)}
                        value={item.progress}
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px'
                        }}
                      />
                      {item.status === PipelineStatus.FAILED && (
                        <Typography
                          variant="caption"
                          style={{ marginLeft: '10px' }}
                        >
                          Failed
                        </Typography>
                      )}
                    </Stack>
                  </Column>
                  <Column width={'70px'}>
                    <Typography
                      variant="body2"
                      style={{
                        whiteSpace: 'nowrap',
                        width: 'fit-content',
                        marginLeft: '10px',
                        marginRight: '10px'
                      }}
                    >
                      {item.progress !== undefined
                        ? `${parseInt(String(item.progress), 10)}%`
                        : ' '}
                    </Typography>
                  </Column>
                  <Column>
                    <ProgressBarAction
                      status={item.status}
                      onViewClick={() => onViewClick(item.id)}
                      onInterruptClick={() => onInterruptClick(item.id)}
                      disableInterrupt={disableInterrupt}
                    />
                  </Column>
                </ProgressBarRow>
              </>
            );
          })}
      </table>
    </Box>
  );
};

export default PipelineProgressBar;
