interface ServerError {
  loc: string;
  message: string;
  type: string;
}

export const convertServerErrors = (
  errors: ServerError[]
): { [key: string]: string } => {
  const error: { [key: string]: string } = {};

  errors.forEach(err => {
    error[err.loc] = err.message;
  });

  return error;
};
