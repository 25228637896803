import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material';

interface StyledSwitchProps extends SwitchProps {
  format?: 'outlined' | 'shadowed';
}

const StyledSwitch = styled((props: StyledSwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, format = 'shadowed' }) => ({
  ...(format === 'outlined' && {
    width: 42,
    height: 26,
    padding: 0,

    '& .MuiSwitch-switchBase': {
      color: '#000',
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#000',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      marginTop: 3,
      marginLeft: 3,
      border: '1px solid #000'
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      border: '1px solid #000',
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
}));

export { StyledSwitch as Switch };
