/* eslint-disable */
import React, { useState } from 'react';

import { Button, Typography } from 'components';
import { TextField } from 'components/Form';
import routes from 'routes/urls';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ResetPasswordForm from './resetPasswordForm';
import { Link } from 'react-router-dom';
import VerifyEmailForm from './verifyEmailForm';
import { useSnack } from 'plugins/snack';

const ResetPasswordPage: React.FC = () => {
  const [formState, setFormState] = useState<'reset' | 'verify'>('reset');
  const [email, setEmail] = useState('');
  const snack = useSnack();
  const navigate = useNavigate();

  const onSuccessfulReset = () => {
    // setFormState('verify');
    navigate(routes.LOGIN.generate());
    snack({
      message: 'Password reset successfully',
      severity: 'success'
    });
  };

  const onReset = (email: string) => {
    setEmail(email);
    setFormState('verify');
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 15
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            fontWeight={'bold'}
            color={'white'}
            align="left"
          >
            Reset Password
          </Typography>
          <Typography
            variant="body2"
            color={'white'}
            align="left"
            style={{ marginTop: 10 }}
          >
            Please enter your Email ID to receive verification code
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {formState === 'reset' ? (
            <>
              <ResetPasswordForm onSubmit={onReset} />
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                color={'white'}
                style={{ color: 'white' }}
              >
                OR
              </Divider>
              <Typography
                variant="body2"
                color={'white'}
                align="left"
                style={{ marginTop: 10, cursor: 'pointer' }}
                component={'a'}
                onClick={() => navigate(routes.LOGIN.generate())}
              >
                Back to Login
              </Typography>
            </>
          ) : (
            <>
              <VerifyEmailForm onSubmit={onSuccessfulReset} email={email} />
              <Typography
                variant="body2"
                color={'white'}
                align="left"
                style={{ marginTop: 10 }}
                component={'a'}
                onClick={() => setFormState('reset')}
              >
                Back to Reset
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPasswordPage;
