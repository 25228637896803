import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const InventoryManagement: React.FC<IProps> = ({
  height = 50,
  width = 50
  // color = '#fff'
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 77 77"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_14035"
              data-name="Rectangle 14035"
              width="77"
              height="77"
              transform="translate(0.081)"
            />
          </clipPath>
        </defs>
        <g
          id="inventory-svgrepo-com"
          transform="translate(-0.081)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_63976"
            data-name="Path 63976"
            d="M6.439,3.219A3.219,3.219,0,0,0,0,3.219V74.043a3.219,3.219,0,0,0,6.439,0V70.824H70.824v3.219a3.219,3.219,0,0,0,6.439,0V3.219a3.219,3.219,0,1,0-6.439,0V25.754H64.386V9.658a3.219,3.219,0,0,0-3.219-3.219H35.412a3.219,3.219,0,0,0-3.219,3.219v3.219H16.1A3.219,3.219,0,0,0,12.877,16.1v9.658H6.439Zm25.754,16.1H19.316v6.439H32.193ZM6.439,32.193V64.386h6.439V41.851A3.219,3.219,0,0,1,16.1,38.631H41.851a3.219,3.219,0,0,1,3.219,3.219V45.07h16.1a3.219,3.219,0,0,1,3.219,3.219v16.1h6.439V32.193Zm51.508-6.439V12.877H38.631V25.754ZM38.631,64.386H19.316V45.07H38.631Zm6.439,0V51.508H57.947V64.386Z"
            transform="translate(0.012)"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </>
  );
};

export default InventoryManagement;
