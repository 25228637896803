import React from 'react';

import { Typography } from '../Typography/Typography';

interface PageTitleProps {
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <div style={{ paddingBottom: '30px' }}>
      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
    </div>
  );
};

export default PageTitle;
