import {
  useGetAllActivities,
  useGetAllObjects,
  useGetWorkflowDetail,
  usePostWorkflowDetail,
  useTrainSequenceModel
} from 'api/sdk';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect, useMemo, useState } from 'react';
import {
  Workflow,
  WorkflowDependencyItem,
  WorkflowInput,
  WorkflowResponse,
  WorkflowSchema,
  WorkflowSchemaInput
} from 'schemas';
import { arrayMove } from '@dnd-kit/sortable';

export const useWorkflow = () => {
  const { id } = useParams<{ id: string }>();

  const [selectedCameraID, setSelectedCameraID] = useState<string | null>();
  const [nextCameraID, setNextCameraID] = useState<string | null>();
  const [rows, setRows] = useState<WorkflowSchema>();
  const [tempRows, setTempRowsRows] = useState<WorkflowSchema>();

  const [activeId, setActiveId] = useState<string | null>(null);

  const cameras = useSelector((state: RootState) => state.configV2.cameras);

  const { data: allActivities } = useGetAllActivities(id || '', {
    pipeline_satus: true || null
  });

  const activities = useMemo(() => {
    if (allActivities?.data.activity_details) {
      return allActivities?.data.activity_details;
    }
    return [];
  }, [allActivities]);

  const { refetch } = useGetWorkflowDetail(id || '');

  useEffect(() => {
    id &&
      refetch().then(response => {
        if (response.data?.data) {
          setRows(response.data?.data[0]);
        }
      });
  }, [id]);

  const { mutateAsync, isLoading } = usePostWorkflowDetail();
  const {
    mutateAsync: train,
    isLoading: trainLoading,
    error: trainError
  } = useTrainSequenceModel();

  const addActivity = (data: Workflow) => {
    setRows(prevRows => {
      let rows = prevRows;
      if (!rows) {
        rows = {
          configuration_id: id || '',
          data: []
        };
      }
      rows.data = [...rows.data, data];
      return rows;
    });
  };

  const handleChangeDependency = (
    id: string,
    dependency: WorkflowDependencyItem[]
  ) => {
    setRows(prevRows => {
      return {
        configuration_id: id,
        data:
          prevRows?.data.map(workflow => {
            if (workflow.id === id) {
              return {
                ...workflow,
                dependency
              };
            }
            return workflow;
          }) || []
      };
    });
  };

  const handleSort = (
    active: string,
    over: string,
    selectedCameraID: string
  ) => {
    setRows(items => {
      const newItems = [...(items?.data || [])];
      const activeIndex = newItems.findIndex(item => item?.id === active);
      const overIndex = newItems.findIndex(item => item?.id === over);
      const activeItem = newItems[activeIndex];
      newItems.splice(activeIndex, 1);
      newItems.splice(overIndex, 0, activeItem);
      return {
        configuration_id: id || '',
        data: newItems
      };
    });
  };

  const handleSave = async (): Promise<void> => {
    await mutateAsync({
      configId: id || '',
      data: {
        configuration_id: id || '',
        data: rows?.data || []
      }
    });
    return;
  };

  return {
    activities,
    handleSave,
    selectedCameraID,
    cameras,
    id,
    nextCameraID,
    setNextCameraID,
    setSelectedCameraID,
    rows,
    setActiveId,
    activeId,
    addActivity,
    setRows,
    handleSort,
    handleChangeDependency,
    isLoading,
    train,
    trainLoading,
    trainError
  };
};
