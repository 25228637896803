// Dependency imports
import { IconButton, Paper, Slider, Stack } from '@mui/material';
import { Typography } from 'components';
import CircularWithValueLabel from 'components/progress/circularProgress';
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useDispatch } from 'react-redux';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

// Local imports
import { Check } from 'components/Form';
import DeleteAction from './deleteAction';
import SelectConfig from '../../assets/images/selectConfig.svg';
import { useSnack } from 'plugins/snack';
import { useAppSelector } from 'hooks';
import { AppDispatch, RootState } from 'store';
import { fetchVideos } from 'store/inference';
import { DataGridComponent } from 'components/DataGrid';
import { InferenceMode } from 'schemas/index';

interface IProps {
  rows: any[];
  loading: boolean;
  selected: string[];
  setSelected: (val: string[]) => void;
  handleButtonClick?: any;
  setVideoModalOpen: any;
  setVideoName: any;
  setCurrentVideo: any;
  setVideoReportQuery: any;
  setIsInferedVideo: any;
}

const ActionToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

function CircularProgressWithIcon(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} color="inherit" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <FileUploadIcon />
      </Box>
    </Box>
  );
}

const VideoStatus: React.FC<{
  status: string;
  uploadProgress?: number;
  processProgress?: number;
  fail_reason?: string;
}> = ({ status, uploadProgress, processProgress, fail_reason }) => {
  switch (status) {
    case 'UPLOADING':
      return (
        <>
          <Stack direction={'column'}>
            <div>
              <ActionToolTip title={'Uploading...'}>
                <CircularProgressWithIcon value={uploadProgress || 0} />
              </ActionToolTip>
            </div>
          </Stack>
        </>
      );
    case 'UPLOADED':
      return (
        <>
          <Stack direction={'column'}>
            <div>
              <ActionToolTip title={'Uploaded'}>
                <IconButton disableRipple style={{ color: 'blue' }}>
                  <CloudDoneIcon />
                </IconButton>
              </ActionToolTip>
            </div>
          </Stack>
        </>
      );
    case 'QUEUED':
      return (
        <>
          <Stack direction={'column'}>
            <div>
              <ActionToolTip title={'Queued'}>
                <IconButton disableRipple style={{ color: 'orange' }}>
                  <AddToQueueIcon />
                </IconButton>
              </ActionToolTip>
            </div>
          </Stack>
        </>
      );
    case 'PROCESSING':
      return (
        <Stack direction={'column'}>
          <div>
            <ActionToolTip title={'Processing...'}>
              <IconButton disableRipple style={{ color: 'orange' }}>
                <CircularWithValueLabel
                  variant={
                    processProgress === 0 ? 'indeterminate' : 'determinate'
                  }
                  value={processProgress || 0}
                  size={30}
                />
              </IconButton>
            </ActionToolTip>
          </div>
        </Stack>
      );
    case 'COMPLETED':
      return (
        <>
          <Stack direction={'column'}>
            <div>
              <ActionToolTip title={'Completed'}>
                <IconButton
                  disableRipple
                  style={{ color: 'green', alignContent: 'center' }}
                >
                  <CheckCircleIcon />
                </IconButton>
              </ActionToolTip>
            </div>
          </Stack>
        </>
      );
    case 'FAILED':
      return (
        <>
          <Stack direction={'column'}>
            <div>
              <ActionToolTip title={fail_reason}>
                <IconButton disableRipple style={{ color: 'red' }}>
                  <CancelRoundedIcon />
                </IconButton>
              </ActionToolTip>
            </div>
          </Stack>
        </>
      );
    default:
      return null;
  }
};

interface VideoActionProps {
  video_id: string;
  status: string;
  onDelete: () => void;
  onPlay?: () => void;
  isVideoUploading?: boolean;
}

const VideoAction: React.FC<VideoActionProps> = ({
  onDelete,
  onPlay,
  status,
  video_id,
  isVideoUploading = false
}) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <div>
        <ActionToolTip title={'Play'}>
          <IconButton onClick={onPlay} disabled={status !== 'COMPLETED'}>
            <PlayArrowIcon />
          </IconButton>
        </ActionToolTip>
      </div>
      <div>
        <ActionToolTip title={'Delete'}>
          <DeleteAction
            onDelete={onDelete}
            disabled={
              status === 'UPLOADING' ||
              status === 'PROCESSING' ||
              isVideoUploading
            }
            video_id={video_id}
          />
        </ActionToolTip>
      </div>
    </Stack>
  );
};

const LiveTable: React.FC<IProps> = ({
  selected,
  rows = [],
  loading = false,
  setSelected,
  setVideoModalOpen,
  handleButtonClick,
  setVideoName,
  setCurrentVideo,
  setVideoReportQuery,
  setIsInferedVideo
}) => {
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference?.currentConfigId
  );

  const dispatch = useDispatch<AppDispatch>();
  const snack = useSnack();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Select',
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      resizable: false,
      disableReorder: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <ActionToolTip title="Select">
          <Check
            version="dark"
            checked={selected.includes(String(params.value))}
            onChange={e => {
              e.preventDefault();
              if (selected.includes(String(params.value))) {
                setSelected(
                  selected.filter(val => val !== String(params.value))
                );
              } else {
                setSelected([...selected, String(params.value)]);
              }
            }}
            disabled={
              params.row.status === 'UPLOADING' ||
              params.row.status === 'PROCESSING'
            }
          />
        </ActionToolTip>
      )
    },
    {
      field: 'name',
      headerName: 'Video Name',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (params: any) => {
        const record = params.row;
        return (
          <h2
            style={{
              textDecoration:
                params.row.status !== 'UPLOADING' &&
                (params.row.status !== 'FAILED' || record.video_url !== null)
                  ? 'underline'
                  : 'none',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 400,
              margin: 0,
              padding: 0
            }}
            onClick={() => {
              if (
                params.row.status !== 'UPLOADING' &&
                (params.row.status !== 'FAILED' || record.video_url !== null)
              ) {
                setVideoName(params.value?.toString());
                setCurrentVideo(params.row.video_url);
                setVideoReportQuery(params.row);
                setVideoModalOpen(true);
              }
            }}
          >
            {params.value?.toString()}
          </h2>
        );
      }
    },
    {
      field: 'inference_end_time',
      headerName: 'Inferred At',
      headerClassName: 'super-app-theme--header',
      flex: 0.6,
      renderCell: (params: any) => (params?.value ? params.value : '-')
    },
    {
      field: 'model',
      headerName: 'Model Used',
      headerClassName: 'super-app-theme--header',
      flex: 0.6,
      renderCell: (params: any) => (params?.value ? params.value : '-')
    },
    {
      field: 'seq_check',
      headerName: 'Sequence',
      headerClassName: 'super-app-theme--header',
      flex: 0.6,
      renderCell: (params: GridCellParams) => (
        <>
          {params.value?.toString() === 'Valid' ? (
            <p
              style={{
                color: 'green',
                fontSize: 12,
                fontWeight: 'bold',
                margin: 0,
                padding: 0
              }}
            >
              Valid
            </p>
          ) : params.value?.toString() === 'Invalid' ? (
            <p
              style={{
                color: 'red',
                fontSize: 12,
                fontWeight: 'bold',
                margin: 0,
                padding: 0
              }}
            >
              Invalid
            </p>
          ) : (
            <p
              style={{
                color: 'orange',
                fontSize: 12,
                fontWeight: 'bold',
                margin: 0,
                padding: 0
              }}
            >
              -
            </p>
          )}
        </>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.4,
      headerClassName: 'super-app-theme--header',
      disableReorder: true,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <VideoStatus
          status={params.value as string}
          uploadProgress={params.row.upload_progress || 0}
          processProgress={params.row.process_progress || 0}
          fail_reason={params.row.fail_reason as string}
        />
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'super-app-theme--header',
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      flex: 0.6,
      renderCell: (params: GridCellParams) => (
        <VideoAction
          status={params.row.status}
          onPlay={() => {
            setVideoName(params.row.name.toString());
            setCurrentVideo(params.row.video_url);
            setIsInferedVideo(true);
            setVideoReportQuery(params.row);
            setVideoModalOpen(true);
          }}
          isVideoUploading={false}
          video_id={params.row.id || ''}
          onDelete={() => {
            snack({
              message: 'Video Deleted Successfully',
              severity: 'success'
            });
            dispatch(
              fetchVideos({
                config_id: currentConfigId || '',
                mode: InferenceMode.LIVE
              })
            );
            setSelected([]);
          }}
        />
      )
    }
  ];

  return (
    <>
      {currentConfigId !== 'Select Configuration' ? (
        rows?.length > 0 ? (
          <DataGridComponent
            columns={columns}
            rows={rows as any[]}
            loading={loading}
            disableRowSelectionOnClick={true}
          />
        ) : (
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '660px'
              }}
            >
              <div
                style={{
                  borderStyle: 'dashed'
                }}
              >
                <IconButton
                  onClick={() => {
                    if (currentConfigId !== 'Select Configuration') {
                      handleButtonClick();
                    }
                  }}
                  style={{ width: '20vw', padding: '20px', color: 'black' }}
                >
                  <SystemUpdateAltIcon />
                  <div style={{ marginLeft: '20px' }}>Import Videos</div>
                </IconButton>
              </div>
            </div>
          </Paper>
        )
      ) : (
        <Stack
          sx={{ height: '650px' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <img
            src={SelectConfig}
            alt="select_config"
            style={{ width: '260px' }}
          />
          <Typography sx={{ fontSize: '24px', color: '#707070' }}>
            Select configuration type to view Inference
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default LiveTable;
