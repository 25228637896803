import { generatePath } from 'react-router-dom';

import { ConfigurationListPage } from 'pages/configuration/list';
import WorkFlowAnnotation from 'pages/workFlowAnnotation';
import { TrainPage } from 'pages/train';
import InferenceReports from 'pages/InferenceReports';
import Settings from 'pages/settings';

export interface RouteCoonfig {
  [key: string]: {
    route?: string;
    generate?: () => string;
    component?: React.ReactNode;
    name: string;
    pageTittle?: string;
    items?: RouteCoonfig;
    disabled?: boolean;
    Icon?: any; // eslint-disable-line
  };
}

const config: RouteCoonfig = {
  CONFIGURATION: {
    name: 'Configuration',
    route: '/configuration',
    component: <ConfigurationListPage />
  },
  TRAINING: {
    name: 'Train',
    route: '/train/',
    component: <TrainPage />
  },
  INFERENCE: {
    name: 'Inference',
    route: '/inference/',
    component: <WorkFlowAnnotation />
  },
  REPORTS: {
    name: 'Reports',
    route: '/reports/recorded',
    component: <InferenceReports />
  },
  SETTINGS: {
    name: 'Settings',
    route: '/settings',
    component: <Settings />
  }
};

export default config;
