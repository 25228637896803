import { createSlice } from '@reduxjs/toolkit';

export const cameraLiveSlice = createSlice({
  name: 'cameraLive',
  initialState: {
    liveState: {
      liveConfig: '',
      liveCameraUrl: '',
      liveModel: ''
    }
  },
  reducers: {
    setLiveState: (state, action) => {
      state.liveState = action.payload;
    }
  }
});

export const { setLiveState } = cameraLiveSlice.actions;
export default cameraLiveSlice.reducer;
