import { Box, Checkbox, Stack, styled } from '@mui/material';
import { Typography } from 'components';
import React from 'react';

interface OptionProps {
  label: string;
  value: string;
  icon: React.ReactNode;
  disabled?: boolean;
}

interface InputsCheckProps {
  options: OptionProps[];
  values: string[];
  onChange: (values: string[], currentValue: string) => void;
  showCheckbox?: boolean;
  analyticsArray?: string[];
}

const CheckboxItem = styled(Box)<{ checked: boolean; disabled: boolean }>(
  ({ checked, disabled, theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    height: '100px',
    width: '100px',
    transition: 'background-color 0.3s ease',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    background: disabled ? theme.palette.grey[400] : 'white',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: checked
      ? theme.palette.primary.main
      : disabled
      ? theme.palette.grey[400]
      : 'white',
    '&:hover': {
      backgroundColor:
        !disabled && `${theme.palette.primary.main}${checked ? 'C8' : '64'}`
    },
    '&: .MuiCheckbox-root': {
      color: checked ? '#fff' : theme.palette.primary.main
    },
    '&:hover .MuiCheckbox-root': {
      color: !disabled && '#fff'
    },
    '&:hover svg': {
      fill: !disabled && '#fff',
      stroke: !disabled && '#fff'
    },
    '& svg': {
      fill: checked ? '#fff' : theme.palette.primary.main,
      stroke: checked ? '#fff' : theme.palette.primary.main
    }
  })
);

const InputsCheck: React.FC<InputsCheckProps> = ({
  options,
  values,
  onChange,
  showCheckbox = true,
  analyticsArray
}) => {
  return (
    <Stack direction={'row'} justifyContent={'space-around'}>
      {options.map(option => {
        const isDisabled =
          (analyticsArray && !analyticsArray.includes(option.value)) ||
          option.disabled === true;
        return (
          <div key={option.value}>
            <CheckboxItem
              checked={values.includes(option.value)}
              disabled={isDisabled || false}
              onClick={() => {
                if (!isDisabled) {
                  if (values.includes(option.value)) {
                    onChange(
                      values.filter(value => value !== option.value),
                      option.value
                    );
                  } else {
                    onChange([...values, option.value], option.value);
                  }
                }
              }}
            >
              <Checkbox
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  margin: '-7px',
                  display: showCheckbox ? 'block' : 'none'
                }}
                checked={values.includes(option.value)}
                disabled={isDisabled}
                onChange={() => {
                  if (!isDisabled) {
                    if (values.includes(option.value)) {
                      onChange(
                        values.filter(value => value !== option.value),
                        option.value
                      );
                    } else {
                      onChange([...values, option.value], option.value);
                    }
                  }
                }}
              />
              {option.icon}
            </CheckboxItem>
            <Typography
              variant="caption"
              maxWidth={100}
              align="center"
              component={'p'}
            >
              {option.label}
            </Typography>
          </div>
        );
      })}
    </Stack>
  );
};

export default InputsCheck;
