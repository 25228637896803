import { useUpdateConfiguration } from 'api/sdk';
import EditableLabel from 'components/EditableLabel';
import { useSnack } from 'plugins/snack';
import { useEffect, useState } from 'react';

interface editableName {
  value: string;
  configId: any;
  isRowEditing: boolean;
  setIsRowEditing: any;
}

const EditName = ({
  value,
  configId,
  isRowEditing,
  setIsRowEditing
}: editableName) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [getName, setName] = useState<string>(value);
  const { data, mutateAsync, isLoading, reset } = useUpdateConfiguration();
  const [inputError, setInputError] = useState<string | null>(null);
  const snack = useSnack();

  const handleSubmit = async (name: string): Promise<void> => {
    await mutateAsync({
      params: {
        config_id: configId?.id,
        name: name
      }
    })
      .then(() => {
        setEditing(false);
        setIsRowEditing(false);
        snack({
          message: 'Configuration name updated successfully',
          severity: 'success'
        });
      })
      .catch(error => {
        console.log('error', error);
        setInputError(error?.response?.data?.detail);
        snack({
          message: error?.response?.data?.detail,
          severity: 'error'
        });
      });
  };

  const handleValidation = (value: string) => {
    setInputError(null); // Reset error message

    if (!value) {
      setInputError('Value cannot be empty');
      return;
    }

    if (value.length < 4) {
      setInputError('Minimum value is 4');
      return;
    }

    if (value.length > 40) {
      setInputError('Maximum value is 40');
      return;
    }

    const regex = /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])[a-zA-Z0-9\W_]+$/;
    if (!regex.test(value)) {
      setInputError('Invalid characters. Input should be alphanumeric');
      return;
    }

    handleSubmit(value);
  };

  return (
    <div>
      {/* {value} */}
      <EditableLabel
        // gridView={gridView}
        value={getName}
        onChange={name => setName(name)}
        // loading={isLabelLoading}
        onCancel={() => {
          setIsRowEditing(false);
          setInputError(null);
          setEditing(false);
          setName(value);
        }}
        onSave={() => handleValidation(getName)}
        error={''}
        isEditing={isEditing}
        setIsEditing={setEditing}
        fontSize={16}
        inputError={inputError}
        isRowEditing={isRowEditing}
        setIsRowEditing={setIsRowEditing}
      />
    </div>
  );
};

export default EditName;
