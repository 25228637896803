import { Divider, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ActivitySchema } from 'store/config/schema';
import { SelectInput, TextInput } from 'components/Form';
import { Button, Typography } from 'components';
import { faL } from '@fortawesome/free-solid-svg-icons';

interface IAddClipForm {
  activities: ActivitySchema[];
  onSaved: (selectedActivity: string, newActivity: string) => void;
}

const AddActivityForm: React.FC<IAddClipForm> = ({ activities, onSaved }) => {
  const [newActivity, setNewActivity] = React.useState<string>('');
  const [selectedActivity, setSelectedActivity] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [isDisabled, setDisabled] = useState(false);
  const isValid = () => {
    if (activities.length > 0) {
      return Boolean(selectedActivity) || Boolean(newActivity);
    }
    return Boolean(newActivity);
  };

  useEffect(() => {
    let data = activities.filter(data => data?.activity === newActivity);
    data?.length > 0 ? setDisabled(true) : setDisabled(false);
  }, [newActivity]);

  const handleSave = (e: any) => {
    e.preventDefault();
    if (isValid()) {
      onSaved(selectedActivity, newActivity);
      setNewActivity('');
      setSelectedActivity('');
    }
  };

  useEffect(() => {
    setNewActivity('');
  }, [selectedActivity]);

  return (
    <div>
      <form onSubmit={handleSave}>
        <Stack spacing={2} alignContent={'center'}>
          {activities.length > 0 && (
            <>
              <SelectInput
                label="Select Activity"
                name="activity"
                options={[
                  { value: '', label: 'None' },
                  ...activities.map(activity => ({
                    value: activity.id,
                    label: activity.activity
                  }))
                ]}
                variant="outlined"
                value={selectedActivity}
                onChange={value => setSelectedActivity(value)}
              />
              <Divider />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                textAlign={'center'}
              >
                OR
              </Typography>
            </>
          )}
          {isDisabled && (
            <Typography variant="subtitle1" color="red" textAlign={'center'}>
              Activity already exists, please select from above given optoins.
            </Typography>
          )}
          <TextInput
            onKeyDown={(event: any) => event.stopPropagation()}
            label="New Activity Name"
            name="activity"
            variant="outlined"
            size="small"
            disabled={Boolean(selectedActivity)}
            value={newActivity}
            onChange={value => setNewActivity(value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={!isValid() || isDisabled}
          >
            Save
          </Button>
        </Stack>
      </form>
    </div>
  );
};

export default AddActivityForm;
