/* eslint-disable */
// Dependency imports
import { useEffect, useRef, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import { IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

// Local imports
import { useVideoReport } from 'api/sdk';
import CircularProgressWithLabel from 'components/progress/circularProgress';

// Style imports
import './styles.css';

interface notificationType {
  type?: string;
  value?: number;
  index?: number;
  heading?: string;
  timer?: string;
  videoQuery?: any;
  selectedConfig?: string;
  getVideoCurrentTime?: number;
  setVicdeoCurrentTime: any;
  seek: Function;
  start?: number;
  mode?: number;
}

interface notificationDataTypes {
  activity: string;
  sequence: any;
  start_time: number;
  end_time: number;
  setVicdeoCurrentTime: any;
  seek: Function;
  start: number;
  color: string;
  missing: number;
}

// video report useVideoReport

export const NotificationComponent: React.FC<notificationType> = ({
  type,
  value,
  videoQuery,
  selectedConfig,
  getVideoCurrentTime,
  setVicdeoCurrentTime,
  seek,
  mode = 0,
  start
}) => {
  const { data, refetch, isLoading } = useVideoReport(
    selectedConfig || '',
    videoQuery?.id || ''
  );
  const [notificationData, setNotificationData] = useState<any>([]);
  const notifyMainBlockRef = useRef<HTMLDivElement>(null);
  // Function to scroll the main block to the bottom
  const scrollToBottom = () => {
    if (notifyMainBlockRef.current) {
      notifyMainBlockRef.current.scrollTop =
        notifyMainBlockRef.current.scrollHeight;
    }
  };
  // Scroll to bottom whenever notificationData changes
  useEffect(() => {
    scrollToBottom();
  }, [notificationData]);

  // useEffect(() => {
  //   const matchingObject =
  //     getVideoCurrentTime &&
  //     data?.data &&
  //     data?.data.find((item: any) => {
  //       return item.start_time === Math.trunc(getVideoCurrentTime);
  //     });
  //   if (matchingObject) {
  //     // Check if the matching object already exists in the state
  //     const isDuplicate = notificationData.some(
  //       (item: any) => item.id === matchingObject.id
  //     );
  //     if (!isDuplicate) {
  //       // If not a duplicate, add the matching object to the state
  //       setNotificationData((prevState: any) => [...prevState, matchingObject]);
  //     }
  //   }
  // }, [getVideoCurrentTime, data?.data, start]);

  useEffect(() => {
    const matchingObjects =
      getVideoCurrentTime &&
      data?.data &&
      data?.data.filter(
        (item: any) => item.start_time <= Math.trunc(getVideoCurrentTime)
      );

    if (matchingObjects) {
      // Filter out duplicate objects by their ID
      const newNotifications = matchingObjects.filter(
        (matchingObject: any) =>
          !notificationData.some((item: any) => item.id === matchingObject.id)
      );

      // Add new notifications to the state
      if (newNotifications.length > 0) {
        setNotificationData((prevState: any) => [
          ...prevState,
          ...newNotifications
        ]);
      }
    }
  }, [getVideoCurrentTime, data?.data]);

  useEffect(() => {
    setNotificationData([]);
  }, [start]);

  function setNotificationColor(data: boolean | string) {
    if (data === true) {
      return 'rgb(0,248,0)';
    } else if (data === false) {
      return 'red';
    } else {
      return 'orange';
    }
  }

  useEffect(() => {
    videoQuery?.id && refetch();
  }, [videoQuery?.id]);

  function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  function calculateTaskPercent(taskVallue: notificationDataTypes) {
    const percentValue =
      getVideoCurrentTime &&
      (getVideoCurrentTime < taskVallue?.end_time
        ? ((getVideoCurrentTime - taskVallue?.start_time) * 100) /
          (taskVallue?.end_time - taskVallue?.start_time)
        : 100);
    return percentValue && percentValue < 0 ? 0 : percentValue;
  }

  return (
    <>
      {notificationData.length > 0 &&
        notificationData[notificationData.length - 1].ncycles !== undefined && (
          <div className="notify-container">
            {/* <LoopIcon
              style={{
                height: '30px',
                width: '30px',
                color: 'white'
              }}
            /> */}
            <div className="notify-main-data">
              <p style={{ margin: 0, padding: 0 }}>
                Sequence:{' '}
                {/* <span>
                  {notificationData[notificationData.length - 1]?.ncycles || 0}
                </span> */}
                <IconButton
                  disableRipple
                  style={{
                    color:
                      notificationData[notificationData.length - 1]
                        ?.sequence === 1
                        ? 'green'
                        : 'red',
                    alignContent: 'center'
                  }}
                >
                  {notificationData[notificationData.length - 1]?.sequence ==
                  1 ? (
                    <CheckCircleIcon />
                  ) : (
                    <CancelRoundedIcon />
                  )}
                </IconButton>
              </p>
            </div>
          </div>
        )}
      <div className="notify-main-block" ref={notifyMainBlockRef}>
        {notificationData.map((data: notificationDataTypes, index: number) => {
          return (
            <div
              className="notify-container"
              onClick={() => seek(data?.start_time)}
            >
              <CircularProgressWithLabel
                style={{
                  height: '40px',
                  width: '40px',
                  color: data?.color
                    ? data?.color
                    : setNotificationColor(data?.sequence)
                }}
                variant="determinate"
                value={
                  calculateTaskPercent(data) == 100 ? (
                    <IconButton
                      disableRipple
                      style={{
                        color: data?.color
                          ? data?.color
                          : setNotificationColor(data?.sequence),
                        alignContent: 'center'
                      }}
                    >
                      {data?.missing === 0 ? (
                        <CheckCircleIcon />
                      ) : (
                        // ) : data?.missing === 1 ? (
                        <ErrorOutlineTwoToneIcon />
                      )}
                    </IconButton>
                  ) : (
                    calculateTaskPercent(data)
                  )
                }
                size={60}
                // disableShrink
              />
              <div className="notify-main-data">
                <div className="notification-head">
                  <div>{`${index + 1} `} </div>
                  <div style={{ marginLeft: '5px', marginRight: '5px' }}>-</div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      width: '100%',
                      display: 'block',
                      boxSizing: 'border-box',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      marginBottom: '2px'
                    }}
                  >
                    {data?.activity}
                  </div>
                </div>
                <div className="timer">
                  {data.sequence === 'missing'
                    ? 'Sequence Missed'
                    : mode === 0
                    ? formatTime(data?.end_time - data?.start_time)
                    : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const NotificationList = ({
  videoQuery,
  selectedConfig,
  getVideoCurrentTime,
  setVicdeoCurrentTime,
  seek,
  mode,
  start
}: notificationType) => {
  const [type, setType] = useState<string>('success');
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 2));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="notification-bar">
      <NotificationComponent
        heading={'Crimp contact onto wire 2'}
        getVideoCurrentTime={getVideoCurrentTime}
        index={1}
        type={type}
        value={100}
        setVicdeoCurrentTime={setVicdeoCurrentTime}
        timer={'00:00:24'}
        videoQuery={videoQuery}
        selectedConfig={selectedConfig}
        seek={seek}
        start={start}
        mode={mode}
      />
    </div>
  );
};
