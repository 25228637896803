/* eslint-disable */

export const colors = {
  YELLOW: '#F7E967',
  PASTEL_RED: '#F26565',
  PICTON_BLUE: '#4AB6DF',
  GREEN: '#5DBB63',
  ELECTRIC_BLUE: '#6F8CE5',
  ORANGE: '#F5A962',
  PURPLE: '#A77DC2',
  LIGHT_BLUE: '#5CA9C9',
  LIGHT_GREEN: '#A1C181',
  LIGHT_ORANGE: '#F8B88B',
  LIGHT_PURPLE: '#BFA2DB',
  LIGHT_YELLOW: '#F8E3A1',
  LIGHT_GRAY: '#D3D3D3'
};

export const newShades = {
  ColorOne: '#102633',
  ColorTwo: '#003E4D',
  ColorThree: '#00565A',
  ColorFour: '#006F57',
  ColorFive: '#168645',
  ColorSix: '#669926',
  ColorSeven: '#AFA500',
  ColorEight: '#FFA600',

  // 30 new colors
  ColorNine: '#FF8C00',
  ColorTen: '#FF7300',
  ColorEleven: '#FF5900',
  ColorTwelve: '#FF4000',
  ColorThirteen: '#FF2600',
  ColorFourteen: '#FF0D00',
  ColorFifteen: '#FF0066',
  ColorSixteen: '#FF00A3',
  ColorSeventeen: '#E100FF',
  ColorEighteen: '#B800FF',
  ColorNineteen: '#8F00FF',
  ColorTwenty: '#6600FF',
  ColorTwentyOne: '#3D00FF',
  ColorTwentyTwo: '#1400FF',
  ColorTwentyThree: '#0033FF',
  ColorTwentyFour: '#0066FF',
  ColorTwentyFive: '#0099FF',
  ColorTwentySix: '#00CCFF',
  ColorTwentySeven: '#00FFFF',
  ColorTwentyEight: '#00FFD5',
  ColorTwentyNine: '#00FFAA',
  ColorThirty: '#00FF80',
  ColorThirtyOne: '#00FF55',
  ColorThirtyTwo: '#00FF2A',
  ColorThirtyThree: '#2AFF00',
  ColorThirtyFour: '#55FF00',
  ColorThirtyFive: '#80FF00',
  ColorThirtySix: '#AAFF00',
  ColorThirtySeven: '#D5FF00',
  ColorThirtyEight: '#FFFF00'
};

const colorValues = Object.values(newShades);

export const getColorList = (strings: string[]) => {
  const colorList: string[] = [];
  const colorMap: any = {};
  const totalColors = colorValues.length;

  strings.forEach((str, index) => {
    if (index < totalColors) {
      // Assign predefined colors
      colorMap[str] = colorValues[index];
    } else {
      // Generate new color using stringToColorCode
      colorMap[str] = stringToColorCode(str);
    }
  });

  // Create the final color list based on the mapped colors
  strings.forEach(str => {
    colorList.push(colorMap[str]);
  });

  return colorList;
};

export const stringToColorCode = (str: string): string => {
  // Convert the string to a single integer hash
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Use the hash to generate three RGB values (0-255)
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;

  // Convert RGB values to a hex string (ensure two digits)
  const color = `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

  return color;
};

export const decToHex = (dec: number): string => {
  return dec.toString(16);
};

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  color += decToHex(r) + decToHex(g) + decToHex(b);

  if (color.length < 7) {
    color = getRandomColor();
  }

  return color;
};
