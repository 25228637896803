/* eslint-disable  */

import React, { useEffect } from 'react';
import { UseSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'components/Accordion';
import { ChangePasswordForm } from './changePasswordForm';
import ProfileDetails from './ProfileDetails';
import { Stack, Typography } from '@mui/material';
import { CreateViewerForm } from './createViewerForm';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import moment from 'moment';

export type TabNames =
  | 'profile'
  | 'password'
  | 'license'
  | 'account'
  | 'viewer';

export interface ISettingsModalProps {
  openTab: TabNames;
}

export const SettingsModal: React.FC<ISettingsModalProps> = ({
  openTab = 'profile'
}) => {
  const [tab, setTab] = React.useState<TabNames>(openTab);
  const profile = useSelector((state: RootState) => state.auth.profile);

  useEffect(() => {
    setTab(openTab);
  }, [openTab]);

  return (
    <>
      <Stack spacing={2} sx={{ padding: 2 }} direction={'column'}>
        <Accordion
          aria-controls={`account-tab-control`}
          id="tab-account"
          expanded={tab === 'account'}
          onChange={() => setTab('account')}
        >
          <AccordionSummary>
            <Typography
              style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
            >
              Profile
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProfileDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          aria-controls={`license-tab-control`}
          id="tab-license"
          expanded={tab === 'license'}
          onChange={() => setTab('license')}
        >
          <AccordionSummary>
            <Typography
              style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
            >
              License
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-start"
              gap={2}
            >
              <Typography
                sx={{
                  width: '22%',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  whiteSpace: 'nowrap'
                }}
              >
                License Created on:
              </Typography>
              <Typography>
                {moment
                  .utc(profile?.license?.purchased_at)
                  .local()
                  .format('MMM DD, YY, h:mm a')}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-start"
              gap={2}
            >
              <Typography
                sx={{
                  width: '22%',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  whiteSpace: 'nowrap'
                }}
              >
                License Expires on:
              </Typography>
              <Typography>
                {moment
                  .utc(profile?.license?.expires_at)
                  .local()
                  .format('MMM DD, YY, h:mm a')}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-start"
              gap={2}
            >
              <Typography
                sx={{
                  width: '22%',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  whiteSpace: 'nowrap'
                }}
              >
                Purchased By:
              </Typography>
              <Typography>{profile?.license?.purchased_by}</Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion
          aria-controls={`password-tab-control`}
          id="tab-password"
          expanded={tab === 'password'}
          onChange={() => setTab('password')}
        >
          <AccordionSummary>
            {' '}
            <Typography
              style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
            >
              Change Password
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChangePasswordForm />
          </AccordionDetails>
        </Accordion>

        {/* Viewers Account */}

        {profile?.role === 'organization_admin' && (
          <Accordion
            aria-controls={`viewer-tab-control`}
            id="tab-viewer"
            expanded={tab === 'viewer'}
            onChange={() => setTab('viewer')}
          >
            <AccordionSummary>
              {' '}
              <Typography
                style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
              >
                Viewer Account
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* ViewerList Table and Create Viewer  */}
              <CreateViewerForm />
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
    </>
  );
};
