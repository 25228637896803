/* eslint-disable  */
import { Box, Skeleton } from '@mui/material';
import React from 'react';
import './styles.css';

interface VideoLoaderProps {
  width?: number;
  height: number;
}

const Spinner: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <rect
        fill={color}
        stroke="#FF156D"
        strokeWidth="15"
        width="30"
        height="30"
        x="25"
        y="85"
      >
        <animate
          attributeName="opacity"
          calcMode="spline"
          dur="2"
          values="1;0;1;"
          keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite"
          begin="-.4"
        ></animate>
      </rect>
      <rect
        fill="#FF156D"
        stroke="#FF156D"
        strokeWidth="15"
        width="30"
        height="30"
        x="85"
        y="85"
      >
        <animate
          attributeName="opacity"
          calcMode="spline"
          dur="2"
          values="1;0;1;"
          keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite"
          begin="-.2"
        ></animate>
      </rect>
      <rect
        fill="#FF156D"
        stroke="#FF156D"
        strokeWidth="15"
        width="30"
        height="30"
        x="145"
        y="85"
      >
        <animate
          attributeName="opacity"
          calcMode="spline"
          dur="2"
          values="1;0;1;"
          keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite"
          begin="0"
        ></animate>
      </rect>
    </svg>
  );
};

/**
 * Video loader component.
 */

const VideoLoader: React.FC<VideoLoaderProps> = ({}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [size, setSize] = React.useState({
    width: 0,
    height: 0
  });

  const defaultResolution = {
    width: 1920,
    height: 1080
  };

  const setContainerSize = () => {
    const width = containerRef.current?.clientWidth || 0;

    const height = (width * defaultResolution.height) / defaultResolution.width;

    setSize({
      width,
      height
    });
  };

  React.useEffect(() => {
    setContainerSize();
    window.addEventListener('resize', setContainerSize);

    return () => {
      window.removeEventListener('resize', setContainerSize);
    };
  }, []);

  return (
    <Box
      width={'100%'}
      ref={containerRef}
      height={size.height}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        position: 'relative'
      }}
    >
      <Skeleton variant="rectangular" width={'100%'} height={size.height} />

      {/* <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 60,
          height: 60
        }}
      >
        <Skeleton variant="rectangular" width={'60px'} height={'60px'} />
      </Box> */}
    </Box>
  );
};

export default VideoLoader;
