import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const WorkflowMonitorIcon: React.FC<IProps> = ({ height = 50, width = 50 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 58.096 58.174"
      >
        <path
          id="workflow-svgrepo-com"
          d="M18.165,39.314H4.961a2.641,2.641,0,0,0-2.641,2.641v13.2A2.641,2.641,0,0,0,4.961,57.8h13.2a2.641,2.641,0,0,0,2.641-2.641V51.2h9.242a2.641,2.641,0,0,0,0-5.281H20.805V41.955A2.641,2.641,0,0,0,18.165,39.314Zm-2.641,13.2H7.6V44.6h7.922Zm-6.6-30.743V32.712a2.641,2.641,0,0,0,5.281,0V21.775a10.563,10.563,0,1,0-5.281,0ZM11.563,6.306a5.281,5.281,0,1,1-5.281,5.281A5.281,5.281,0,0,1,11.563,6.306Zm39.61,32.063v-8.3a2.641,2.641,0,1,0-5.281,0v8.3a10.563,10.563,0,1,0,5.281,0ZM48.532,53.838a5.281,5.281,0,1,1,5.281-5.281A5.281,5.281,0,0,1,48.532,53.838Zm-6.6-33.008h13.2a2.641,2.641,0,0,0,2.641-2.641V4.986a2.641,2.641,0,0,0-2.641-2.641h-13.2A2.641,2.641,0,0,0,39.29,4.986V8.947H30.048a2.641,2.641,0,1,0,0,5.281H39.29v3.961A2.641,2.641,0,0,0,41.931,20.83Zm2.641-13.2h7.922v7.922H44.571Z"
          transform="translate(-1 -0.985)"
        />
      </svg>
    </>
  );
};

export default WorkflowMonitorIcon;
