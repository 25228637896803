import { IconButton, ListItem, Switch, Tooltip } from '@mui/material';
import { SelectInput } from 'components/Form';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import DependancyGraph from '../AnalysisConfiguration/WorkflowMonitor/dependancyGraph';
import DeleteIcon from '@mui/icons-material/Delete';
import { useWorkflow } from 'hooks/config/useWorkflow';
import { Button } from 'components';
import {
  updateSequenceModel,
  usePostWorkflowDetail,
  useUpdateSequenceModel
} from 'api/sdk';
import { useSnack } from 'plugins/snack';
import { useConfirm } from 'plugins/confirm';

const InfoPopover: React.FC<{
  model: any;
  isEdit: boolean;
  configId: string;
  handleClose: () => void;
  setEdit: Dispatch<SetStateAction<boolean>>;
}> = ({ model, isEdit, setEdit, configId, handleClose }) => {
  const { mutateAsync, isLoading } = useUpdateSequenceModel();
  const activities_map = model.workflow_config?.activities || {};
  const activities: any = Object.values(activities_map);
  const confirm = useConfirm();
  const [deps, setDeps] = useState(model.workflow_config?.workflow || []);
  const snack = useSnack();
  const handleSave = async (): Promise<void> => {
    await mutateAsync({
      configId: configId || '',
      modelId: model?.id,
      data: { workflow: deps || [], name: model?.model_name }
    });
    return;
  };

  // Memoized nodes
  const nodes: any[] = useMemo(() => {
    return deps.map((item: any, index: number) => ({
      id: item.id,
      data: {
        mendatory: item.mendatory,
        label:
          activities?.find((activity: any) => activity.id === item.activity_id)
            ?.activity ?? ''
      },
      position: { x: 50 * index, y: 100 * index }
    }));
  }, [deps, activities]);

  // Memoized edges
  const edges = useMemo(() => {
    return deps
      .filter(
        (item: any) =>
          Array.isArray(item.dependency) && item.dependency.length > 0
      )
      .flatMap((item: any) =>
        item.dependency.map((dep: any) => ({
          id: `e-${dep}-${item.id}`, // Update to use item.id as target
          source: dep,
          target: item.id
        }))
      );
  }, [deps]);

  // Add a new activity to deps
  const handleAddActivity = (data: any) => {
    setDeps((prevDeps: any) => [
      ...prevDeps,
      {
        id: `node-${data.id}-${prevDeps.length}`, // Unique id for each duplicate
        activity_id: data.id,
        activity_name: data.activity,
        dependency: [], // Initial empty dependency array
        mendatory: false
      }
    ]);
  };

  // Update dependencies for a specific node by its id
  const handleChangeDependency = (id: string, value: string[]) => {
    setDeps((prevDeps: any) =>
      prevDeps.map((dep: any) =>
        dep.id === id ? { ...dep, dependency: value } : dep
      )
    );
  };

  // Toggle mandatory for a specific node
  const handleToggleMandatory = (id: string) => {
    setDeps((prevDeps: any) =>
      prevDeps.map((dep: any) =>
        dep.id === id ? { ...dep, mendatory: !dep.mendatory } : dep
      )
    );
  };

  // Delete a specific node
  const handleDeleteNode = (id: string) => {
    setDeps((prevDeps: any) =>
      prevDeps
        .filter((dep: any) => dep.id !== id) // Remove the node itself
        .map((dep: any) => ({
          ...dep,
          dependency:
            dep.dependency?.filter((depId: string) => depId !== id) || [] // Ensure dependencies is an array before filtering
        }))
    );
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {isEdit && (
          <div
            style={{
              width: '30%',
              marginRight: '15px',
              height: '475px',
              overflowY: 'auto'
            }}
          >
            <div
              style={{
                backgroundColor: '#102633',
                color: 'white',
                padding: '5px 0px 0px 10px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                top: -2,
                zIndex: 1,
                marginTop: -2
              }}
            >
              <div style={{ fontSize: '15px', marginRight: '10px' }}>List</div>
              <div
                style={{
                  fontSize: '10px',
                  color: 'white',
                  marginBottom: '4px'
                }}
              >
                {'(Tap to add.)'}
              </div>
            </div>
            {Object.values(model?.workflow_config?.activities).map(
              (data: any, index: number) => (
                <ListItem
                  key={data?.id}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    height: '50px',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    overflowY: 'scroll',
                    textOverflow: 'ellipsis'
                  }}
                  onClick={() => handleAddActivity(data)} // Call handleAddActivity on click
                >
                  <Tooltip title={data?.activity || ''} arrow>
                    <span style={{ fontSize: '15px' }}>
                      {index + 1}. {data?.activity}
                    </span>
                  </Tooltip>
                </ListItem>
              )
            )}
          </div>
        )}
        {isEdit && (
          <div
            style={{
              width: '100%',
              marginRight: '15px',
              height: '475px',
              overflowY: 'auto'
            }}
          >
            <div
              style={{
                backgroundColor: '#102633',
                color: 'white',
                padding: '5px 0px 0px 10px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                top: -2,
                zIndex: 1,
                marginTop: -2
              }}
            >
              <div style={{ fontSize: '15px', marginRight: '10px' }}>
                Selected
              </div>
            </div>
            {deps.map((data: any, index: number) => (
              <div key={data?.id} style={{ display: 'flex' }}>
                <ListItem
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    height: '50px',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    overflowY: 'scroll',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <Tooltip title={data?.activity_name || ''} arrow>
                    <span style={{ fontSize: '15px' }}>
                      {index + 1}. {data?.activity_name}
                    </span>
                  </Tooltip>
                </ListItem>
                <div
                  style={{
                    width: '150px',
                    backgroundColor: 'white',
                    border: '1px solid black'
                  }}
                >
                  <SelectInput
                    name=""
                    multiple={true}
                    options={deps
                      .filter((dataM: any) => dataM !== data)
                      .map((act: any) => ({
                        value: act.id,
                        label: act.activity_name
                      }))}
                    value={data?.dependency}
                    placeholder=""
                    variant="outlined"
                    maxWidth={'250px'}
                    onChange={value => handleChangeDependency(data.id, value)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: 'white',
                    border: '1px solid black',
                    alignItems: 'center'
                  }}
                >
                  <Switch
                    checked={data?.mendatory || false}
                    onChange={() => handleToggleMandatory(data.id)} // Toggle mandatory status
                  />

                  <IconButton
                    aria-label="delete"
                    disableRipple
                    onClick={() => handleDeleteNode(data.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
        <DependancyGraph nodes={nodes} edges={edges} />
      </div>
      {isEdit ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px'
          }}
        >
          <Button
            version="light"
            children={'Cancel'}
            style={{ marginRight: '10px' }}
            onClick={() => {
              confirm({
                title: 'Cancel Changes',
                content: `Are you sure you want to exit, changes will be lost?`
              })
                .then(() => {
                  setDeps(model.workflow_config?.workflow);
                  setEdit(false); // Call handleDelete if the user confirms
                  handleClose();
                })
                .catch(() => {
                  // Do nothing if the user cancels
                });
            }}
          />
          <Button
            version="dark"
            children={'Save'}
            onClick={() => {
              handleSave().then(() => {
                snack({
                  message: 'Workflow saved successfully',
                  severity: 'success'
                });
                handleClose();
                setEdit(false);
              });
            }}
          />
        </div>
      ) : (
        <div
          style={{
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px'
          }}
        >
          <Button
            version="light"
            children={'Edit'}
            onClick={() => setEdit(true)}
          />
        </div>
      )}
    </div>
  );
};

export default InfoPopover;
