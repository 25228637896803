/* eslint-disable */
import { styled } from '@mui/material';
import React from 'react';
import CircularWithValueLabel from 'components/progress/circularProgress';
import { Typography } from 'components/Typography/Typography';
import { Button } from 'components/Button/Button';

const UploadProgressContainer = styled('div')`
  border: 1px solid black;
  height: 100%;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;
interface UploadProgressProps {
  value: number;
  onCancel?: () => void;
}

const UploadProgress: React.FC<UploadProgressProps> = ({ value, onCancel }) => {
  return (
    <UploadProgressContainer>
      <CircularWithValueLabel value={value} />
      <br />
      <Typography variant="body2" textAlign={'center'}>
        Please wait, <br />
        uploading in progress...
        <br />
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        version="light"
        size="small"
        onClick={() => onCancel && onCancel()}
      >
        Cancel
      </Button>
    </UploadProgressContainer>
  );
};

export default UploadProgress;
