import React, { useEffect, useRef } from 'react';
import ReactFlow, {
  addEdge,
  Controls,
  useNodesState,
  useEdgesState,
  Connection,
  ReactFlowInstance,
  MiniMap,
  Background
} from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { Tooltip } from '@mui/material';

const getLayoutedElements = (nodes: any, edges: any) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeWidth = 172;
  const nodeHeight = 36;

  dagreGraph.setGraph({ rankdir: 'TB' });

  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: nodeHeight
    });
  });

  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'top';
    node.sourcePosition = 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2
    };

    node.data.label = (
      <Tooltip title={node?.data?.label}>
        <div>
          {node?.data?.label.length > 18
            ? node?.data?.label?.slice(0, 18) + '...'
            : node?.data?.label}
        </div>
      </Tooltip>
    );

    // Add red background color
    node.style = {
      backgroundColor: node?.data?.mendatory ? '#808080' : 'white',
      color: node?.data?.mendatory ? 'white' : '#808080'
    };

    return node;
  });

  edges.forEach((edge: any) => {
    edge.animated = true;
  });

  return { nodes, edges };
};

export interface FlowComponentsProps {
  onNodeClick?(element: any): void;
  nodes: any[];
  edges: any[];
  width?: number | string;
  isOpen?: boolean;
}

const DependancyGraph: React.FC<FlowComponentsProps> = ({
  nodes: initialNodes,
  edges: initialEdges,
  isOpen
}: any) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);

  useEffect(() => {
    const layoutedElements = getLayoutedElements(initialNodes, initialEdges);
    setNodes(layoutedElements.nodes);
    setEdges(layoutedElements.edges);
  }, [initialNodes, initialEdges]);

  useEffect(() => {
    if (reactFlowInstance.current) {
      reactFlowInstance.current.fitView();
    }
  }, [nodes, edges]);

  const onConnect = (params: Connection) => {
    setEdges(eds => addEdge(params, eds));
  };

  return (
    <div
      style={{
        height: '50vh',
        background: 'white',
        width: '100%'
      }}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        onInit={instance => {
          reactFlowInstance.current = instance;
          instance.fitView();
        }}
        onNodesChange={onNodesChange}
        fitView
        proOptions={{ hideAttribution: true }}
        nodesDraggable={false}
        nodesConnectable={false}
        style={{ color: 'red' }}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </div>
  );
};

export default DependancyGraph;
