/* eslint-disable */
// Dependency imports
import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';

// Local imports
import { AppLoading, Card } from 'components';
import { useConfig } from 'hooks/config';
import NotFound from 'pages/errors/notFound.tsx';
import { ConfigSteps } from 'types';
import AnalysisConfiguration from '../AnalysisConfiguration';
import ActivityAnnotationPage from '../activityAnnotation';
import { CameraVideoUpload } from '../steps/CameraVideoUpload';

interface configType {
  setAllowAdd?: any;
  handleButtonClick?: any;
  isAutoSave?: boolean;
}

const ConfigurationCreatePage: React.FC<configType> = ({
  setAllowAdd,
  handleButtonClick,
  isAutoSave
}) => {
  const { id, camera, step, isLoading, isError, error, isSuccess, changeStep } =
    useConfig(true);

  useEffect(() => {
    setAllowAdd(step);
  }, [step]);

  return (
    <>
      {error && error.response?.status === 404 && <NotFound />}
      {error && error.response?.status !== 404 && (
        <Card>
          <Typography variant="body1" color="error">
            Error: {error.message}
          </Typography>
        </Card>
      )}
      {isLoading && (
        <Card sx={{ height: '650px' }}>
          <AppLoading />
        </Card>
      )}
      {isSuccess && (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {isLoading ? (
              <Card sx={{ height: '650px' }}>
                <AppLoading />
              </Card>
            ) : (
              <>
                {step === ConfigSteps.GENERAL_CAMERA_AND_VIDEO && (
                  <CameraVideoUpload
                    handleButtonClick={handleButtonClick}
                    key={id} // Add key prop
                    onContinue={() =>
                      changeStep(ConfigSteps.ACTIVITY_ANNOTATION)
                    }
                  />
                )}

                {step === ConfigSteps.ACTIVITY_ANNOTATION && (
                  <ActivityAnnotationPage
                    isAutoSave={isAutoSave || false}
                    onNext={() =>
                      changeStep(ConfigSteps.ANALYSIS_CONFIGURATION)
                    }
                    onBack={() =>
                      changeStep(ConfigSteps.GENERAL_CAMERA_AND_VIDEO)
                    }
                  />
                )}

                {step === ConfigSteps.ANALYSIS_CONFIGURATION && (
                  <AnalysisConfiguration
                    onNext={() => {
                      changeStep(ConfigSteps.DASHBOARD_CONFIGURATION);
                    }}
                    onChange={() => {
                      changeStep(ConfigSteps.ROI);
                    }}
                    changeStep={changeStep}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ConfigurationCreatePage;
