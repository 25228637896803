/* eslint-disable  */
import { parse } from 'querystring';

import axios from 'axios';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import ErrorBoundary from 'components/ErrorBoundry';
import config from 'config/env';
import { ConfirmServiceProvider } from 'plugins/confirm';
import { SnackServiceProvider, SnackbarOptions } from 'plugins/snack';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store';
import { AppThemeProvider } from 'theme';
import './App.css';

import AppRouter from './routes/Routes';

axios.defaults.baseURL = config.API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = '*';
//   'GET,PUT,POST,DELETE,PATCH,OPTIONS';
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Access-Control-Allow-Headers'] = '*';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: error => {
        console.error('error', error);
      }
    }
  }
});

/**
 * Options for the snack bar.
 */
const snackOptions: SnackbarOptions = {
  snackbarProps: {
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    autoHideDuration: 3000
  }
};

/**
 * The main component of the application.
 * It wraps the entire application with error boundaries, theme provider, query client provider,
 * snack service provider, and confirm service provider.
 */
const App: React.FC = () => {
  return (
    <ErrorBoundary name="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <QueryParamProvider
              adapter={ReactRouter6Adapter}
              options={{
                searchStringToObject: (search: string) =>
                  parse(search.replace('?', '')),
                includeAllParams: true
              }}
            >
              <AppThemeProvider>
                <QueryClientProvider client={queryClient}>
                  <SnackServiceProvider defaultOptions={snackOptions}>
                    <ConfirmServiceProvider>
                      <AppRouter />
                    </ConfirmServiceProvider>
                  </SnackServiceProvider>
                </QueryClientProvider>
              </AppThemeProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
