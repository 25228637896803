import {
  useGetAllActivities,
  useGetConfiguration,
  useLabelAssistInterrupt,
  useSyncActivity
} from 'api/sdk';
import { useConfirm } from 'plugins/confirm';
import { Snack } from 'plugins/snack/Snack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SyncActivityRequest } from 'schemas';
import { RootState } from 'store';
import {
  addActivity,
  clearActivities,
  createActivity,
  deleteActivity,
  deleteAllClips,
  deleteSelectedClips,
  mergeActivities,
  moveImages,
  updateActivityName
} from 'store/config/activities';
import {
  deleteAllClipItems,
  selectCurrentCamera,
  setCurrentCameraId
} from 'store/config/configSlice';
import { selectCameras } from 'store/config/configSlice';

import { PipelineStatus } from 'types';

export const useActivityConfiguration = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const currentCamera = useSelector(selectCurrentCamera);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirm();
  const { mutateAsync: updateActivity, isLoading: isSyncLoading } =
    useSyncActivity();
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

  const [selectedDestActivities, setSelectedDestActivities] = useState<
    string | null
  >(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [moveModelOpen, setMoveModelOpen] = useState(false);
  const cameras = useSelector(selectCameras);
  const [updateList, setUpdateList] = useState<boolean>(false);
  const [createModelOpen, setCreateModelOpen] = useState<{
    open: boolean;
    data?: {
      name: string;
    };
  }>({
    open: false
  });

  const currentCameraId = useSelector(
    (state: RootState) => state.configV2.currentCameraId
  );

  const { refetch: list, isLoading: isLoadingListConfig } = useGetConfiguration(
    {
      config_id: id || ''
    },
    {
      query: {
        enabled: !!id
      }
    }
  );

  const setCurrentCamera = (cameraId: string) => {
    dispatch(setCurrentCameraId(cameraId));
  };

  const { refetch, isLoading, error } = useGetAllActivities(id || '', {
    pipeline_satus: true || null
  });

  const apiLoading = isLoading;
  const analyticsList = useSelector(
    (state: RootState) => state.configV2.analytics
  );

  const activities = useSelector((state: RootState) => state.activities.data);
  const activity = activities || {};

  const pipelineStatuses = useMemo(() => {
    const status: Array<{
      id: string;
      title: string;
      status: PipelineStatus;
      progress: number;
    }> = [];

    return status;
  }, [cameras, activities]);

  /**
   * Update the current camera
   */
  const handleCameraChange = (id: string) => {
    dispatch(setCurrentCameraId(id));
    setSelectedActivities([]);
  };

  useEffect(() => {
    if (id) {
      refetch().then(response => {
        const responseData = response.data?.data;
        if (responseData && typeof responseData === 'object') {
          const dataArray = [responseData];

          dispatch(addActivity(dataArray));
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (updateList) {
      refetch().then(response => {
        const responseData = response.data?.data;
        if (responseData && typeof responseData === 'object') {
          const dataArray = [responseData];

          dispatch(addActivity(dataArray));
        }
      });
    }
  }, [updateList]);

  useEffect(() => {
    if (updateList) {
      dispatch(
        deleteAllClipItems({
          cameraId: currentCamera?.id?.toString() ?? ''
        })
      );
    }
  }, [updateList]);

  // clear previous activities on unmount
  useEffect(() => {
    return () => {
      dispatch(clearActivities());
    };
  }, []);

  // refetchPipelineStatus().then(response => {
  //   dispatch(updatePipelineStatus(response.data?.data || []));
  // });

  const handleUpdateActivityName = (id: string, name: string) => {
    // dispatch(
    //   updateActivityName({
    //     cameraId: currentCameraId || '',
    //     ActivityId: id,
    //     name
    //   })
    // );
  };

  const handleMergeActivities = (new_name: string) => {
    const dest = selectedActivities[0];
    const src = selectedActivities.slice(1);
    // Merge objects
    dispatch(
      mergeActivities({
        cameraId: currentCameraId || '',
        srcId: src,
        destId: dest,
        name: new_name
      })
    );
    setSelectedActivities([]);
  };

  const handleMoveClips = (
    objectId: string,
    selectedDestActivities: string | null,
    clipsId: string[]
  ) => {
    const dest = selectedDestActivities;

    // move objects
    dispatch(
      moveImages({
        cameraId: currentCameraId || '',
        srcActivity: objectId,
        destActivity: dest,
        clips: clipsId
      })
    );
    setSelectedActivities([]);
    setMoveModelOpen(false);
  };

  const handleDeleteAllClips = (activityId: string) => {
    currentCameraId &&
      confirm({
        title: 'Delete all images',
        content: 'Are you sure you want to delete all images?'
      }).then(() => {
        dispatch(
          deleteAllClips({
            cameraId: currentCameraId,
            activityId
          })
        );
        setSelectedItems([]);
      });
  };

  const handleDeleteClip = (activityId: string, clipsIds: string[]) => {
    currentCameraId &&
      confirm({
        title: 'Delete images',
        content: `Are you sure you want to delete ${clipsIds.length} images?`
      }).then(() => {
        dispatch(
          deleteSelectedClips({
            cameraId: currentCameraId,
            activityId,
            clips: clipsIds
          })
        );
        setSelectedItems([]);
      });
  };

  const handleDeleteActivity = (activityId: string) => {
    currentCameraId &&
      confirm({
        title: 'Delete activity',
        content: `Are you sure you want to delete selected activity?`
      }).then(() => {
        dispatch(
          deleteActivity({
            activityId
          })
        );
      });
  };

  const handlerefresh = () => {
    refetch();
    // Incrementing the key to force a re-render
    setUpdateList(true);

    // Reset refreshKey to false after 3 seconds
    setTimeout(() => {
      setUpdateList(false);
    }, 3000);
  };

  const [disableInterrupt, setDisableInterrupt] = useState(false);

  const { mutateAsync: interrupt } = useLabelAssistInterrupt();

  const handleInterruptClick = (cameraId: string) => {
    setDisableInterrupt(true);
    interrupt({
      cameraId: cameraId?.toString() ?? '',
      configId: id?.toString() ?? ''
    }).then(() => {
      Snack({
        message: 'Activity updated successfully',
        severity: 'success'
      });
      setDisableInterrupt(false);
    });
  };

  const handleCreateActivity = (
    activityId: string | null,
    new_name: string,

    imageIds: string[]
  ) => {
    // Merge objects
    // dispatch(
    //   createActivity({
    //     cameraId: currentCameraId || '',
    //     activity: new_name,
    //     clips: imageIds,
    //     srcActivity: activityId
    //   })
    // );
    setSelectedActivities([]);
  };

  const syncActivity = (activityData: any): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!activity) {
        console.error('Objects array is null or undefined');
        return resolve();
      }
      // Prepare the data for the update
      const data: SyncActivityRequest[] = [];
      const mergedData = activities;
      mergedData.forEach((data, index) => {
        if (data?.id === activityData?.id) {
          mergedData[index] = activityData;
        }
      });

      mergedData.map((activity: any) => {
        data.push({
          activity: activity.activity,
          clips: activity.clips.map((clip: any) => ({
            camera_id: clip.camera_id,
            end_time: String(clip.end_time),
            id: clip.id,
            start_time: String(clip.start_time),
            thumbnail_path: clip.thumbnail_path
          })),
          configuration_id: id || '',
          id: activity.id
        });
      });

      updateActivity({
        configId: id || '',
        data: data
      })
        .then(() => {
          Snack({
            message: 'Activity updated successfully',
            severity: 'success'
          });
          resolve();
        })
        .catch(() => {
          Snack({
            message: 'Error updating activity',
            severity: 'error'
          });
          reject();
        });
    });
  };

  return {
    isLoading,
    refetch,
    setCurrentCamera,
    currentCameraId,
    cameras,
    activity,
    pipelineStatuses,
    selectedActivities,
    selectedItems,
    setSelectedItems,
    setSelectedActivities,
    handleCameraChange,
    apiLoading,
    handleDeleteActivity,
    handleUpdateActivityName,
    handleMergeActivities,
    handleDeleteAllClips,
    handleDeleteClip,

    selectedDestActivities,
    setSelectedDestActivities,
    handleMoveClips,
    moveModelOpen,
    setMoveModelOpen,
    currentCamera,
    videoContainerRef,
    handlerefresh,
    handleInterruptClick,
    disableInterrupt,
    setCreateModelOpen,
    createModelOpen,
    id,
    handleCreateActivity,
    syncActivity,
    isSyncLoading,
    isLoadingListConfig
  };
};
