import { faMaximize, faMinimize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Stack, styled } from '@mui/material';
import { Button, Modal, Thumbnail } from 'components';
import React, { useEffect, useRef, useState } from 'react';

import { Card } from 'components/Card';
import { Check } from 'components/Form';
import { Typography } from 'components/Typography/Typography';
import { convertDurationToSeconds } from 'utils';

type actionsType = 'edit' | 'delete' | 'expand';

export interface ImageItem {
  id: string;
  url: string;
  thumbnail_url: string;
}

export interface VideoClipItem {
  id: string;
  video_url: string;
  thumbnail_url: string;
  start_time: string;
  end_time: string;
}

export type itemsType = ImageItem | VideoClipItem;

export interface ObjectCategoryCardProps {
  id: string;
  title: string;
  subtitle?: string;
  contentType: 'image' | 'videoClip';
  items: itemsType[];

  actions?: actionsType[];
  enableZoom?: boolean;

  expanded?: boolean;
  checkable?: boolean;
  checkableItems?: boolean;
  checked?: boolean;
  checkedItems?: string[];
  onCheck?: (checked: boolean) => void;
  onEditAction?: () => void;
  onDeleteAction?: () => void;
  onExpandAction?: (expanded: boolean) => void;
  onCheckItem?: (checked: boolean, item: string) => void;

  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
  extraActions?: React.ReactNode;
}

const Title = styled(Typography)({
  fontWeight: 'bold !important',
  fontSize: 13
});

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: 5,
  borderRadius: 4,
  border: `1px solid ${theme.palette.primary.main}`,
  height: '30px',
  '& svg': {
    maxWidth: 13,
    maxHeight: 13
  }
}));

const ObjectCategoryCard: React.FC<ObjectCategoryCardProps> = ({
  id,
  title,
  subtitle = '',
  contentType = 'image',
  items = [],
  actions = ['edit', 'delete', 'expand'],
  expanded = false,
  checkable = false,
  checkableItems = false,
  checkedItems = [],
  checked = false,
  onCheck,
  onEditAction = () => {},
  onDeleteAction = () => {},
  onExpandAction = () => {},
  onCheckItem,
  preContent,
  postContent,
  extraActions
}) => {
  const [currentVideo, setCurrentVideo] = React.useState<VideoClipItem | null>(
    null
  );

  const videoRef = useRef<HTMLVideoElement>(null);
  const [startTimeInSeconds, setStartTimeInSeconds] = useState(0);
  const [endTimeInSeconds, setEndTimeInSeconds] = useState(0);
  // useEffect(() => {
  //   if (currentVideo && videoRef.current) {
  //     const videoElement = videoRef.current;
  //     videoElement.currentTime = currentVideo.start_time;
  //     videoElement.play();
  //   }
  // }, [videoRef.current]);

  // useEffect(() => {
  //   if (currentVideo && videoRef.current) {
  //     const video = videoRef.current;
  //     console.log('videoRef.current', videoRef.current);
  //     videoRef.current.src = currentVideo.video_url;

  //     videoRef.current.currentTime = convertDurationToSeconds(
  //       currentVideo.start_time
  //     );
  //     videoRef.current.play();

  //     return () => {
  //       videoRef.current?.pause();
  //     };
  //   }
  // }, [currentVideo]);
  const parseTimeToSeconds = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
    return hours * 3600 + minutes * 60 + seconds;
  };

  useEffect(() => {
    if (currentVideo) {
      setStartTimeInSeconds(parseTimeToSeconds(currentVideo.start_time));
      setEndTimeInSeconds(parseTimeToSeconds(currentVideo.end_time));
    }
  }, [currentVideo]);
  const handleTimeUpdate = (
    event: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    const video = event.currentTarget;
    if (
      video.currentTime < startTimeInSeconds ||
      video.currentTime > endTimeInSeconds
    ) {
      video.currentTime = startTimeInSeconds;
    }
  };
  return (
    <>
      <Card>
        <Grid container>
          {/* Start Title */}
          <Stack
            direction="row"
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Stack>
              <Stack direction="row" alignContent={'center'}>
                {checkable && (
                  <Check
                    disableRipple
                    overlap={false}
                    checked={checked}
                    onChange={() => onCheck && onCheck(!checked)}
                  />
                )}

                <Title>{title}</Title>
              </Stack>

              {subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </Stack>
            {/* End Title */}

            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {actions.includes('edit') && (
                <ActionButton disableRipple onClick={onEditAction}>
                  <EditIcon />
                </ActionButton>
              )}
              {actions.includes('delete') && (
                <ActionButton disableRipple onClick={onDeleteAction}>
                  <DeleteIcon />
                </ActionButton>
              )}
              {actions.includes('expand') && (
                <ActionButton
                  disableRipple
                  onClick={() => onExpandAction(!expanded)}
                >
                  <FontAwesomeIcon icon={expanded ? faMinimize : faMaximize} />
                </ActionButton>
              )}
              {extraActions && extraActions}
            </Stack>
          </Stack>

          {/* start extra Actions*/}
          {/* {extraActions && (
          <Grid item xs={4}>
            {extraActions}
          </Grid>
        )} */}
          {/* end extra Actions*/}

          {/* Start Precontent */}

          {preContent && (
            <Grid item xs={12}>
              {preContent}
            </Grid>
          )}
          {/* End Precontent */}

          {/* Start actions */}

          {/* End actions */}

          {/* Start Items */}
          <Grid item xs={12} mt={2}>
            <Box position={'relative'}>
              {items.map((item, index) => (
                <Thumbnail
                  key={index}
                  thumbnailUrl={item.thumbnail_url}
                  title={title}
                  checkable={checkableItems}
                  checked={checkedItems.includes(item.id)}
                  onCheck={checked =>
                    onCheckItem && onCheckItem(checked, item.id)
                  }
                  onClick={
                    contentType === 'videoClip'
                      ? () =>
                          setCurrentVideo({
                            id: item.id,
                            video_url: (item as VideoClipItem).video_url,
                            thumbnail_url: item.thumbnail_url,
                            start_time: (item as VideoClipItem).start_time,
                            end_time: (item as VideoClipItem).end_time
                          })
                      : undefined
                  }
                />
              ))}
            </Box>
          </Grid>
          {/* End Items */}

          {/* Start Postcontent */}
          {postContent && (
            <Grid item xs={12}>
              {postContent}
            </Grid>
          )}
          {/* End Postcontent */}
        </Grid>
      </Card>
      {currentVideo && (
        <Modal
          open={true}
          onClose={() => setCurrentVideo(null)}
          title={`Activity: ${title}, start-time: ${currentVideo.start_time}, end-time: ${currentVideo.end_time}`}
          size="lg"
        >
          <Grid container style={{ maxHeight: '450px', overflowY: 'auto' }}>
            <video
              id="video"
              // ref={videoRef}
              muted
              disablePictureInPicture
              controls
              autoPlay
              src={currentVideo.video_url}
              style={{ width: '100%', height: '100%' }}
              onTimeUpdate={handleTimeUpdate}
            />
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default ObjectCategoryCard;
