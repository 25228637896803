import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { VideoStatus, VideoInferenceState } from './schema';

import {
  addInferenceVideos,
  inferenceRequestVideoUploadUrl,
  listInferenceVideos
} from 'api/sdk';
import { InferenceMode, VideoMode } from 'schemas/index';

const initialState: VideoInferenceState = {
  videos: [],
  loading: false,
  currentConfigId: 'Select Configuration',
  error: null
};

// Create a thunk
export const fetchVideos = createAsyncThunk(
  'inference/fetchVideos',
  async (args: { config_id: string; mode: InferenceMode }, { dispatch }) => {
    const { config_id, mode } = args;
    const response = await listInferenceVideos(config_id, {
      mode: mode
    });
    return response.data;
  }
);

const addInferenceVideoThunk = createAsyncThunk(
  'inference/addInferenceVideo',
  async (
    payload: {
      siteId: string;
      video_id: string;
      path: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as { inference: VideoInferenceState };

    const video = state.inference.videos.find(v => v.id === payload.video_id);

    if (!video) {
      return;
    }

    const data = await addInferenceVideos(payload.siteId, {
      configuration_id: video.configuration_id,
      duration: video.duration || 0,
      id: video.id,
      model_id: null,
      name: video.name,
      site_id: payload.siteId,
      upload_path: payload.path
    });
    return {
      data,
      video_id: payload.video_id
    };
  }
);

const uploadVideo = createAsyncThunk(
  'inference/uploadVideo',
  async (
    payload: {
      config_id: string;
      video_id: string;
      file: File;
      showDuplicateError: any;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as { inference: VideoInferenceState };

    try {
      const {
        data: { file_name, file_type, path, signed_url, url }
      } = await inferenceRequestVideoUploadUrl({
        config_id: payload.config_id,
        filename: payload.file.name
      });

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signed_url, true);
      xhr.setRequestHeader('Content-Type', file_type);

      xhr.upload.onprogress = e => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        thunkApi.dispatch(
          updateProgress({ id: payload.video_id, progress: percentCompleted })
        );
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          // call another api to update the video status
          thunkApi.dispatch(
            addInferenceVideoThunk({
              siteId: payload.config_id,
              video_id: payload.video_id,
              path
            })
          );
        } else {
          thunkApi.dispatch(
            updateVideoStatus({
              id: payload.video_id,
              status: VideoStatus.FAILED
            })
          );
        }
      };

      xhr.send(payload.file);
    } catch (error: any) {
      console.log('Error inside', error);
      payload.showDuplicateError(error?.response?.data?.detail);
      thunkApi.dispatch(
        updateVideoStatus({
          id: payload.video_id,
          status: VideoStatus.FAILED
        })
      );
      return;
    }
  }
);

export const uploadFiles = createAsyncThunk(
  'inference/uploadFiles',
  async (
    payload: {
      configuration_id: string;
      files: FileList;
      model_id: string;
      site_id: string;
      showDuplicateError: any;
    },
    { dispatch }
  ) => {
    const files = Array.from(payload.files);

    files.forEach(file => {
      const video_id = uuidv4();
      dispatch(
        addVideo({
          id: video_id,
          configuration_id: payload.configuration_id,
          name: file.name,
          url: '',
          file
        })
      );

      dispatch(
        uploadVideo({
          config_id: payload.configuration_id,
          video_id,
          file,
          showDuplicateError: payload.showDuplicateError
        })
      );
    });
    return;
  }
);

// Create a slice
export const inferenceSlice = createSlice({
  name: 'inference',
  initialState,
  reducers: {
    // Add reducers here
    addVideo: (
      state,
      action: PayloadAction<{
        id: string;
        configuration_id: string;
        name: string;
        url: string;
        file: File;
        fail_reason?: any;
      }>
    ) => {
      state.videos.push({
        name: action.payload.name,
        status: VideoStatus.UPLOADING,
        configuration_id: action.payload.configuration_id,
        id: action.payload.id,
        fail_reason: '',
        created_at: '',
        video_url: '',
        duration: 0,
        inference_start_time: '',
        inference_end_time: '',
        output_video: '',
        output_video_url: '',
        upload_path: '',
        file: action.payload.file,

        model: null,
        camera: null,
        mode: VideoMode.OFFLINE
      });
    },

    updateProgress: (
      state,
      action: PayloadAction<{ id: string; progress: number }>
    ) => {
      const video = state.videos.find(v => v.id === action.payload.id);
      if (video) {
        video.upload_progress = action.payload.progress;
      }
    },
    updateVideoStatus: (
      state,
      action: PayloadAction<{ id: string; status: VideoStatus }>
    ) => {
      const video = state.videos.find(v => v.id === action.payload.id);
      if (video) {
        video.status = action.payload.status;
      }
    },
    consumeWebsocketMessage: (state, action: PayloadAction<any>) => {
      const { data, pipeline_type, type } = action.payload;
      const { video_id, site_id } = data;
      const video = state.videos.find(v => v.id === video_id);

      if (!video) {
        return;
      }

      switch (type) {
        case 'PROCESSING':
          video.status = VideoStatus.PROCESSING;
          video.process_progress = data.progress || 0;
          break;
        case 'QUEUED':
          video.status = VideoStatus.QUEUED;
          break;

        case 'PROGRESS':
          video.status = VideoStatus.PROCESSING;
          video.process_progress = data.progress || 0;
          break;
        case 'COMPLETED':
          video.status = VideoStatus.COMPLETED;
          video.output_video = data.output_video;
          video.output_video_url = data.output_video_url;
          break;
        case 'FAILED':
          video.status = VideoStatus.FAILED;
          video.fail_reason = data.fail_reason;
          break;
        default:
          break;
      }
    },
    updateConfigId: (state, action) => {
      state.currentConfigId = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchVideos.pending, state => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(fetchVideos.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.videos = action.payload.map(video => ({
        ...video,
        output_url: video.output_video_url || '',

        status: VideoStatus[video.status as keyof typeof VideoStatus],
        fail_reason: video.fail_reason
      }));
    });

    builder.addCase(fetchVideos.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'An error occurred';
      state.videos = [];
    });

    builder.addCase(addInferenceVideoThunk.fulfilled, (state, action) => {
      const data = action.payload?.data.data;

      if (!data) {
        return;
      }

      const video = state.videos.find(v => v.id === data.id);

      if (video) {
        video.status = VideoStatus.UPLOADED;
        video.upload_path = data.upload_path;
        video.created_at = data.created_at;
        video.duration = data.duration;
        video.inference_start_time = data.inference_start_time;
        video.inference_end_time = data.inference_end_time;
        video.output_video = data.output_video;
        video.output_video_url = data.output_video_url;
        video.video_url = data.video_url;
      }
    });
    builder.addCase(addInferenceVideoThunk.rejected, (state, action) => {
      const video = state.videos.find(v => v.id === action.meta.arg.video_id);
      if (video) {
        video.status = VideoStatus.FAILED;
      }
    });
  }
});

export const {
  addVideo,
  updateProgress,
  updateVideoStatus,
  consumeWebsocketMessage,
  updateConfigId
} = inferenceSlice.actions;

export const selectInferenceState = (state: {
  inference: VideoInferenceState;
}) => state.inference;

export default inferenceSlice.reducer;
