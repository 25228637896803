import { Field, FieldProps } from 'formik';
import React from 'react';

import { OtpInput, IOtpInputProps } from '../Inputs/OtpInput';

export interface IOtpFieldProps
  extends Omit<IOtpInputProps, 'value' | 'onChange'> {
  onChange?: (otp: string) => void;
}

export const OtpField: React.FC<IOtpFieldProps> = ({
  name,
  onChange,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <OtpInput
          name={name}
          {...props}
          value={field.value}
          onChange={value => {
            form.setFieldValue(name, value);
            if (onChange) {
              onChange(value);
            }
          }}
          error={form.errors[name]?.toString()}
        />
      )}
    </Field>
  );
};
