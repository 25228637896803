import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSnack } from 'plugins/snack';
import routes from 'routes/urls';

import RegisterEmailForm from './registerEmailForm';
import ValidateOrganizationForm from './validateOrganizationForm';

const RegisterPage: React.FC = () => {
  const snack = useSnack();
  const navigate = useNavigate();

  const [pageState, setPageState] = useState<'validate' | 'register'>(
    'validate'
  );

  //   eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, setToken] = useState<string>('');

  const handleValidate = (val: string) => {
    setToken(val);
    setPageState('register');
  };

  return (
    <>
      {pageState === 'validate' && (
        <ValidateOrganizationForm onValidate={handleValidate} />
      )}

      {pageState === 'register' && (
        <RegisterEmailForm
          token={token}
          onSuccess={() => {
            snack({
              message:
                'Verfication email has been sent. Please check your email.',
              severity: 'success',
              snackbarProps: {
                autoHideDuration: 200
              }
            }).then(() => {
              navigate(routes.LOGIN.generate());
            });
          }}
        />
      )}
    </>
  );
};

export default RegisterPage;
