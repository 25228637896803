/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WFM-Backend
 * OpenAPI spec version: 1
 */

export type Analytics = typeof Analytics[keyof typeof Analytics];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Analytics = {
  employee_productivity: 'employee_productivity',
  product_throughput: 'product_throughput',
  workflow_monitoring: 'workflow_monitoring',
  safety_gear_monitoring: 'safety_gear_monitoring',
  inventor_management: 'inventor_management',
  security: 'security',
  process_control: 'process_control',
  quality_control: 'quality_control',
} as const;
