// Dependency imports
import {
  DataGrid,
  getDefaultGridFilterModel,
  gridClasses,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel
} from '@mui/x-data-grid';

// Styles import
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  setRowsPerPage,
  setPageNumber,
  setSortModel
} from 'store/pagination/paginationSlice';
import { useState } from 'react';

const ODD_OPACITY = 0.2;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-sortIcon': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-filterIcon': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: 'white'
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          )
        }
      }
    }
  },
  [`& .${gridClasses.row}.odd`]: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}));

export type RecordValue =
  | string
  | number
  | undefined
  | object
  | React.ReactElement;

export type Record = {
  id: string; // Ensure that each record has a unique id
  [key: string]: RecordValue | RecordValue[];
};

interface DataGridProps {
  rows: Array<Record>;
  columns: any;
  loading?: boolean;
  checkboxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
}

export const DataGridComponent = ({
  rows,
  columns,
  loading,
  checkboxSelection,
  disableRowSelectionOnClick
}: DataGridProps) => {
  const dispatch = useDispatch();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: []
  });

  const [sortModel, setSortModelState] = useState<GridSortModel>(
    useSelector((state: RootState) => state.pagination.sortModel)
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: useSelector((state: RootState) => state.pagination.rowsPerPage),
    page: useSelector((state: RootState) => state.pagination.pagenumber)
  });

  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModelState(newSortModel);
    dispatch(setSortModel(newSortModel));
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPageNumber(newPaginationModel.page));
    dispatch(setRowsPerPage(newPaginationModel.pageSize));
  };

  return (
    <Box
      sx={{
        height: 620,
        width: '100%',
        border: 'none',
        borderRadius: '6px',
        '& .super-app-theme--header': {
          backgroundColor: '#102633',
          border: 'none',
          color: 'white',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[10]}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton'
          }
        }}
      />
    </Box>
  );
};
