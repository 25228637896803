/* eslint-disable */

// Dependencies imports
import {
  Box,
  Stack,
  DialogContent,
  CircularProgressProps,
  CircularProgress,
  Typography,
  Paper
} from '@mui/material';
import { useVideoUpload } from 'hooks/config';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { IconButton, Slider, Link } from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

// Local imports
import { useSnack } from 'plugins/snack';
import { Videos } from 'store/inference/schema';
import useVideo from 'hooks/video';
import { secondsToHms } from 'utils';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ITableHeadColumn, Table, Button, Card, Modal } from 'components';

// Style imports
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { DataGridComponent } from 'components/DataGrid';
import { useDispatch } from 'react-redux';
import { setPreventNavigation } from 'store/preventNavigation/preventNavigationSlice';

interface CameraVideoUploadProps {
  onContinue: () => void;
  handleButtonClick?: any;
}

const ActionToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

function CircularProgressWithIcon(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} color="inherit" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <FileUploadIcon />
      </Box>
    </Box>
  );
}

const VideoStatus: React.FC<{
  status: string;
  uploadProgress?: number;
  fail_reason?: string;
}> = ({ status, uploadProgress, fail_reason }) => {
  switch (status) {
    case 'uploading':
      return (
        <Stack direction={'column'}>
          <div style={{ marginLeft: '-10px' }}>
            <ActionToolTip title={'Uploading...'}>
              <IconButton disableRipple style={{ color: 'black' }}>
                {/* <CircularWithValueLabel
                  variant={
                    uploadProgress === 0 ? 'indeterminate' : 'determinate'
                  }
                  value={uploadProgress || 0}
                  size={30}
                /> */}
                <CircularProgressWithIcon value={uploadProgress || 0} />
              </IconButton>
            </ActionToolTip>
          </div>
        </Stack>
      );
    case 'uploaded':
      return (
        <Stack direction={'column'}>
          <div>
            <ActionToolTip title={'Uploaded'}>
              <IconButton disableRipple style={{ color: 'blue' }}>
                <CloudDoneIcon />
              </IconButton>
            </ActionToolTip>
          </div>
        </Stack>
      );
    case 'failed':
      return (
        <Stack direction={'column'}>
          <div>
            <ActionToolTip title={fail_reason}>
              <IconButton disableRipple style={{ color: 'red' }}>
                <CancelRoundedIcon />
              </IconButton>
            </ActionToolTip>
          </div>
        </Stack>
      );
    default:
      return null;
  }
};

const VideoAction: React.FC<any> = ({ onDelete, onPlay, status, video_id }) => {
  return (
    <Stack direction={'column'}>
      <div>
        <ActionToolTip title={'Delete'}>
          <IconButton onClick={onDelete} disableRipple>
            <DeleteIcon />
          </IconButton>
        </ActionToolTip>
      </div>
    </Stack>
  );
};

function checkAllStatus(videos: any) {
  let status = false;

  function checkLogic(data: string) {
    if (data === 'uploading' || data === 'failed') {
      return true; // return true if any of these conditions match
    }
    return false; // otherwise return false
  }

  videos?.forEach((data: Videos) => {
    if (checkLogic(data?.status)) {
      status = true; // set status to true if any checkLogic call returns true
    }
  });

  return status;
}

export const CameraVideoUpload: React.FC<CameraVideoUploadProps> = ({
  onContinue,
  handleButtonClick
}) => {
  const {
    cameras,
    isLoading,
    configId,
    isAddingCameras,
    handleUpload,
    handleDelete,
    handleContinue,
    videoError,
    setError,
    refresh
  } = useVideoUpload(onContinue);
  const [selected, setSelected] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isPause, setPause] = useState<boolean>(true);
  const [isPlay, setPlay] = useState<boolean>(false);
  const [videoName, setVideoName] = useState<string | null>('');
  const [getVideoCurrentTime, setVicdeoCurrentTime] = useState<number>(0);
  const { videoRef, seek, reset, loading: videoLoading } = useVideo();
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastTime, setLastTime] = useState(0);
  const Snack = useSnack();
  const dispatch = useDispatch();
  const [isVideoUploading, setIsVideoUploading] = useState<boolean>(false);

  useEffect(() => {
    if (cameras && cameras.length > 0) {
      setRows(cameras);
      let isUploading = false;

      cameras.every((item: any) => {
        if (item.upload_progress < 100) {
          isUploading = true;
          dispatch(setPreventNavigation(true));
          return false;
        } else {
          return true;
        }
      });
      setIsVideoUploading(isUploading);
    }
  }, [cameras]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isVideoUploading) {
        const confirmationMessage =
          'A video is currently being uploaded. If you leave or refresh the page, the upload will be lost. Are you sure you want to proceed?';
        event.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage; // Required for some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (isVideoUploading) {
        dispatch(setPreventNavigation(false));
      }
    };
  }, [isVideoUploading]);

  useEffect(() => {
    const checkBuffering = () => {
      const video = videoRef.current;
      if (video) {
        if (
          videoDuration !== 0 &&
          (video.currentTime === videoDuration ||
            video.currentTime > videoDuration)
        ) {
          setPlay(true);
          setPause(false);
        }

        if (video.currentTime > videoDuration) {
          setLoading(false);
        } else if (
          video.currentTime === lastTime &&
          !isPlay &&
          video.currentTime !== videoDuration
        ) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        setLastTime(video.currentTime);
      }
    };

    const interval = setInterval(checkBuffering, 500);

    return () => {
      clearInterval(interval);
    };
  }, [lastTime]);

  const handleSliderChange = (event: Event, value: number | number[]) => {
    const newTime = Array.isArray(value) ? value[0] : value;
    if (newTime === videoDuration) {
      setLoading(false);
    }
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setVicdeoCurrentTime(newTime);
    }
  };

  function videoCurrentTime(event: SyntheticEvent<HTMLVideoElement, Event>) {
    setVicdeoCurrentTime(event?.currentTarget?.currentTime);
  }

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      setVideoDuration(videoRef.current.duration);
    }
  };

  const handleClickOpen = (url: any) => {
    setVideoUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setPause(true);
    setPlay(false);
    setOpen(false);
    setVideoUrl(null);
  };

  const handlePlay = () => {
    setPlay(false);
    setPause(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    setPlay(true);
    setPause(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Video Name',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const record = params.row;
        return (
          record.status && (
            <h2
              style={{
                textDecoration:
                  record.status !== undefined &&
                  record.status !== 'uploading' &&
                  record.status !== 'failed' &&
                  record.url !== null
                    ? 'underline'
                    : 'none',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: 400,
                margin: 0,
                padding: 0
              }}
              onClick={() => {
                if (
                  record.status !== undefined &&
                  record.status !== 'uploading' &&
                  record.status !== 'failed' &&
                  record.url !== null
                ) {
                  refresh();
                  if (params.value) {
                    setVideoName(params.value.toString());
                  }
                  handleClickOpen(record.url);
                }
              }}
            >
              {params.value?.toString()}
            </h2>
          )
        );
      }
    },
    {
      field: 'duration',
      headerName: 'Duration',
      headerClassName: 'super-app-theme--header',
      flex: 0.6
    },
    {
      field: 'date',
      headerName: 'Uploaded At',
      headerClassName: 'super-app-theme--header',
      flex: 0.6,
      renderCell: (params: GridCellParams) => (
        <>{params?.value ? params.value : '-'}</>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const record = params.row;
        return (
          <VideoStatus
            status={params.value as string}
            uploadProgress={record.progress || 0}
            fail_reason={record.fail_reason as string}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
      disableReorder: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const record = params.row;
        return (
          <VideoAction
            status={record.status}
            onDelete={() => {
              handleDelete(record.id, refresh);
            }}
            video_id={params.row.id || ''}
          />
        );
      }
    }
  ];

  function checkSaveStatus() {
    Snack({
      message: 'Minimum 3 videos required to proceed.',
      severity: 'warning'
    });
  }

  return (
    <div className="hello">
      <Card>
        {cameras?.length ? (
          <DataGridComponent
            columns={columns}
            disableRowSelectionOnClick={true}
            rows={
              rows?.map((data: any) => {
                return {
                  id: data.id,
                  name: data?.name || '',
                  duration: secondsToHms(data?.video_metadata?.duration || 0),
                  date: moment
                    .utc(data?.created_at)
                    .local()
                    .format('MMM DD, YY, h:mm a'),
                  status: data?.status,
                  progress: data?.upload_progress || 0,
                  path: data?.video_path || '',
                  url: data?.video_url || ''
                };
              }) || []
            }
            loading={isLoading}
          />
        ) : (
          <Paper>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '660px'
              }}
            >
              <div
                style={{
                  borderStyle: 'dashed'
                }}
              >
                <IconButton
                  style={{
                    width: '20vw',
                    padding: '20px',
                    color: 'black'
                  }}
                  onClick={handleButtonClick}
                >
                  {' '}
                  <SystemUpdateAltIcon />
                  <div style={{ marginLeft: '20px' }}>Import Videos</div>
                </IconButton>
              </div>
            </div>
          </Paper>
        )}
      </Card>
      <div className="here one"></div>
      {cameras?.length ? (
        <Box marginTop={'20px'} sx={{ justifyContent: 'center' }}>
          <Stack direction="row" spacing={2} justifyContent={'center'}>
            <Button
              variant="contained"
              color="primary"
              version="dark"
              onClick={() =>
                cameras?.length < 3 ? checkSaveStatus() : handleContinue()
              }
            >
              Save And Proceed
            </Button>
          </Stack>
        </Box>
      ) : null}
      <div className="here"></div>
      <Modal open={open} onClose={handleClose} size="xl">
        <DialogContent>
          {videoUrl && (
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
              >
                <Typography>{videoName}</Typography>
              </Stack>
              <Stack sx={{ position: 'relative' }}>
                <video
                  ref={videoRef}
                  autoPlay
                  style={{ maxWidth: '100%', maxHeight: '70vh' }}
                  controls={false}
                  src={videoUrl}
                  onTimeUpdate={videoCurrentTime}
                  onLoadedMetadata={handleLoadedMetadata}
                />
                {loading && (
                  <CircularProgress
                    size={60}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  />
                )}
              </Stack>

              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={2}
                marginTop={3}
              >
                {isPlay && (
                  <IconButton onClick={handlePlay}>
                    <PlayArrowIcon />
                  </IconButton>
                )}
                {isPause && (
                  <IconButton onClick={handlePause}>
                    <PauseIcon />
                  </IconButton>
                )}
                <Slider
                  value={getVideoCurrentTime}
                  max={videoDuration}
                  onChange={handleSliderChange}
                  aria-labelledby="continuous-slider"
                  style={{ width: '100%' }}
                />
              </Stack>
            </Stack>
          )}
        </DialogContent>
      </Modal>
    </div>
  );
};
