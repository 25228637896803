/* eslint-disable  */
import * as React from 'react';
import CircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CircularProgressWithLabelProps
  extends Omit<CircularProgressProps, 'value'> {
  value: number | React.ReactNode;
  size?: number;
  variantType?: 'indeterminate' | 'determinate';
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  size,
  variantType = 'determinate',
  ...props
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant={variantType}
        value={typeof value === 'number' ? value : 100}
        {...props}
        size={size || 40}
        disableShrink
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color={'text.secondary'}
          fontSize={'11.5px'}
        >
          {typeof value == 'number' ? `${Math.round(value)}%` : value}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
