/* eslint-disable */
import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Box, Stack } from '@mui/material';
import { TextField } from 'components/Form';
import { Button, Typography } from 'components';
import { useForgotPassword } from 'api/sdk';
import { convertServerErrors } from 'utils/form';

interface IResetPasswordFormValues {
  email: string;
}

interface IResetPasswordFormProps {
  onSubmit: (email: string) => void;
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({ onSubmit }) => {
  const { mutateAsync, error, isError, isLoading } = useForgotPassword();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
  });

  const resetPasswordFormInitialValue: IResetPasswordFormValues = {
    email: ''
  };

  const handleSubmit = async (
    values: IResetPasswordFormValues,
    formik: FormikHelpers<IResetPasswordFormValues>
  ) => {
    await mutateAsync({
      data: {
        email: values.email
      }
    })
      .then(() => {
        onSubmit(values.email);
      })
      .catch(err => {
        if (err.response.status === 422) {
          formik.setErrors(convertServerErrors(err.response.data.detail));
        }
        if (err.response.status === 404) {
          formik.setErrors({ email: 'User not found' });
        }
      });
  };

  return (
    <Formik<IResetPasswordFormValues>
      initialValues={resetPasswordFormInitialValue}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <br />
          <Stack spacing={3}>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your email"
              boootstrap
              light
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Send Verification Code'}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
