/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WFM-Backend
 * OpenAPI spec version: 1
 */
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  APIResponseVideoSchema,
  ActivityResponseSchema,
  AddCameraRequestSchema,
  AddCamerasParams,
  AddInferenceVideoRequestSchema,
  AddToActivityAssist200,
  AddToActivityAssistParams,
  AddToActivityInferenceParams,
  AddToInferenceRequest,
  AddToLabelAssist200,
  AddToTrainingAssist200,
  AddToTrainingAssistParams,
  AdminRegisterRequestSchema,
  AvailableSitesResponseSchema,
  BodyUpdateSequenceModel,
  BodyUploadVideo,
  BodyVideoUpload,
  Camera,
  CameraResponseSchema,
  ChangePasswordRequestSchema,
  ChangeSiteRequestSchema,
  ConfigSchema,
  ConfigurationCameraSchema,
  CreateAdminSiteResponseSchema,
  CreateConfigurationRequestSchema,
  DashBoardConfigSchemaInput,
  DashBoardConfigSchemaOutput,
  DeleteConfigurationParams,
  DeleteConfigurationsCameraParams,
  DeleteSequenceModel200,
  EmployeeResponse,
  EmployeeSchema,
  FileUploadResponseSchema,
  ForgotPasswordRequestSchema,
  GetAllActivitiesParams,
  GetAllObjectsParams,
  GetConfigurationParams,
  GetOrganisationSitesParams,
  GetPipelineStatusParams,
  GetReportConfigurationsParams,
  GetVideoParams,
  GetVideoQueueParams,
  HTTPValidationError,
  InferedVideosGraphData200,
  InferedVideosGraphDataParams,
  InferenceModelSchema,
  InventoryResponse,
  InventorySchema,
  LabelAsisitRequest,
  LabelAssistInterrupt200,
  ListInferenceModelsParams,
  ListInferenceVideosParams,
  LoginResponseSchema,
  MemberDeleteRequestSchema,
  MemberRegisterRequestSchema,
  ModulesInferenceSchemaGeturlRequest,
  ModulesTrainingActivitiesSchemasActivitySchema,
  ModulesTrainingSchemasGeturlRequest,
  ObjResponseSchema,
  ObjSchema,
  OrganisationValidateRequestSchema,
  OrganisationValidateResponseSchema,
  PipelineStatusResponse,
  ProductResponse,
  ProductSchema,
  ProfileSchema,
  ReportResponse,
  ReportconfigResponse,
  ReportconfigurationInput,
  ReportconfigurationOutput,
  ResetPasswordRequestSchema,
  SafetyGearResponse,
  SafetyGearSchema,
  SecurityResponse,
  SecuritySchema,
  StartProcessingRequestSchema,
  SyncActivityRequest,
  SyncObjectsRequest,
  TrainModelRequest,
  TrainSequenceModel200,
  UpdateCameraRequestSchema,
  UpdateConfigurationParams,
  UpdateSequenceModel200,
  UpdateVideoPath200,
  UpdateVideoPathParams,
  UpdateVideoRequestSchema,
  UserLoginRequestSchema,
  UserProfileSchema,
  VerifyAdminRegisterRequestSchema,
  VideoReport200,
  VideoUploadParams,
  VideoUploadResponseSchema,
  VideosSchema,
  WorkflowSchema
} from '../schemas'




/**
 * Login Endpoint
 * @summary Login
 */
export const login = (
    userLoginRequestSchema: UserLoginRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LoginResponseSchema>> => {
    
    return axios.post(
      `/auth/login`,
      userLoginRequestSchema,options
    );
  }



export const getLoginMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: UserLoginRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: UserLoginRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, {data: UserLoginRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  login(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>
    export type LoginMutationBody = UserLoginRequestSchema
    export type LoginMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Login
 */
export const useLogin = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: UserLoginRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof login>>,
        TError,
        {data: UserLoginRequestSchema},
        TContext
      > => {

      const mutationOptions = getLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Register Request Endpoint
 * @summary Register Request
 */
export const registerAdmin = (
    adminRegisterRequestSchema: AdminRegisterRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/auth/register-admin`,
      adminRegisterRequestSchema,options
    );
  }



export const getRegisterAdminMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerAdmin>>, TError,{data: AdminRegisterRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof registerAdmin>>, TError,{data: AdminRegisterRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerAdmin>>, {data: AdminRegisterRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  registerAdmin(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RegisterAdminMutationResult = NonNullable<Awaited<ReturnType<typeof registerAdmin>>>
    export type RegisterAdminMutationBody = AdminRegisterRequestSchema
    export type RegisterAdminMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Register Request
 */
export const useRegisterAdmin = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerAdmin>>, TError,{data: AdminRegisterRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof registerAdmin>>,
        TError,
        {data: AdminRegisterRequestSchema},
        TContext
      > => {

      const mutationOptions = getRegisterAdminMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Register Request Member Endpoint
 * @summary Register Request Member
 */
export const registerMember = (
    memberRegisterRequestSchema: MemberRegisterRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/auth/register-member`,
      memberRegisterRequestSchema,options
    );
  }



export const getRegisterMemberMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerMember>>, TError,{data: MemberRegisterRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof registerMember>>, TError,{data: MemberRegisterRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerMember>>, {data: MemberRegisterRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  registerMember(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RegisterMemberMutationResult = NonNullable<Awaited<ReturnType<typeof registerMember>>>
    export type RegisterMemberMutationBody = MemberRegisterRequestSchema
    export type RegisterMemberMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Register Request Member
 */
export const useRegisterMember = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerMember>>, TError,{data: MemberRegisterRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof registerMember>>,
        TError,
        {data: MemberRegisterRequestSchema},
        TContext
      > => {

      const mutationOptions = getRegisterMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Verify Admin Endpoint
 * @summary Verify Admin
 */
export const verifyAdminRegister = (
    verifyAdminRegisterRequestSchema: VerifyAdminRegisterRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/auth/verify-admin-register`,
      verifyAdminRegisterRequestSchema,options
    );
  }



export const getVerifyAdminRegisterMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyAdminRegister>>, TError,{data: VerifyAdminRegisterRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof verifyAdminRegister>>, TError,{data: VerifyAdminRegisterRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyAdminRegister>>, {data: VerifyAdminRegisterRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  verifyAdminRegister(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type VerifyAdminRegisterMutationResult = NonNullable<Awaited<ReturnType<typeof verifyAdminRegister>>>
    export type VerifyAdminRegisterMutationBody = VerifyAdminRegisterRequestSchema
    export type VerifyAdminRegisterMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Verify Admin
 */
export const useVerifyAdminRegister = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyAdminRegister>>, TError,{data: VerifyAdminRegisterRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof verifyAdminRegister>>,
        TError,
        {data: VerifyAdminRegisterRequestSchema},
        TContext
      > => {

      const mutationOptions = getVerifyAdminRegisterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Change Password Endpoint
 * @summary Change Password
 */
export const changePassword = (
    changePasswordRequestSchema: ChangePasswordRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/auth/change-password`,
      changePasswordRequestSchema,options
    );
  }



export const getChangePasswordMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError,{data: ChangePasswordRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError,{data: ChangePasswordRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changePassword>>, {data: ChangePasswordRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  changePassword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof changePassword>>>
    export type ChangePasswordMutationBody = ChangePasswordRequestSchema
    export type ChangePasswordMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Change Password
 */
export const useChangePassword = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError,{data: ChangePasswordRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof changePassword>>,
        TError,
        {data: ChangePasswordRequestSchema},
        TContext
      > => {

      const mutationOptions = getChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get User Profile
 * @summary Get Profile
 */
export const getProfile = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProfileSchema>> => {
    
    return axios.get(
      `/auth/profile`,options
    );
  }


export const getGetProfileQueryKey = () => {
    return [`/auth/profile`] as const;
    }

    
export const getGetProfileQueryOptions = <TData = Awaited<ReturnType<typeof getProfile>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProfileQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfile>>> = ({ signal }) => getProfile({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getProfile>>>
export type GetProfileQueryError = AxiosError<unknown>

/**
 * @summary Get Profile
 */
export const useGetProfile = <TData = Awaited<ReturnType<typeof getProfile>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Viewer Profile
 * @summary Get viewer profile
 */
export const getViewerProfile = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserProfileSchema[]>> => {
    
    return axios.get(
      `/auth/viewer_profilelist`,options
    );
  }


export const getGetViewerProfileQueryKey = () => {
    return [`/auth/viewer_profilelist`] as const;
    }

    
export const getGetViewerProfileQueryOptions = <TData = Awaited<ReturnType<typeof getViewerProfile>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getViewerProfile>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetViewerProfileQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getViewerProfile>>> = ({ signal }) => getViewerProfile({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getViewerProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetViewerProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getViewerProfile>>>
export type GetViewerProfileQueryError = AxiosError<unknown>

/**
 * @summary Get viewer profile
 */
export const useGetViewerProfile = <TData = Awaited<ReturnType<typeof getViewerProfile>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getViewerProfile>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetViewerProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Forgot Password
 * @summary Forgot Password
 */
export const forgotPassword = (
    forgotPasswordRequestSchema: ForgotPasswordRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/auth/forgot-password`,
      forgotPasswordRequestSchema,options
    );
  }



export const getForgotPasswordMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: ForgotPasswordRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: ForgotPasswordRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof forgotPassword>>, {data: ForgotPasswordRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  forgotPassword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof forgotPassword>>>
    export type ForgotPasswordMutationBody = ForgotPasswordRequestSchema
    export type ForgotPasswordMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Forgot Password
 */
export const useForgotPassword = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: ForgotPasswordRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof forgotPassword>>,
        TError,
        {data: ForgotPasswordRequestSchema},
        TContext
      > => {

      const mutationOptions = getForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Reset Password Endpoint
 * @summary Reset Password
 */
export const resetPassword = (
    resetPasswordRequestSchema: ResetPasswordRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/auth/reset-password`,
      resetPasswordRequestSchema,options
    );
  }



export const getResetPasswordMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, {data: ResetPasswordRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  resetPassword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>
    export type ResetPasswordMutationBody = ResetPasswordRequestSchema
    export type ResetPasswordMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Reset Password
 */
export const useResetPassword = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof resetPassword>>,
        TError,
        {data: ResetPasswordRequestSchema},
        TContext
      > => {

      const mutationOptions = getResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List of Available Sites
 * @summary Available Sites
 */
export const availableSites = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AvailableSitesResponseSchema[]>> => {
    
    return axios.get(
      `/auth/available_sites`,options
    );
  }


export const getAvailableSitesQueryKey = () => {
    return [`/auth/available_sites`] as const;
    }

    
export const getAvailableSitesQueryOptions = <TData = Awaited<ReturnType<typeof availableSites>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof availableSites>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAvailableSitesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof availableSites>>> = ({ signal }) => availableSites({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof availableSites>>, TError, TData> & { queryKey: QueryKey }
}

export type AvailableSitesQueryResult = NonNullable<Awaited<ReturnType<typeof availableSites>>>
export type AvailableSitesQueryError = AxiosError<unknown>

/**
 * @summary Available Sites
 */
export const useAvailableSites = <TData = Awaited<ReturnType<typeof availableSites>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof availableSites>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAvailableSitesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Change Site Endpoint
 * @summary Change Site
 */
export const changeSite = (
    changeSiteRequestSchema: ChangeSiteRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/auth/change_site`,
      changeSiteRequestSchema,options
    );
  }



export const getChangeSiteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeSite>>, TError,{data: ChangeSiteRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof changeSite>>, TError,{data: ChangeSiteRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeSite>>, {data: ChangeSiteRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  changeSite(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeSiteMutationResult = NonNullable<Awaited<ReturnType<typeof changeSite>>>
    export type ChangeSiteMutationBody = ChangeSiteRequestSchema
    export type ChangeSiteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Change Site
 */
export const useChangeSite = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeSite>>, TError,{data: ChangeSiteRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof changeSite>>,
        TError,
        {data: ChangeSiteRequestSchema},
        TContext
      > => {

      const mutationOptions = getChangeSiteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Request Member Endpoint
 * @summary Delete Request Member
 */
export const deleteMember = (
    memberDeleteRequestSchema: MemberDeleteRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.delete(
      `/auth/delete-member`,{data:
      memberDeleteRequestSchema, ...options}
    );
  }



export const getDeleteMemberMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMember>>, TError,{data: MemberDeleteRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteMember>>, TError,{data: MemberDeleteRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMember>>, {data: MemberDeleteRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  deleteMember(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteMemberMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMember>>>
    export type DeleteMemberMutationBody = MemberDeleteRequestSchema
    export type DeleteMemberMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Request Member
 */
export const useDeleteMember = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMember>>, TError,{data: MemberDeleteRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteMember>>,
        TError,
        {data: MemberDeleteRequestSchema},
        TContext
      > => {

      const mutationOptions = getDeleteMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Validate Organisation Code
 * @summary Validate Organisation
 */
export const validateOrganisation = (
    organisationValidateRequestSchema: OrganisationValidateRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<OrganisationValidateResponseSchema>> => {
    
    return axios.post(
      `/organisations/validate`,
      organisationValidateRequestSchema,options
    );
  }



export const getValidateOrganisationMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateOrganisation>>, TError,{data: OrganisationValidateRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof validateOrganisation>>, TError,{data: OrganisationValidateRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateOrganisation>>, {data: OrganisationValidateRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  validateOrganisation(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ValidateOrganisationMutationResult = NonNullable<Awaited<ReturnType<typeof validateOrganisation>>>
    export type ValidateOrganisationMutationBody = OrganisationValidateRequestSchema
    export type ValidateOrganisationMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Validate Organisation
 */
export const useValidateOrganisation = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateOrganisation>>, TError,{data: OrganisationValidateRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof validateOrganisation>>,
        TError,
        {data: OrganisationValidateRequestSchema},
        TContext
      > => {

      const mutationOptions = getValidateOrganisationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get All Sites of an Organisation by token. (Applicable for Admin regiser page only)
 * @summary Get Organisation Sites
 */
export const getOrganisationSites = (
    params: GetOrganisationSitesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CreateAdminSiteResponseSchema[]>> => {
    
    return axios.get(
      `/sites/get-organisation-sites`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetOrganisationSitesQueryKey = (params: GetOrganisationSitesParams,) => {
    return [`/sites/get-organisation-sites`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrganisationSitesQueryOptions = <TData = Awaited<ReturnType<typeof getOrganisationSites>>, TError = AxiosError<HTTPValidationError>>(params: GetOrganisationSitesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganisationSites>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganisationSitesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganisationSites>>> = ({ signal }) => getOrganisationSites(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganisationSites>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganisationSitesQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganisationSites>>>
export type GetOrganisationSitesQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Organisation Sites
 */
export const useGetOrganisationSites = <TData = Awaited<ReturnType<typeof getOrganisationSites>>, TError = AxiosError<HTTPValidationError>>(
 params: GetOrganisationSitesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganisationSites>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganisationSitesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Upload Video
 * @summary Upload Video
 */
export const videoUpload = (
    bodyVideoUpload: BodyVideoUpload,
    params: VideoUploadParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ConfigurationCameraSchema>> => {const formData = new FormData();
formData.append('file', bodyVideoUpload.file)

    
    return axios.post(
      `/video/upload`,
      formData,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getVideoUploadMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof videoUpload>>, TError,{data: BodyVideoUpload;params: VideoUploadParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof videoUpload>>, TError,{data: BodyVideoUpload;params: VideoUploadParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof videoUpload>>, {data: BodyVideoUpload;params: VideoUploadParams}> = (props) => {
          const {data,params} = props ?? {};

          return  videoUpload(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type VideoUploadMutationResult = NonNullable<Awaited<ReturnType<typeof videoUpload>>>
    export type VideoUploadMutationBody = BodyVideoUpload
    export type VideoUploadMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Upload Video
 */
export const useVideoUpload = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof videoUpload>>, TError,{data: BodyVideoUpload;params: VideoUploadParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof videoUpload>>,
        TError,
        {data: BodyVideoUpload;params: VideoUploadParams},
        TContext
      > => {

      const mutationOptions = getVideoUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Video
 * @summary Get Video
 */
export const getVideo = (
    params: GetVideoParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.get(
      `/video/get_video`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetVideoQueryKey = (params: GetVideoParams,) => {
    return [`/video/get_video`, ...(params ? [params]: [])] as const;
    }

    
export const getGetVideoQueryOptions = <TData = Awaited<ReturnType<typeof getVideo>>, TError = AxiosError<HTTPValidationError>>(params: GetVideoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideo>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVideoQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVideo>>> = ({ signal }) => getVideo(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVideo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVideoQueryResult = NonNullable<Awaited<ReturnType<typeof getVideo>>>
export type GetVideoQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Video
 */
export const useGetVideo = <TData = Awaited<ReturnType<typeof getVideo>>, TError = AxiosError<HTTPValidationError>>(
 params: GetVideoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideo>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVideoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Queue
 * @summary Get Queue
 */
export const getVideoQueue = (
    params?: GetVideoQueueParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<APIResponseVideoSchema>> => {
    
    return axios.get(
      `/video/get_queue`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetVideoQueueQueryKey = (params?: GetVideoQueueParams,) => {
    return [`/video/get_queue`, ...(params ? [params]: [])] as const;
    }

    
export const getGetVideoQueueQueryOptions = <TData = Awaited<ReturnType<typeof getVideoQueue>>, TError = AxiosError<HTTPValidationError>>(params?: GetVideoQueueParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideoQueue>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVideoQueueQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVideoQueue>>> = ({ signal }) => getVideoQueue(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVideoQueue>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVideoQueueQueryResult = NonNullable<Awaited<ReturnType<typeof getVideoQueue>>>
export type GetVideoQueueQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Queue
 */
export const useGetVideoQueue = <TData = Awaited<ReturnType<typeof getVideoQueue>>, TError = AxiosError<HTTPValidationError>>(
 params?: GetVideoQueueParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideoQueue>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVideoQueueQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Start video processing
 * @summary Start Processing
 */
export const startVideoProcessing = (
    startProcessingRequestSchema: StartProcessingRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<APIResponseVideoSchema>> => {
    
    return axios.patch(
      `/video/start_processing`,
      startProcessingRequestSchema,options
    );
  }



export const getStartVideoProcessingMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startVideoProcessing>>, TError,{data: StartProcessingRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof startVideoProcessing>>, TError,{data: StartProcessingRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startVideoProcessing>>, {data: StartProcessingRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  startVideoProcessing(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StartVideoProcessingMutationResult = NonNullable<Awaited<ReturnType<typeof startVideoProcessing>>>
    export type StartVideoProcessingMutationBody = StartProcessingRequestSchema
    export type StartVideoProcessingMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Start Processing
 */
export const useStartVideoProcessing = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startVideoProcessing>>, TError,{data: StartProcessingRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof startVideoProcessing>>,
        TError,
        {data: StartProcessingRequestSchema},
        TContext
      > => {

      const mutationOptions = getStartVideoProcessingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Available Dates for reports
 * @summary Get Available Dates for reports
 */
export const getAvailableDates = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string[]>> => {
    
    return axios.get(
      `/video/get_available_dates`,options
    );
  }


export const getGetAvailableDatesQueryKey = () => {
    return [`/video/get_available_dates`] as const;
    }

    
export const getGetAvailableDatesQueryOptions = <TData = Awaited<ReturnType<typeof getAvailableDates>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAvailableDates>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAvailableDatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableDates>>> = ({ signal }) => getAvailableDates({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAvailableDates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAvailableDatesQueryResult = NonNullable<Awaited<ReturnType<typeof getAvailableDates>>>
export type GetAvailableDatesQueryError = AxiosError<unknown>

/**
 * @summary Get Available Dates for reports
 */
export const useGetAvailableDates = <TData = Awaited<ReturnType<typeof getAvailableDates>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAvailableDates>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAvailableDatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create Configuration
 * @summary Create Configuration
 */
export const createConfiguration = (
    createConfigurationRequestSchema: CreateConfigurationRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ConfigSchema>> => {
    
    return axios.post(
      `/training/create_configuration`,
      createConfigurationRequestSchema,options
    );
  }



export const getCreateConfigurationMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createConfiguration>>, TError,{data: CreateConfigurationRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createConfiguration>>, TError,{data: CreateConfigurationRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createConfiguration>>, {data: CreateConfigurationRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  createConfiguration(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof createConfiguration>>>
    export type CreateConfigurationMutationBody = CreateConfigurationRequestSchema
    export type CreateConfigurationMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Configuration
 */
export const useCreateConfiguration = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createConfiguration>>, TError,{data: CreateConfigurationRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createConfiguration>>,
        TError,
        {data: CreateConfigurationRequestSchema},
        TContext
      > => {

      const mutationOptions = getCreateConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Configuration
 * @summary Get Configuration
 */
export const getConfiguration = (
    params: GetConfigurationParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ConfigSchema>> => {
    
    return axios.get(
      `/training/get_configuration`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConfigurationQueryKey = (params: GetConfigurationParams,) => {
    return [`/training/get_configuration`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof getConfiguration>>, TError = AxiosError<HTTPValidationError>>(params: GetConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConfigurationQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfiguration>>> = ({ signal }) => getConfiguration(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getConfiguration>>>
export type GetConfigurationQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Configuration
 */
export const useGetConfiguration = <TData = Awaited<ReturnType<typeof getConfiguration>>, TError = AxiosError<HTTPValidationError>>(
 params: GetConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConfigurationQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List Configurations
 * @summary List Configurations
 */
export const listConfigurations = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ConfigSchema[]>> => {
    
    return axios.get(
      `/training/list_configurations`,options
    );
  }


export const getListConfigurationsQueryKey = () => {
    return [`/training/list_configurations`] as const;
    }

    
export const getListConfigurationsQueryOptions = <TData = Awaited<ReturnType<typeof listConfigurations>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigurations>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListConfigurationsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listConfigurations>>> = ({ signal }) => listConfigurations({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listConfigurations>>, TError, TData> & { queryKey: QueryKey }
}

export type ListConfigurationsQueryResult = NonNullable<Awaited<ReturnType<typeof listConfigurations>>>
export type ListConfigurationsQueryError = AxiosError<unknown>

/**
 * @summary List Configurations
 */
export const useListConfigurations = <TData = Awaited<ReturnType<typeof listConfigurations>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigurations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListConfigurationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Request Video Upload URL
 * @summary Request Video Upload URL
 */
export const requestVideoUploadUrl = (
    modulesTrainingSchemasGeturlRequest: ModulesTrainingSchemasGeturlRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileUploadResponseSchema>> => {
    
    return axios.post(
      `/training/request_video_upload_url`,
      modulesTrainingSchemasGeturlRequest,options
    );
  }



export const getRequestVideoUploadUrlMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestVideoUploadUrl>>, TError,{data: ModulesTrainingSchemasGeturlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof requestVideoUploadUrl>>, TError,{data: ModulesTrainingSchemasGeturlRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof requestVideoUploadUrl>>, {data: ModulesTrainingSchemasGeturlRequest}> = (props) => {
          const {data} = props ?? {};

          return  requestVideoUploadUrl(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RequestVideoUploadUrlMutationResult = NonNullable<Awaited<ReturnType<typeof requestVideoUploadUrl>>>
    export type RequestVideoUploadUrlMutationBody = ModulesTrainingSchemasGeturlRequest
    export type RequestVideoUploadUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Request Video Upload URL
 */
export const useRequestVideoUploadUrl = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestVideoUploadUrl>>, TError,{data: ModulesTrainingSchemasGeturlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof requestVideoUploadUrl>>,
        TError,
        {data: ModulesTrainingSchemasGeturlRequest},
        TContext
      > => {

      const mutationOptions = getRequestVideoUploadUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update Configuration Name
 * @summary Update Configuration Name
 */
export const updateConfiguration = (
    params: UpdateConfigurationParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.patch(
      `/training/update_configuration`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getUpdateConfigurationMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{params: UpdateConfigurationParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{params: UpdateConfigurationParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateConfiguration>>, {params: UpdateConfigurationParams}> = (props) => {
          const {params} = props ?? {};

          return  updateConfiguration(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof updateConfiguration>>>
    
    export type UpdateConfigurationMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Configuration Name
 */
export const useUpdateConfiguration = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{params: UpdateConfigurationParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateConfiguration>>,
        TError,
        {params: UpdateConfigurationParams},
        TContext
      > => {

      const mutationOptions = getUpdateConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Configuration
 * @summary Delete Configuration
 */
export const deleteConfiguration = (
    params: DeleteConfigurationParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.delete(
      `/training/delete_configuration`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteConfigurationMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteConfiguration>>, TError,{params: DeleteConfigurationParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteConfiguration>>, TError,{params: DeleteConfigurationParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteConfiguration>>, {params: DeleteConfigurationParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteConfiguration(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteConfiguration>>>
    
    export type DeleteConfigurationMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Configuration
 */
export const useDeleteConfiguration = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteConfiguration>>, TError,{params: DeleteConfigurationParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteConfiguration>>,
        TError,
        {params: DeleteConfigurationParams},
        TContext
      > => {

      const mutationOptions = getDeleteConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add Cameras
 * @summary Add Cameras
 */
export const addCameras = (
    addCamerasBody: string[],
    params: AddCamerasParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ConfigurationCameraSchema[]>> => {
    
    return axios.patch(
      `/training/add_cameras`,
      addCamerasBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getAddCamerasMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCameras>>, TError,{data: string[];params: AddCamerasParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addCameras>>, TError,{data: string[];params: AddCamerasParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCameras>>, {data: string[];params: AddCamerasParams}> = (props) => {
          const {data,params} = props ?? {};

          return  addCameras(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddCamerasMutationResult = NonNullable<Awaited<ReturnType<typeof addCameras>>>
    export type AddCamerasMutationBody = string[]
    export type AddCamerasMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Cameras
 */
export const useAddCameras = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCameras>>, TError,{data: string[];params: AddCamerasParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addCameras>>,
        TError,
        {data: string[];params: AddCamerasParams},
        TContext
      > => {

      const mutationOptions = getAddCamerasMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update Camera Details
 * @summary Update Camera Details
 */
export const updateCamera = (
    configId: string,
    updateCameraRequestSchema: UpdateCameraRequestSchema[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.patch(
      `/training/${configId}/update_camera`,
      updateCameraRequestSchema,options
    );
  }



export const getUpdateCameraMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCamera>>, TError,{configId: string;data: UpdateCameraRequestSchema[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateCamera>>, TError,{configId: string;data: UpdateCameraRequestSchema[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCamera>>, {configId: string;data: UpdateCameraRequestSchema[]}> = (props) => {
          const {configId,data} = props ?? {};

          return  updateCamera(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCameraMutationResult = NonNullable<Awaited<ReturnType<typeof updateCamera>>>
    export type UpdateCameraMutationBody = UpdateCameraRequestSchema[]
    export type UpdateCameraMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Camera Details
 */
export const useUpdateCamera = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCamera>>, TError,{configId: string;data: UpdateCameraRequestSchema[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCamera>>,
        TError,
        {configId: string;data: UpdateCameraRequestSchema[]},
        TContext
      > => {

      const mutationOptions = getUpdateCameraMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Configurations Camera
 * @summary Delete Configurations Camera
 */
export const deleteConfigurationsCamera = (
    params: DeleteConfigurationsCameraParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.delete(
      `/training/delete_configurations_camera`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteConfigurationsCameraMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteConfigurationsCamera>>, TError,{params: DeleteConfigurationsCameraParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteConfigurationsCamera>>, TError,{params: DeleteConfigurationsCameraParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteConfigurationsCamera>>, {params: DeleteConfigurationsCameraParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteConfigurationsCamera(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteConfigurationsCameraMutationResult = NonNullable<Awaited<ReturnType<typeof deleteConfigurationsCamera>>>
    
    export type DeleteConfigurationsCameraMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Configurations Camera
 */
export const useDeleteConfigurationsCamera = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteConfigurationsCamera>>, TError,{params: DeleteConfigurationsCameraParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteConfigurationsCamera>>,
        TError,
        {params: DeleteConfigurationsCameraParams},
        TContext
      > => {

      const mutationOptions = getDeleteConfigurationsCameraMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Upload Video
 * @summary Upload Video
 */
export const uploadVideo = (
    configId: string,
    cameraId: string,
    bodyUploadVideo: BodyUploadVideo, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<VideoUploadResponseSchema>> => {const formData = new FormData();
formData.append('file', bodyUploadVideo.file)

    
    return axios.post(
      `/training/${configId}/${cameraId}/upload_video`,
      formData,options
    );
  }



export const getUploadVideoMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadVideo>>, TError,{configId: string;cameraId: string;data: BodyUploadVideo}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof uploadVideo>>, TError,{configId: string;cameraId: string;data: BodyUploadVideo}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadVideo>>, {configId: string;cameraId: string;data: BodyUploadVideo}> = (props) => {
          const {configId,cameraId,data} = props ?? {};

          return  uploadVideo(configId,cameraId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadVideoMutationResult = NonNullable<Awaited<ReturnType<typeof uploadVideo>>>
    export type UploadVideoMutationBody = BodyUploadVideo
    export type UploadVideoMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Upload Video
 */
export const useUploadVideo = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadVideo>>, TError,{configId: string;cameraId: string;data: BodyUploadVideo}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadVideo>>,
        TError,
        {configId: string;cameraId: string;data: BodyUploadVideo},
        TContext
      > => {

      const mutationOptions = getUploadVideoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update Video Path
 * @summary Update Video Path
 */
export const updateVideoPath = (
    updateVideoRequestSchema: UpdateVideoRequestSchema,
    params: UpdateVideoPathParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UpdateVideoPath200>> => {
    
    return axios.post(
      `/training/update_video_path`,
      updateVideoRequestSchema,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getUpdateVideoPathMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVideoPath>>, TError,{data: UpdateVideoRequestSchema;params: UpdateVideoPathParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateVideoPath>>, TError,{data: UpdateVideoRequestSchema;params: UpdateVideoPathParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVideoPath>>, {data: UpdateVideoRequestSchema;params: UpdateVideoPathParams}> = (props) => {
          const {data,params} = props ?? {};

          return  updateVideoPath(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateVideoPathMutationResult = NonNullable<Awaited<ReturnType<typeof updateVideoPath>>>
    export type UpdateVideoPathMutationBody = UpdateVideoRequestSchema
    export type UpdateVideoPathMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Video Path
 */
export const useUpdateVideoPath = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVideoPath>>, TError,{data: UpdateVideoRequestSchema;params: UpdateVideoPathParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateVideoPath>>,
        TError,
        {data: UpdateVideoRequestSchema;params: UpdateVideoPathParams},
        TContext
      > => {

      const mutationOptions = getUpdateVideoPathMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add To Label Assist Queue
 * @summary Add To Label Assist Queue
 */
export const addToLabelAssist = (
    labelAsisitRequest: LabelAsisitRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AddToLabelAssist200>> => {
    
    return axios.post(
      `/training/add_to_label_assist`,
      labelAsisitRequest,options
    );
  }



export const getAddToLabelAssistMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToLabelAssist>>, TError,{data: LabelAsisitRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addToLabelAssist>>, TError,{data: LabelAsisitRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addToLabelAssist>>, {data: LabelAsisitRequest}> = (props) => {
          const {data} = props ?? {};

          return  addToLabelAssist(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddToLabelAssistMutationResult = NonNullable<Awaited<ReturnType<typeof addToLabelAssist>>>
    export type AddToLabelAssistMutationBody = LabelAsisitRequest
    export type AddToLabelAssistMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add To Label Assist Queue
 */
export const useAddToLabelAssist = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToLabelAssist>>, TError,{data: LabelAsisitRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addToLabelAssist>>,
        TError,
        {data: LabelAsisitRequest},
        TContext
      > => {

      const mutationOptions = getAddToLabelAssistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add To Activity Assist Queue
 * @summary Add To Activity Assist Queue
 */
export const addToActivityAssist = (
    params: AddToActivityAssistParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AddToActivityAssist200>> => {
    
    return axios.post(
      `/training/add_to_activity_assist`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getAddToActivityAssistMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToActivityAssist>>, TError,{params: AddToActivityAssistParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addToActivityAssist>>, TError,{params: AddToActivityAssistParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addToActivityAssist>>, {params: AddToActivityAssistParams}> = (props) => {
          const {params} = props ?? {};

          return  addToActivityAssist(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddToActivityAssistMutationResult = NonNullable<Awaited<ReturnType<typeof addToActivityAssist>>>
    
    export type AddToActivityAssistMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add To Activity Assist Queue
 */
export const useAddToActivityAssist = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToActivityAssist>>, TError,{params: AddToActivityAssistParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addToActivityAssist>>,
        TError,
        {params: AddToActivityAssistParams},
        TContext
      > => {

      const mutationOptions = getAddToActivityAssistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add To Training Assist Queue
 * @summary Add To Training Assist Queue
 */
export const addToTrainingAssist = (
    params: AddToTrainingAssistParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AddToTrainingAssist200>> => {
    
    return axios.post(
      `/training/add_to_training_assist`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getAddToTrainingAssistMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToTrainingAssist>>, TError,{params: AddToTrainingAssistParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addToTrainingAssist>>, TError,{params: AddToTrainingAssistParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addToTrainingAssist>>, {params: AddToTrainingAssistParams}> = (props) => {
          const {params} = props ?? {};

          return  addToTrainingAssist(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddToTrainingAssistMutationResult = NonNullable<Awaited<ReturnType<typeof addToTrainingAssist>>>
    
    export type AddToTrainingAssistMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add To Training Assist Queue
 */
export const useAddToTrainingAssist = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToTrainingAssist>>, TError,{params: AddToTrainingAssistParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addToTrainingAssist>>,
        TError,
        {params: AddToTrainingAssistParams},
        TContext
      > => {

      const mutationOptions = getAddToTrainingAssistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Pipeline Status
 * @summary Get Pipeline Status
 */
export const getPipelineStatus = (
    params: GetPipelineStatusParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PipelineStatusResponse[]>> => {
    
    return axios.get(
      `/training/get_pipeline_status`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetPipelineStatusQueryKey = (params: GetPipelineStatusParams,) => {
    return [`/training/get_pipeline_status`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPipelineStatusQueryOptions = <TData = Awaited<ReturnType<typeof getPipelineStatus>>, TError = AxiosError<HTTPValidationError>>(params: GetPipelineStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPipelineStatus>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPipelineStatusQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPipelineStatus>>> = ({ signal }) => getPipelineStatus(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPipelineStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPipelineStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getPipelineStatus>>>
export type GetPipelineStatusQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Pipeline Status
 */
export const useGetPipelineStatus = <TData = Awaited<ReturnType<typeof getPipelineStatus>>, TError = AxiosError<HTTPValidationError>>(
 params: GetPipelineStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPipelineStatus>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPipelineStatusQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Request image Upload URL
 * @summary Request image Upload URL
 */
export const requestImageUploadUrl = (
    modulesTrainingSchemasGeturlRequest: ModulesTrainingSchemasGeturlRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileUploadResponseSchema>> => {
    
    return axios.post(
      `/training/request_image_upload_url`,
      modulesTrainingSchemasGeturlRequest,options
    );
  }



export const getRequestImageUploadUrlMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestImageUploadUrl>>, TError,{data: ModulesTrainingSchemasGeturlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof requestImageUploadUrl>>, TError,{data: ModulesTrainingSchemasGeturlRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof requestImageUploadUrl>>, {data: ModulesTrainingSchemasGeturlRequest}> = (props) => {
          const {data} = props ?? {};

          return  requestImageUploadUrl(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RequestImageUploadUrlMutationResult = NonNullable<Awaited<ReturnType<typeof requestImageUploadUrl>>>
    export type RequestImageUploadUrlMutationBody = ModulesTrainingSchemasGeturlRequest
    export type RequestImageUploadUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Request image Upload URL
 */
export const useRequestImageUploadUrl = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestImageUploadUrl>>, TError,{data: ModulesTrainingSchemasGeturlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof requestImageUploadUrl>>,
        TError,
        {data: ModulesTrainingSchemasGeturlRequest},
        TContext
      > => {

      const mutationOptions = getRequestImageUploadUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add report configuration
 * @summary Add report configuration
 */
export const addReportConfiguration = (
    configId: string,
    reportconfigurationInput: ReportconfigurationInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ReportconfigResponse[]>> => {
    
    return axios.post(
      `/training/configuration/${configId}/report_configuration`,
      reportconfigurationInput,options
    );
  }



export const getAddReportConfigurationMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addReportConfiguration>>, TError,{configId: string;data: ReportconfigurationInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addReportConfiguration>>, TError,{configId: string;data: ReportconfigurationInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addReportConfiguration>>, {configId: string;data: ReportconfigurationInput}> = (props) => {
          const {configId,data} = props ?? {};

          return  addReportConfiguration(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddReportConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof addReportConfiguration>>>
    export type AddReportConfigurationMutationBody = ReportconfigurationInput
    export type AddReportConfigurationMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add report configuration
 */
export const useAddReportConfiguration = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addReportConfiguration>>, TError,{configId: string;data: ReportconfigurationInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addReportConfiguration>>,
        TError,
        {configId: string;data: ReportconfigurationInput},
        TContext
      > => {

      const mutationOptions = getAddReportConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Report Configuration
 * @summary Get Report Configuration
 */
export const getReportConfiguration = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ReportResponse[]>> => {
    
    return axios.get(
      `/training/configuration/${configId}/report_configuration`,options
    );
  }


export const getGetReportConfigurationQueryKey = (configId: string,) => {
    return [`/training/configuration/${configId}/report_configuration`] as const;
    }

    
export const getGetReportConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof getReportConfiguration>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReportConfiguration>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReportConfigurationQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportConfiguration>>> = ({ signal }) => getReportConfiguration(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getReportConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type GetReportConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getReportConfiguration>>>
export type GetReportConfigurationQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Report Configuration
 */
export const useGetReportConfiguration = <TData = Awaited<ReturnType<typeof getReportConfiguration>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReportConfiguration>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetReportConfigurationQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Report Configuration
 * @summary Get Report Configuration
 */
export const getReportConfigurations = (
    configId: string,
    params: GetReportConfigurationsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ReportconfigurationOutput[]>> => {
    
    return axios.get(
      `/training/configuration/${configId}/report_configurations`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetReportConfigurationsQueryKey = (configId: string,
    params: GetReportConfigurationsParams,) => {
    return [`/training/configuration/${configId}/report_configurations`, ...(params ? [params]: [])] as const;
    }

    
export const getGetReportConfigurationsQueryOptions = <TData = Awaited<ReturnType<typeof getReportConfigurations>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    params: GetReportConfigurationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReportConfigurations>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReportConfigurationsQueryKey(configId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportConfigurations>>> = ({ signal }) => getReportConfigurations(configId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getReportConfigurations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetReportConfigurationsQueryResult = NonNullable<Awaited<ReturnType<typeof getReportConfigurations>>>
export type GetReportConfigurationsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Report Configuration
 */
export const useGetReportConfigurations = <TData = Awaited<ReturnType<typeof getReportConfigurations>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    params: GetReportConfigurationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReportConfigurations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetReportConfigurationsQueryOptions(configId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Lable Assist Interrupt Service
 * @summary Lable Assist Interrupt Service
 */
export const labelAssistInterrupt = (
    configId: string,
    cameraId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LabelAssistInterrupt200>> => {
    
    return axios.post(
      `/trainingconfiguration/${configId}/camera/${cameraId}/label_assist_interrupt`,undefined,options
    );
  }



export const getLabelAssistInterruptMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof labelAssistInterrupt>>, TError,{configId: string;cameraId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof labelAssistInterrupt>>, TError,{configId: string;cameraId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof labelAssistInterrupt>>, {configId: string;cameraId: string}> = (props) => {
          const {configId,cameraId} = props ?? {};

          return  labelAssistInterrupt(configId,cameraId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LabelAssistInterruptMutationResult = NonNullable<Awaited<ReturnType<typeof labelAssistInterrupt>>>
    
    export type LabelAssistInterruptMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Lable Assist Interrupt Service
 */
export const useLabelAssistInterrupt = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof labelAssistInterrupt>>, TError,{configId: string;cameraId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof labelAssistInterrupt>>,
        TError,
        {configId: string;cameraId: string},
        TContext
      > => {

      const mutationOptions = getLabelAssistInterruptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Training Model
 * @summary Training Model
 */
export const trainSequenceModel = (
    configId: string,
    trainModelRequest: TrainModelRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TrainSequenceModel200>> => {
    
    return axios.post(
      `/trainingconfiguration/${configId}/train`,
      trainModelRequest,options
    );
  }



export const getTrainSequenceModelMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainSequenceModel>>, TError,{configId: string;data: TrainModelRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof trainSequenceModel>>, TError,{configId: string;data: TrainModelRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainSequenceModel>>, {configId: string;data: TrainModelRequest}> = (props) => {
          const {configId,data} = props ?? {};

          return  trainSequenceModel(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TrainSequenceModelMutationResult = NonNullable<Awaited<ReturnType<typeof trainSequenceModel>>>
    export type TrainSequenceModelMutationBody = TrainModelRequest
    export type TrainSequenceModelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Training Model
 */
export const useTrainSequenceModel = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainSequenceModel>>, TError,{configId: string;data: TrainModelRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof trainSequenceModel>>,
        TError,
        {configId: string;data: TrainModelRequest},
        TContext
      > => {

      const mutationOptions = getTrainSequenceModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Model
 * @summary Delete Model
 */
export const deleteSequenceModel = (
    configId: string,
    modelId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DeleteSequenceModel200>> => {
    
    return axios.delete(
      `/trainingconfiguration/${configId}/model${modelId}`,options
    );
  }



export const getDeleteSequenceModelMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSequenceModel>>, TError,{configId: string;modelId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteSequenceModel>>, TError,{configId: string;modelId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSequenceModel>>, {configId: string;modelId: string}> = (props) => {
          const {configId,modelId} = props ?? {};

          return  deleteSequenceModel(configId,modelId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteSequenceModelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSequenceModel>>>
    
    export type DeleteSequenceModelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Model
 */
export const useDeleteSequenceModel = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSequenceModel>>, TError,{configId: string;modelId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteSequenceModel>>,
        TError,
        {configId: string;modelId: string},
        TContext
      > => {

      const mutationOptions = getDeleteSequenceModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update Model
 * @summary Update Model
 */
export const updateSequenceModel = (
    configId: string,
    modelId: string,
    bodyUpdateSequenceModel: BodyUpdateSequenceModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UpdateSequenceModel200>> => {
    
    return axios.patch(
      `/training/configuration/${configId}/model/${modelId}`,
      bodyUpdateSequenceModel,options
    );
  }



export const getUpdateSequenceModelMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSequenceModel>>, TError,{configId: string;modelId: string;data: BodyUpdateSequenceModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateSequenceModel>>, TError,{configId: string;modelId: string;data: BodyUpdateSequenceModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSequenceModel>>, {configId: string;modelId: string;data: BodyUpdateSequenceModel}> = (props) => {
          const {configId,modelId,data} = props ?? {};

          return  updateSequenceModel(configId,modelId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSequenceModelMutationResult = NonNullable<Awaited<ReturnType<typeof updateSequenceModel>>>
    export type UpdateSequenceModelMutationBody = BodyUpdateSequenceModel
    export type UpdateSequenceModelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Model
 */
export const useUpdateSequenceModel = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSequenceModel>>, TError,{configId: string;modelId: string;data: BodyUpdateSequenceModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateSequenceModel>>,
        TError,
        {configId: string;modelId: string;data: BodyUpdateSequenceModel},
        TContext
      > => {

      const mutationOptions = getUpdateSequenceModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Objects detail
 * @summary Get Objects detail
 */
export const getObjects = (
    configId: string,
    cameraId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ObjSchema[]>> => {
    
    return axios.get(
      `/training/configuration/${configId}/camera/${cameraId}/object`,options
    );
  }


export const getGetObjectsQueryKey = (configId: string,
    cameraId: string,) => {
    return [`/training/configuration/${configId}/camera/${cameraId}/object`] as const;
    }

    
export const getGetObjectsQueryOptions = <TData = Awaited<ReturnType<typeof getObjects>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    cameraId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getObjects>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetObjectsQueryKey(configId,cameraId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getObjects>>> = ({ signal }) => getObjects(configId,cameraId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId && cameraId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getObjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetObjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getObjects>>>
export type GetObjectsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Objects detail
 */
export const useGetObjects = <TData = Awaited<ReturnType<typeof getObjects>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    cameraId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getObjects>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetObjectsQueryOptions(configId,cameraId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Sync Objects
 * @summary Sync Objects
 */
export const syncObject = (
    configId: string,
    cameraId: string,
    syncObjectsRequest: SyncObjectsRequest[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ObjResponseSchema>> => {
    
    return axios.patch(
      `/training/configuration/${configId}/camera/${cameraId}/object`,
      syncObjectsRequest,options
    );
  }



export const getSyncObjectMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncObject>>, TError,{configId: string;cameraId: string;data: SyncObjectsRequest[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncObject>>, TError,{configId: string;cameraId: string;data: SyncObjectsRequest[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncObject>>, {configId: string;cameraId: string;data: SyncObjectsRequest[]}> = (props) => {
          const {configId,cameraId,data} = props ?? {};

          return  syncObject(configId,cameraId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncObjectMutationResult = NonNullable<Awaited<ReturnType<typeof syncObject>>>
    export type SyncObjectMutationBody = SyncObjectsRequest[]
    export type SyncObjectMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync Objects
 */
export const useSyncObject = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncObject>>, TError,{configId: string;cameraId: string;data: SyncObjectsRequest[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncObject>>,
        TError,
        {configId: string;cameraId: string;data: SyncObjectsRequest[]},
        TContext
      > => {

      const mutationOptions = getSyncObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get all objects of a configuration
 * @summary get all objects of a configuration
 */
export const getAllObjects = (
    configId: string,
    params: GetAllObjectsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ObjResponseSchema>> => {
    
    return axios.get(
      `/training/configuration/${configId}/object`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetAllObjectsQueryKey = (configId: string,
    params: GetAllObjectsParams,) => {
    return [`/training/configuration/${configId}/object`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllObjectsQueryOptions = <TData = Awaited<ReturnType<typeof getAllObjects>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    params: GetAllObjectsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllObjects>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllObjectsQueryKey(configId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllObjects>>> = ({ signal }) => getAllObjects(configId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllObjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllObjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllObjects>>>
export type GetAllObjectsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get all objects of a configuration
 */
export const useGetAllObjects = <TData = Awaited<ReturnType<typeof getAllObjects>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    params: GetAllObjectsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllObjects>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllObjectsQueryOptions(configId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * get_activities
 * @summary get_activities
 */
export const getActivities = (
    configId: string,
    cameraId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ModulesTrainingActivitiesSchemasActivitySchema[]>> => {
    
    return axios.get(
      `/training/configuration/${configId}/camera/${cameraId}/activity`,options
    );
  }


export const getGetActivitiesQueryKey = (configId: string,
    cameraId: string,) => {
    return [`/training/configuration/${configId}/camera/${cameraId}/activity`] as const;
    }

    
export const getGetActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof getActivities>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    cameraId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetActivitiesQueryKey(configId,cameraId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivities>>> = ({ signal }) => getActivities(configId,cameraId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId && cameraId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getActivities>>>
export type GetActivitiesQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get_activities
 */
export const useGetActivities = <TData = Awaited<ReturnType<typeof getActivities>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    cameraId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivities>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetActivitiesQueryOptions(configId,cameraId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * get all activities of a configuration
 * @summary get all activties of a configuration
 */
export const getAllActivities = (
    configId: string,
    params: GetAllActivitiesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityResponseSchema>> => {
    
    return axios.get(
      `/training/configuration/${configId}/activity`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetAllActivitiesQueryKey = (configId: string,
    params: GetAllActivitiesParams,) => {
    return [`/training/configuration/${configId}/activity`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof getAllActivities>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    params: GetAllActivitiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllActivities>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllActivitiesQueryKey(configId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllActivities>>> = ({ signal }) => getAllActivities(configId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllActivities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllActivities>>>
export type GetAllActivitiesQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get all activties of a configuration
 */
export const useGetAllActivities = <TData = Awaited<ReturnType<typeof getAllActivities>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    params: GetAllActivitiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllActivities>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllActivitiesQueryOptions(configId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Sync Activity
 * @summary Sync Activity
 */
export const syncActivity = (
    configId: string,
    syncActivityRequest: SyncActivityRequest[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityResponseSchema>> => {
    
    return axios.patch(
      `/training/configuration/${configId}/activity`,
      syncActivityRequest,options
    );
  }



export const getSyncActivityMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncActivity>>, TError,{configId: string;data: SyncActivityRequest[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof syncActivity>>, TError,{configId: string;data: SyncActivityRequest[]}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncActivity>>, {configId: string;data: SyncActivityRequest[]}> = (props) => {
          const {configId,data} = props ?? {};

          return  syncActivity(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SyncActivityMutationResult = NonNullable<Awaited<ReturnType<typeof syncActivity>>>
    export type SyncActivityMutationBody = SyncActivityRequest[]
    export type SyncActivityMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Sync Activity
 */
export const useSyncActivity = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncActivity>>, TError,{configId: string;data: SyncActivityRequest[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof syncActivity>>,
        TError,
        {configId: string;data: SyncActivityRequest[]},
        TContext
      > => {

      const mutationOptions = getSyncActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get dashboard details
 * @summary get dashboard details
 */
export const getDashboardDetails = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DashBoardConfigSchemaOutput[]>> => {
    
    return axios.get(
      `/training/configuration/${configId}/dashboard`,options
    );
  }


export const getGetDashboardDetailsQueryKey = (configId: string,) => {
    return [`/training/configuration/${configId}/dashboard`] as const;
    }

    
export const getGetDashboardDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getDashboardDetails>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardDetails>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDashboardDetailsQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboardDetails>>> = ({ signal }) => getDashboardDetails(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDashboardDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDashboardDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboardDetails>>>
export type GetDashboardDetailsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get dashboard details
 */
export const useGetDashboardDetails = <TData = Awaited<ReturnType<typeof getDashboardDetails>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardDetails>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDashboardDetailsQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create dashboard Configuration
 * @summary Create dashboard Configuration
 */
export const createDashboardConfiguration = (
    configId: string,
    dashBoardConfigSchemaInput: DashBoardConfigSchemaInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DashBoardConfigSchemaOutput>> => {
    
    return axios.post(
      `/training/configuration/${configId}/dashboard`,
      dashBoardConfigSchemaInput,options
    );
  }



export const getCreateDashboardConfigurationMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDashboardConfiguration>>, TError,{configId: string;data: DashBoardConfigSchemaInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createDashboardConfiguration>>, TError,{configId: string;data: DashBoardConfigSchemaInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDashboardConfiguration>>, {configId: string;data: DashBoardConfigSchemaInput}> = (props) => {
          const {configId,data} = props ?? {};

          return  createDashboardConfiguration(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateDashboardConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof createDashboardConfiguration>>>
    export type CreateDashboardConfigurationMutationBody = DashBoardConfigSchemaInput
    export type CreateDashboardConfigurationMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create dashboard Configuration
 */
export const useCreateDashboardConfiguration = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDashboardConfiguration>>, TError,{configId: string;data: DashBoardConfigSchemaInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createDashboardConfiguration>>,
        TError,
        {configId: string;data: DashBoardConfigSchemaInput},
        TContext
      > => {

      const mutationOptions = getCreateDashboardConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * post employee productvity detail
 * @summary post employee productvity detail
 */
export const postEmployeeProductvityDetail = (
    configId: string,
    employeeSchema: EmployeeSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EmployeeResponse>> => {
    
    return axios.post(
      `/analysis/configuration/${configId}/employee_productvity`,
      employeeSchema,options
    );
  }



export const getPostEmployeeProductvityDetailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postEmployeeProductvityDetail>>, TError,{configId: string;data: EmployeeSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postEmployeeProductvityDetail>>, TError,{configId: string;data: EmployeeSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postEmployeeProductvityDetail>>, {configId: string;data: EmployeeSchema}> = (props) => {
          const {configId,data} = props ?? {};

          return  postEmployeeProductvityDetail(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostEmployeeProductvityDetailMutationResult = NonNullable<Awaited<ReturnType<typeof postEmployeeProductvityDetail>>>
    export type PostEmployeeProductvityDetailMutationBody = EmployeeSchema
    export type PostEmployeeProductvityDetailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary post employee productvity detail
 */
export const usePostEmployeeProductvityDetail = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postEmployeeProductvityDetail>>, TError,{configId: string;data: EmployeeSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postEmployeeProductvityDetail>>,
        TError,
        {configId: string;data: EmployeeSchema},
        TContext
      > => {

      const mutationOptions = getPostEmployeeProductvityDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get employee productvity detail
 * @summary get employee productvity detail
 */
export const getEmployeeProductvityDetail = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EmployeeResponse[]>> => {
    
    return axios.get(
      `/analysis/configuration/${configId}/employee_productvity`,options
    );
  }


export const getGetEmployeeProductvityDetailQueryKey = (configId: string,) => {
    return [`/analysis/configuration/${configId}/employee_productvity`] as const;
    }

    
export const getGetEmployeeProductvityDetailQueryOptions = <TData = Awaited<ReturnType<typeof getEmployeeProductvityDetail>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmployeeProductvityDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEmployeeProductvityDetailQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmployeeProductvityDetail>>> = ({ signal }) => getEmployeeProductvityDetail(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEmployeeProductvityDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEmployeeProductvityDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getEmployeeProductvityDetail>>>
export type GetEmployeeProductvityDetailQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get employee productvity detail
 */
export const useGetEmployeeProductvityDetail = <TData = Awaited<ReturnType<typeof getEmployeeProductvityDetail>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmployeeProductvityDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEmployeeProductvityDetailQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * post product throughput detail
 * @summary post product throughput detail
 */
export const postProductThroughputDetail = (
    configId: string,
    productSchema: ProductSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProductResponse>> => {
    
    return axios.post(
      `/analysis/configuration/${configId}/product_throughput`,
      productSchema,options
    );
  }



export const getPostProductThroughputDetailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProductThroughputDetail>>, TError,{configId: string;data: ProductSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProductThroughputDetail>>, TError,{configId: string;data: ProductSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProductThroughputDetail>>, {configId: string;data: ProductSchema}> = (props) => {
          const {configId,data} = props ?? {};

          return  postProductThroughputDetail(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProductThroughputDetailMutationResult = NonNullable<Awaited<ReturnType<typeof postProductThroughputDetail>>>
    export type PostProductThroughputDetailMutationBody = ProductSchema
    export type PostProductThroughputDetailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary post product throughput detail
 */
export const usePostProductThroughputDetail = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProductThroughputDetail>>, TError,{configId: string;data: ProductSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProductThroughputDetail>>,
        TError,
        {configId: string;data: ProductSchema},
        TContext
      > => {

      const mutationOptions = getPostProductThroughputDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get product throughput detail
 * @summary get product_throughput detail
 */
export const getProductThroughputDetail = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProductResponse[]>> => {
    
    return axios.get(
      `/analysis/configuration/${configId}/product_throughput`,options
    );
  }


export const getGetProductThroughputDetailQueryKey = (configId: string,) => {
    return [`/analysis/configuration/${configId}/product_throughput`] as const;
    }

    
export const getGetProductThroughputDetailQueryOptions = <TData = Awaited<ReturnType<typeof getProductThroughputDetail>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductThroughputDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductThroughputDetailQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductThroughputDetail>>> = ({ signal }) => getProductThroughputDetail(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductThroughputDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProductThroughputDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getProductThroughputDetail>>>
export type GetProductThroughputDetailQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get product_throughput detail
 */
export const useGetProductThroughputDetail = <TData = Awaited<ReturnType<typeof getProductThroughputDetail>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductThroughputDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProductThroughputDetailQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * post safety gear detail
 * @summary post safety gear detail
 */
export const postSafetyGearDetail = (
    configId: string,
    safetyGearSchema: SafetyGearSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SafetyGearResponse[]>> => {
    
    return axios.post(
      `/analysis/configuration/${configId}/safety_gear`,
      safetyGearSchema,options
    );
  }



export const getPostSafetyGearDetailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSafetyGearDetail>>, TError,{configId: string;data: SafetyGearSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postSafetyGearDetail>>, TError,{configId: string;data: SafetyGearSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSafetyGearDetail>>, {configId: string;data: SafetyGearSchema}> = (props) => {
          const {configId,data} = props ?? {};

          return  postSafetyGearDetail(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostSafetyGearDetailMutationResult = NonNullable<Awaited<ReturnType<typeof postSafetyGearDetail>>>
    export type PostSafetyGearDetailMutationBody = SafetyGearSchema
    export type PostSafetyGearDetailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary post safety gear detail
 */
export const usePostSafetyGearDetail = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSafetyGearDetail>>, TError,{configId: string;data: SafetyGearSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postSafetyGearDetail>>,
        TError,
        {configId: string;data: SafetyGearSchema},
        TContext
      > => {

      const mutationOptions = getPostSafetyGearDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get safety gear detail
 * @summary get safety gear detail
 */
export const getSafetyGearDetail = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SafetyGearResponse[]>> => {
    
    return axios.get(
      `/analysis/configuration/${configId}/safety_gear`,options
    );
  }


export const getGetSafetyGearDetailQueryKey = (configId: string,) => {
    return [`/analysis/configuration/${configId}/safety_gear`] as const;
    }

    
export const getGetSafetyGearDetailQueryOptions = <TData = Awaited<ReturnType<typeof getSafetyGearDetail>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSafetyGearDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSafetyGearDetailQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSafetyGearDetail>>> = ({ signal }) => getSafetyGearDetail(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSafetyGearDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSafetyGearDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getSafetyGearDetail>>>
export type GetSafetyGearDetailQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get safety gear detail
 */
export const useGetSafetyGearDetail = <TData = Awaited<ReturnType<typeof getSafetyGearDetail>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSafetyGearDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSafetyGearDetailQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * post inverntory_management detail
 * @summary post inverntory_management detail
 */
export const postInverntoryManagementDetail = (
    configId: string,
    inventorySchema: InventorySchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InventoryResponse[]>> => {
    
    return axios.post(
      `/analysis/configuration/${configId}/inverntory_management`,
      inventorySchema,options
    );
  }



export const getPostInverntoryManagementDetailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInverntoryManagementDetail>>, TError,{configId: string;data: InventorySchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postInverntoryManagementDetail>>, TError,{configId: string;data: InventorySchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postInverntoryManagementDetail>>, {configId: string;data: InventorySchema}> = (props) => {
          const {configId,data} = props ?? {};

          return  postInverntoryManagementDetail(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostInverntoryManagementDetailMutationResult = NonNullable<Awaited<ReturnType<typeof postInverntoryManagementDetail>>>
    export type PostInverntoryManagementDetailMutationBody = InventorySchema
    export type PostInverntoryManagementDetailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary post inverntory_management detail
 */
export const usePostInverntoryManagementDetail = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postInverntoryManagementDetail>>, TError,{configId: string;data: InventorySchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postInverntoryManagementDetail>>,
        TError,
        {configId: string;data: InventorySchema},
        TContext
      > => {

      const mutationOptions = getPostInverntoryManagementDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get inverntory_management detail
 * @summary get inverntory_management detail
 */
export const getInverntoryManagementDetail = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InventoryResponse[]>> => {
    
    return axios.get(
      `/analysis/configuration/${configId}/inverntory_management`,options
    );
  }


export const getGetInverntoryManagementDetailQueryKey = (configId: string,) => {
    return [`/analysis/configuration/${configId}/inverntory_management`] as const;
    }

    
export const getGetInverntoryManagementDetailQueryOptions = <TData = Awaited<ReturnType<typeof getInverntoryManagementDetail>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInverntoryManagementDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInverntoryManagementDetailQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInverntoryManagementDetail>>> = ({ signal }) => getInverntoryManagementDetail(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInverntoryManagementDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInverntoryManagementDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getInverntoryManagementDetail>>>
export type GetInverntoryManagementDetailQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get inverntory_management detail
 */
export const useGetInverntoryManagementDetail = <TData = Awaited<ReturnType<typeof getInverntoryManagementDetail>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInverntoryManagementDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInverntoryManagementDetailQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * post security detail
 * @summary post security detail
 */
export const postSecurityDetail = (
    configId: string,
    securitySchema: SecuritySchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SecurityResponse>> => {
    
    return axios.post(
      `/analysis/configuration/${configId}/security`,
      securitySchema,options
    );
  }



export const getPostSecurityDetailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSecurityDetail>>, TError,{configId: string;data: SecuritySchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postSecurityDetail>>, TError,{configId: string;data: SecuritySchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSecurityDetail>>, {configId: string;data: SecuritySchema}> = (props) => {
          const {configId,data} = props ?? {};

          return  postSecurityDetail(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostSecurityDetailMutationResult = NonNullable<Awaited<ReturnType<typeof postSecurityDetail>>>
    export type PostSecurityDetailMutationBody = SecuritySchema
    export type PostSecurityDetailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary post security detail
 */
export const usePostSecurityDetail = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSecurityDetail>>, TError,{configId: string;data: SecuritySchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postSecurityDetail>>,
        TError,
        {configId: string;data: SecuritySchema},
        TContext
      > => {

      const mutationOptions = getPostSecurityDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get security detail
 * @summary get security detail
 */
export const getSecurityDetail = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SecurityResponse[]>> => {
    
    return axios.get(
      `/analysis/configuration/${configId}/security`,options
    );
  }


export const getGetSecurityDetailQueryKey = (configId: string,) => {
    return [`/analysis/configuration/${configId}/security`] as const;
    }

    
export const getGetSecurityDetailQueryOptions = <TData = Awaited<ReturnType<typeof getSecurityDetail>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSecurityDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSecurityDetailQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSecurityDetail>>> = ({ signal }) => getSecurityDetail(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSecurityDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSecurityDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getSecurityDetail>>>
export type GetSecurityDetailQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get security detail
 */
export const useGetSecurityDetail = <TData = Awaited<ReturnType<typeof getSecurityDetail>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSecurityDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSecurityDetailQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * post workflow detail
 * @summary post workflow detail
 */
export const postWorkflowDetail = (
    configId: string,
    workflowSchema: WorkflowSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkflowSchema>> => {
    
    return axios.post(
      `/analysis/configuration/${configId}/workflow`,
      workflowSchema,options
    );
  }



export const getPostWorkflowDetailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWorkflowDetail>>, TError,{configId: string;data: WorkflowSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postWorkflowDetail>>, TError,{configId: string;data: WorkflowSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWorkflowDetail>>, {configId: string;data: WorkflowSchema}> = (props) => {
          const {configId,data} = props ?? {};

          return  postWorkflowDetail(configId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostWorkflowDetailMutationResult = NonNullable<Awaited<ReturnType<typeof postWorkflowDetail>>>
    export type PostWorkflowDetailMutationBody = WorkflowSchema
    export type PostWorkflowDetailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary post workflow detail
 */
export const usePostWorkflowDetail = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWorkflowDetail>>, TError,{configId: string;data: WorkflowSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postWorkflowDetail>>,
        TError,
        {configId: string;data: WorkflowSchema},
        TContext
      > => {

      const mutationOptions = getPostWorkflowDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get workflow detail
 * @summary get workflow detail
 */
export const getWorkflowDetail = (
    configId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WorkflowSchema[]>> => {
    
    return axios.get(
      `/analysis/configuration/${configId}/workflow`,options
    );
  }


export const getGetWorkflowDetailQueryKey = (configId: string,) => {
    return [`/analysis/configuration/${configId}/workflow`] as const;
    }

    
export const getGetWorkflowDetailQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDetail>>, TError = AxiosError<HTTPValidationError>>(configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDetailQueryKey(configId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDetail>>> = ({ signal }) => getWorkflowDetail(configId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDetail>>>
export type GetWorkflowDetailQueryError = AxiosError<HTTPValidationError>

/**
 * @summary get workflow detail
 */
export const useGetWorkflowDetail = <TData = Awaited<ReturnType<typeof getWorkflowDetail>>, TError = AxiosError<HTTPValidationError>>(
 configId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkflowDetailQueryOptions(configId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Request Video Upload URL
 * @summary Request Video Upload URL
 */
export const inferenceRequestVideoUploadUrl = (
    modulesInferenceSchemaGeturlRequest: ModulesInferenceSchemaGeturlRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileUploadResponseSchema>> => {
    
    return axios.post(
      `/inference/request_video_upload_url`,
      modulesInferenceSchemaGeturlRequest,options
    );
  }



export const getInferenceRequestVideoUploadUrlMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferenceRequestVideoUploadUrl>>, TError,{data: ModulesInferenceSchemaGeturlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof inferenceRequestVideoUploadUrl>>, TError,{data: ModulesInferenceSchemaGeturlRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inferenceRequestVideoUploadUrl>>, {data: ModulesInferenceSchemaGeturlRequest}> = (props) => {
          const {data} = props ?? {};

          return  inferenceRequestVideoUploadUrl(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InferenceRequestVideoUploadUrlMutationResult = NonNullable<Awaited<ReturnType<typeof inferenceRequestVideoUploadUrl>>>
    export type InferenceRequestVideoUploadUrlMutationBody = ModulesInferenceSchemaGeturlRequest
    export type InferenceRequestVideoUploadUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Request Video Upload URL
 */
export const useInferenceRequestVideoUploadUrl = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferenceRequestVideoUploadUrl>>, TError,{data: ModulesInferenceSchemaGeturlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof inferenceRequestVideoUploadUrl>>,
        TError,
        {data: ModulesInferenceSchemaGeturlRequest},
        TContext
      > => {

      const mutationOptions = getInferenceRequestVideoUploadUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List of Inference Videos
 * @summary List of Inference Videos
 */
export const listInferenceVideos = (
    configId: string,
    params?: ListInferenceVideosParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<VideosSchema[]>> => {
    
    return axios.get(
      `/inference/${configId}/list_inference_videos`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getListInferenceVideosQueryKey = (configId: string,
    params?: ListInferenceVideosParams,) => {
    return [`/inference/${configId}/list_inference_videos`, ...(params ? [params]: [])] as const;
    }

    
export const getListInferenceVideosQueryOptions = <TData = Awaited<ReturnType<typeof listInferenceVideos>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    params?: ListInferenceVideosParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInferenceVideos>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListInferenceVideosQueryKey(configId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listInferenceVideos>>> = ({ signal }) => listInferenceVideos(configId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listInferenceVideos>>, TError, TData> & { queryKey: QueryKey }
}

export type ListInferenceVideosQueryResult = NonNullable<Awaited<ReturnType<typeof listInferenceVideos>>>
export type ListInferenceVideosQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List of Inference Videos
 */
export const useListInferenceVideos = <TData = Awaited<ReturnType<typeof listInferenceVideos>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    params?: ListInferenceVideosParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInferenceVideos>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListInferenceVideosQueryOptions(configId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Infered Videos Grpah Data
 * @summary Infered Videos Grpah Data
 */
export const inferedVideosGraphData = (
    configId: string,
    params?: InferedVideosGraphDataParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferedVideosGraphData200>> => {
    
    return axios.get(
      `/inference/${configId}/infered_graph_data`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getInferedVideosGraphDataQueryKey = (configId: string,
    params?: InferedVideosGraphDataParams,) => {
    return [`/inference/${configId}/infered_graph_data`, ...(params ? [params]: [])] as const;
    }

    
export const getInferedVideosGraphDataQueryOptions = <TData = Awaited<ReturnType<typeof inferedVideosGraphData>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    params?: InferedVideosGraphDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof inferedVideosGraphData>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInferedVideosGraphDataQueryKey(configId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof inferedVideosGraphData>>> = ({ signal }) => inferedVideosGraphData(configId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof inferedVideosGraphData>>, TError, TData> & { queryKey: QueryKey }
}

export type InferedVideosGraphDataQueryResult = NonNullable<Awaited<ReturnType<typeof inferedVideosGraphData>>>
export type InferedVideosGraphDataQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Infered Videos Grpah Data
 */
export const useInferedVideosGraphData = <TData = Awaited<ReturnType<typeof inferedVideosGraphData>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    params?: InferedVideosGraphDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof inferedVideosGraphData>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInferedVideosGraphDataQueryOptions(configId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Report details for video
 * @summary Report details for video
 */
export const videoReport = (
    configId: string,
    videoId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<VideoReport200>> => {
    
    return axios.get(
      `/inference/${configId}/${videoId}/reports`,options
    );
  }


export const getVideoReportQueryKey = (configId: string,
    videoId: string,) => {
    return [`/inference/${configId}/${videoId}/reports`] as const;
    }

    
export const getVideoReportQueryOptions = <TData = Awaited<ReturnType<typeof videoReport>>, TError = AxiosError<HTTPValidationError>>(configId: string,
    videoId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof videoReport>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVideoReportQueryKey(configId,videoId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof videoReport>>> = ({ signal }) => videoReport(configId,videoId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(configId && videoId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof videoReport>>, TError, TData> & { queryKey: QueryKey }
}

export type VideoReportQueryResult = NonNullable<Awaited<ReturnType<typeof videoReport>>>
export type VideoReportQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Report details for video
 */
export const useVideoReport = <TData = Awaited<ReturnType<typeof videoReport>>, TError = AxiosError<HTTPValidationError>>(
 configId: string,
    videoId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof videoReport>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVideoReportQueryOptions(configId,videoId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List of Inference Models
 * @summary List of Inference Models
 */
export const listInferenceModels = (
    siteId: string,
    params?: ListInferenceModelsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferenceModelSchema[]>> => {
    
    return axios.get(
      `/inference/${siteId}/list_inference_models`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getListInferenceModelsQueryKey = (siteId: string,
    params?: ListInferenceModelsParams,) => {
    return [`/inference/${siteId}/list_inference_models`, ...(params ? [params]: [])] as const;
    }

    
export const getListInferenceModelsQueryOptions = <TData = Awaited<ReturnType<typeof listInferenceModels>>, TError = AxiosError<HTTPValidationError>>(siteId: string,
    params?: ListInferenceModelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInferenceModels>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListInferenceModelsQueryKey(siteId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listInferenceModels>>> = ({ signal }) => listInferenceModels(siteId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(siteId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listInferenceModels>>, TError, TData> & { queryKey: QueryKey }
}

export type ListInferenceModelsQueryResult = NonNullable<Awaited<ReturnType<typeof listInferenceModels>>>
export type ListInferenceModelsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary List of Inference Models
 */
export const useListInferenceModels = <TData = Awaited<ReturnType<typeof listInferenceModels>>, TError = AxiosError<HTTPValidationError>>(
 siteId: string,
    params?: ListInferenceModelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInferenceModels>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListInferenceModelsQueryOptions(siteId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * add Inference Videos
 * @summary add Inference Videos
 */
export const addInferenceVideos = (
    siteId: string,
    addInferenceVideoRequestSchema: AddInferenceVideoRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<VideosSchema>> => {
    
    return axios.post(
      `/inference/${siteId}/add_inference_videos`,
      addInferenceVideoRequestSchema,options
    );
  }



export const getAddInferenceVideosMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addInferenceVideos>>, TError,{siteId: string;data: AddInferenceVideoRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addInferenceVideos>>, TError,{siteId: string;data: AddInferenceVideoRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addInferenceVideos>>, {siteId: string;data: AddInferenceVideoRequestSchema}> = (props) => {
          const {siteId,data} = props ?? {};

          return  addInferenceVideos(siteId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddInferenceVideosMutationResult = NonNullable<Awaited<ReturnType<typeof addInferenceVideos>>>
    export type AddInferenceVideosMutationBody = AddInferenceVideoRequestSchema
    export type AddInferenceVideosMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary add Inference Videos
 */
export const useAddInferenceVideos = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addInferenceVideos>>, TError,{siteId: string;data: AddInferenceVideoRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addInferenceVideos>>,
        TError,
        {siteId: string;data: AddInferenceVideoRequestSchema},
        TContext
      > => {

      const mutationOptions = getAddInferenceVideosMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * add to activity_inference
 * @summary add to activity_inference
 */
export const addToActivityInference = (
    configId: string,
    addToInferenceRequest: AddToInferenceRequest[],
    params: AddToActivityInferenceParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferenceModelSchema[]>> => {
    
    return axios.post(
      `/inference/${configId}/add_to_activity_inference`,
      addToInferenceRequest,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getAddToActivityInferenceMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToActivityInference>>, TError,{configId: string;data: AddToInferenceRequest[];params: AddToActivityInferenceParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addToActivityInference>>, TError,{configId: string;data: AddToInferenceRequest[];params: AddToActivityInferenceParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addToActivityInference>>, {configId: string;data: AddToInferenceRequest[];params: AddToActivityInferenceParams}> = (props) => {
          const {configId,data,params} = props ?? {};

          return  addToActivityInference(configId,data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddToActivityInferenceMutationResult = NonNullable<Awaited<ReturnType<typeof addToActivityInference>>>
    export type AddToActivityInferenceMutationBody = AddToInferenceRequest[]
    export type AddToActivityInferenceMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary add to activity_inference
 */
export const useAddToActivityInference = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToActivityInference>>, TError,{configId: string;data: AddToInferenceRequest[];params: AddToActivityInferenceParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addToActivityInference>>,
        TError,
        {configId: string;data: AddToInferenceRequest[];params: AddToActivityInferenceParams},
        TContext
      > => {

      const mutationOptions = getAddToActivityInferenceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Video
 * @summary Delete Video
 */
export const deleteInferenceVideos = (
    videoId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.delete(
      `/inference/delete_video/${videoId}`,options
    );
  }



export const getDeleteInferenceVideosMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInferenceVideos>>, TError,{videoId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteInferenceVideos>>, TError,{videoId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteInferenceVideos>>, {videoId: string}> = (props) => {
          const {videoId} = props ?? {};

          return  deleteInferenceVideos(videoId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteInferenceVideosMutationResult = NonNullable<Awaited<ReturnType<typeof deleteInferenceVideos>>>
    
    export type DeleteInferenceVideosMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Video
 */
export const useDeleteInferenceVideos = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInferenceVideos>>, TError,{videoId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteInferenceVideos>>,
        TError,
        {videoId: string},
        TContext
      > => {

      const mutationOptions = getDeleteInferenceVideosMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * list camera details
 * @summary camera details
 */
export const listCameraDetails = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Camera[]>> => {
    
    return axios.get(
      `/configuration/cameras/`,options
    );
  }


export const getListCameraDetailsQueryKey = () => {
    return [`/configuration/cameras/`] as const;
    }

    
export const getListCameraDetailsQueryOptions = <TData = Awaited<ReturnType<typeof listCameraDetails>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCameraDetails>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCameraDetailsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCameraDetails>>> = ({ signal }) => listCameraDetails({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCameraDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCameraDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof listCameraDetails>>>
export type ListCameraDetailsQueryError = AxiosError<unknown>

/**
 * @summary camera details
 */
export const useListCameraDetails = <TData = Awaited<ReturnType<typeof listCameraDetails>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCameraDetails>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCameraDetailsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Add camera details
 * @summary Add camera details
 */
export const addCameraData = (
    addCameraRequestSchema: AddCameraRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CameraResponseSchema>> => {
    
    return axios.post(
      `/configuration/cameras/`,
      addCameraRequestSchema,options
    );
  }



export const getAddCameraDataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCameraData>>, TError,{data: AddCameraRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addCameraData>>, TError,{data: AddCameraRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCameraData>>, {data: AddCameraRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  addCameraData(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddCameraDataMutationResult = NonNullable<Awaited<ReturnType<typeof addCameraData>>>
    export type AddCameraDataMutationBody = AddCameraRequestSchema
    export type AddCameraDataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add camera details
 */
export const useAddCameraData = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCameraData>>, TError,{data: AddCameraRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addCameraData>>,
        TError,
        {data: AddCameraRequestSchema},
        TContext
      > => {

      const mutationOptions = getAddCameraDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update camera details
 * @summary Update camera details
 */
export const updateCameraData = (
    id: string,
    addCameraRequestSchema: AddCameraRequestSchema, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CameraResponseSchema>> => {
    
    return axios.put(
      `/configuration/cameras/${id}`,
      addCameraRequestSchema,options
    );
  }



export const getUpdateCameraDataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCameraData>>, TError,{id: string;data: AddCameraRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateCameraData>>, TError,{id: string;data: AddCameraRequestSchema}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCameraData>>, {id: string;data: AddCameraRequestSchema}> = (props) => {
          const {id,data} = props ?? {};

          return  updateCameraData(id,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCameraDataMutationResult = NonNullable<Awaited<ReturnType<typeof updateCameraData>>>
    export type UpdateCameraDataMutationBody = AddCameraRequestSchema
    export type UpdateCameraDataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update camera details
 */
export const useUpdateCameraData = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCameraData>>, TError,{id: string;data: AddCameraRequestSchema}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCameraData>>,
        TError,
        {id: string;data: AddCameraRequestSchema},
        TContext
      > => {

      const mutationOptions = getUpdateCameraDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete camera details
 * @summary Delete camera details
 */
export const deleteCameraData = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CameraResponseSchema>> => {
    
    return axios.delete(
      `/configuration/cameras/${id}`,options
    );
  }



export const getDeleteCameraDataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCameraData>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCameraData>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCameraData>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteCameraData(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCameraDataMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCameraData>>>
    
    export type DeleteCameraDataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete camera details
 */
export const useDeleteCameraData = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCameraData>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCameraData>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteCameraDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Stream camera video
 * @summary Stream camera video
 */
export const streamCamera = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.get(
      `/configuration/cameras/${id}/stream`,options
    );
  }


export const getStreamCameraQueryKey = (id: string,) => {
    return [`/configuration/cameras/${id}/stream`] as const;
    }

    
export const getStreamCameraQueryOptions = <TData = Awaited<ReturnType<typeof streamCamera>>, TError = AxiosError<HTTPValidationError>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof streamCamera>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStreamCameraQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof streamCamera>>> = ({ signal }) => streamCamera(id, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof streamCamera>>, TError, TData> & { queryKey: QueryKey }
}

export type StreamCameraQueryResult = NonNullable<Awaited<ReturnType<typeof streamCamera>>>
export type StreamCameraQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Stream camera video
 */
export const useStreamCamera = <TData = Awaited<ReturnType<typeof streamCamera>>, TError = AxiosError<HTTPValidationError>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof streamCamera>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getStreamCameraQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




