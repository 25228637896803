/* eslint-disable */
import { Grid, MobileStepper, Typography } from '@mui/material';
import { useCreateConfiguration } from 'api/sdk';
import { Button } from 'components';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Analytics } from 'schemas';
import * as Yup from 'yup';

import { TextField } from 'components/Form';
import InputsCheck from 'components/Form/Inputs/InputsCheck';
import Stepper from 'components/Stepper';
import {
  EmployeeProductivityIcon,
  InventoryManagementIcon,
  ProductThroughputIcon,
  SafetyGearMonitor,
  SecurityIcon,
  WorkflowMonitorIcon
} from 'components/icons';

import { convertServerErrors } from 'utils/form';
import ProcessControlIcon from 'components/icons/ProcessControlIcon';
import QualityControl from 'components/icons/qualityControl';
import { useSnack } from 'plugins/snack';

interface IProps {
  initialValues: IformValues;
  onSubmit: (values: IformValues) => void;
  onCancel: () => void;
}

interface IformValues {
  config_name: string;
  number_of_cameras: number | null;
  analysisOptions: string[];
}
const validationSchema = Yup.object().shape({
  config_name: Yup.string()
    .trim()
    .required('Configuration name is required')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])[a-zA-Z0-9\W_]+$/, // Updated regex pattern
      'Invalid characters. Input should be Alphanumeric'
    )
    .min(4, 'Minimum value is 4')
    .max(40, 'Maximum value is 40'),
  number_of_cameras: Yup.number()
    .typeError('Number of cameras must be a number')
    .required('Number of cameras is required')
    .min(1, 'Minimum value is 1')
    .max(100, 'Maximum value is 100'),
  analysisOptions: Yup.array().min(1, 'Select at least one option')
});

const analysisOptions = [
  {
    label: 'Employee Productivity',
    value: 'employee_productivity',
    icon: <EmployeeProductivityIcon />,
    disabled: true
  },
  {
    label: 'Product Throughput',
    value: 'product_throughput',
    icon: <ProductThroughputIcon />,
    disabled: true
  },
  {
    label: 'Workflow Monitoring',
    value: 'workflow_monitoring',
    icon: <WorkflowMonitorIcon />
  },
  {
    label: 'SafetyGear Monitoring',
    value: 'safety_gear_monitoring',
    icon: <SafetyGearMonitor />,
    disabled: true
  },
  {
    label: 'Inventory Management',
    value: 'inventor_management',
    icon: <InventoryManagementIcon />,
    disabled: true
  },
  {
    label: 'Security',
    value: 'security',
    icon: <SecurityIcon />,
    disabled: true
  },
  {
    label: 'Process Control',
    value: 'process_control',
    icon: <ProcessControlIcon />,
    disabled: true
  },
  {
    label: 'Quality Control',
    value: 'quality_control',
    icon: <QualityControl />,
    disabled: true
  }
];

const CameraNameForm: React.FC<IProps> = ({
  onSubmit,
  initialValues,
  onCancel
}) => {
  const { mutateAsync, isLoading } = useCreateConfiguration();
  const navigate = useNavigate();
  const snack = useSnack();

  const handleFormSave = async (
    values: IformValues,
    formikHelpers: FormikHelpers<IformValues>
  ) => {
    await mutateAsync({
      data: {
        config_name: values.config_name,
        number_of_cameras: 0,
        analytics: values.analysisOptions.map(option => option as Analytics)
      }
    })
      .then(response => {
        snack({
          message: 'Configuration created successfully',
          severity: 'success'
        });
        onSubmit(values);
      })
      .catch(err => {
        if (err.response.status === 422) {
          formikHelpers.setErrors(
            convertServerErrors(err.response.data.detail)
          );
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleFormSave}
    >
      {({ handleSubmit, errors, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              {/* Row Start */}
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body2">Configuration Name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="config_name"
                    placeholder="Enter Configuration Name"
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Row End */}

            {/* Row Start */}
            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body2">Number of Cameras</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="number_of_cameras"
                    placeholder="Enter Number of Cameras"
                    type="number"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item xs={12} mt={2}>
              <InputsCheck
                options={analysisOptions}
                values={values.analysisOptions}
                onChange={(values: string[], currentValue: string): void => {
                  setFieldValue('analysisOptions', values);
                }}
              />
              {errors.analysisOptions && (
                <Typography
                  variant="body1"
                  color="error"
                  width={'100%'}
                  align="center"
                  fontSize={14}
                >
                  {errors.analysisOptions}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px'
              }}
              padding={2}
            >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  onCancel();
                }}
                version="light"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                loading={isLoading}
              >
                {isLoading ? 'Creating...' : 'Create'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CameraNameForm;
