import { generatePath } from 'react-router-dom';

export default {
  REGISTER_ADMIN: {
    route: '/auth/register',
    generate() {
      return generatePath(this.route);
    }
  },
  LOGIN: {
    route: '/auth/login',
    generate() {
      return generatePath(this.route);
    }
  },
  RESET_PASSWORD: {
    route: '/auth/reset-password',
    generate() {
      return generatePath(this.route);
    }
  }
};
