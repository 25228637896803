import { Field, FieldProps } from 'formik';
import React from 'react';

import { SelectInput, ISelectInputProps } from '../Inputs/SelectInput';

export const SelectField: React.FC<ISelectInputProps> = ({
  name,
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <SelectInput
          name={name}
          {...props}
          value={field.value}
          placeholder={placeholder}
          onChange={value => {
            form.setFieldValue(name, value);
            if (onChange) {
              onChange(value);
            }
          }}
          error={form.errors[name]?.toString()}
        />
      )}
    </Field>
  );
};
