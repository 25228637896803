import { EmotionCache } from '@emotion/cache';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { FunctionComponent, PropsWithChildren, useMemo } from 'react';

import LIGHT_THEME from './light';

// Client-side cache, shared for the whole session of the user in the browser.

interface Props extends PropsWithChildren {
  emotionCache?: EmotionCache; // You can omit it if you don't want to use Emotion styling library
}

/**
 * Renders composition of Emotion's CacheProvider + MUI's ThemeProvider to wrap content of entire App
 * The Light or Dark themes applied depending on global .darkMode state
 * @param {EmotionCache} [emotionCache] - shared Emotion's cache to use in the App
 */
const AppThemeProvider: FunctionComponent<Props> = ({ children }) => {
  const theme = useMemo(() => createTheme(LIGHT_THEME), []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline /* MUI Styles */ />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;
