import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LoginResponseSchema, ProfileSchema } from 'schemas';

import type { RootState } from './store';

export interface AuthState {
  profile: ProfileSchema | null;
  token: string | null;
  isLoggedIn: boolean;
  loading: boolean;
}

const initialState: AuthState = {
  profile: null,
  token: null,
  isLoggedIn: false,
  loading: true
};

// Create a slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<LoginResponseSchema>) => {
      state.token = action.payload.access_token;
      state.profile = action.payload.profile;
      state.isLoggedIn = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logout: state => {
      localStorage.removeItem('token');
      state.profile = null;
      state.token = null;
      state.isLoggedIn = false;
    }
  }
});

// Export actions
export const {
  setAuthState,
  setLoading: setAuthLoading,
  logout
} = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.profile;
export const selectToken = (state: RootState) => state.auth.token;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectSite = (state: RootState) =>
  state.auth.profile?.session_site;

export default authSlice.reducer;
