/* eslint-disable  */
import React from 'react';

interface IAddconfigIconProps {
  height?: number;
  width?: number;
}

const AddconfigIcon: React.FC<IAddconfigIconProps> = ({
  height = 60,
  width = 60
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
        viewBox="0 0 90 90"
      >
        <g
          id="Group_56615"
          data-name="Group 56615"
          transform="translate(-966.5 -423.5)"
        >
          <circle
            id="Ellipse_4971"
            data-name="Ellipse 4971"
            cx="35"
            cy="35"
            r="35"
            transform="translate(976 431)"
            fill="#102633"
          />
          <g
            transform="matrix(1, 0, 0, 1, 966.5, 423.5)"
            filter="url(#Icon_ionic-ios-add-circle)"
          >
            <path
              id="Icon_ionic-ios-add-circle-2"
              data-name="Icon ionic-ios-add-circle"
              d="M36.875,1.875a35,35,0,1,0,35,35A34.995,34.995,0,0,0,36.875,1.875ZM52.1,39.567H39.567V52.1a2.692,2.692,0,0,1-5.385,0V39.567H21.647a2.692,2.692,0,1,1,0-5.385H34.183V21.647a2.692,2.692,0,1,1,5.385,0V34.183H52.1a2.692,2.692,0,0,1,0,5.385Z"
              transform="translate(8.13 5.13)"
              fill="#fff"
              stroke="#102633"
              strokeWidth="2"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default AddconfigIcon;
