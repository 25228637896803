import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const SafeIcon: React.FC<IProps> = ({
  height = 50,
  width = 50
  // color = '#fff'
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 66.077 77.246"
    >
      <g
        id="Group_60987"
        data-name="Group 60987"
        transform="translate(12688.006 -2537)"
      >
        <g id="check-circle-svgrepo-com" transform="translate(-12682.472 2537)">
          <path
            id="Path_67207"
            data-name="Path 67207"
            d="M29.594,2A27.594,27.594,0,1,1,2,29.594,27.625,27.625,0,0,1,29.594,2Zm0,49.67A22.075,22.075,0,1,0,7.519,29.594,22.1,22.1,0,0,0,29.594,51.67Z"
            transform="translate(-2 -2)"
            fill="#102633"
          />
          <path
            id="Path_67208"
            data-name="Path 67208"
            d="M18.038,30.075a2.759,2.759,0,0,1-1.856-.718L7.9,21.832a2.759,2.759,0,1,1,3.712-4.084l6.147,5.588L29.715,8.993a2.759,2.759,0,1,1,4.24,3.533l-13.8,16.557a2.759,2.759,0,0,1-1.929.986Q18.133,30.075,18.038,30.075Z"
            transform="translate(6.797 8.556)"
            fill="#102633"
          />
        </g>
        <g
          id="Group_60987-2"
          data-name="Group 60987"
          transform="translate(-12688.006 2579.302)"
        >
          <path
            id="Path_67207-2"
            data-name="Path 67207"
            d="M2.207,0h18.12a2.207,2.207,0,0,1,2.207,2.208V25.68a2.206,2.206,0,0,1-3.615,1.7l-7.651-6.353L3.618,27.378A2.208,2.208,0,0,1,0,25.68V2.208A2.209,2.209,0,0,1,2.207,0ZM18.12,4.416H4.414V20.977l5.443-4.519a2.206,2.206,0,0,1,2.819,0l5.443,4.519Z"
            transform="matrix(0.899, 0.438, -0.438, 0.899, 12.225, 0)"
            fill="#102633"
          />
          <path
            id="Path_67208-2"
            data-name="Path 67208"
            d="M20.327,27.484H2.208A2.208,2.208,0,0,1,0,25.277V2.208A2.208,2.208,0,0,1,3.6.5l7.664,6.255L18.931.5a2.208,2.208,0,0,1,3.6,1.71V25.277A2.208,2.208,0,0,1,20.327,27.484ZM4.415,23.069h13.7V6.859l-5.456,4.453a2.208,2.208,0,0,1-2.792,0L4.415,6.859Z"
            transform="matrix(-0.899, 0.438, -0.438, -0.899, 66.077, 24.703)"
            fill="#102633"
          />
        </g>
      </g>
    </svg>
  );
};

export default SafeIcon;
