import { VideosSchema, VideosSchemaFailReason } from 'schemas';

export enum VideoStatus {
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export interface Videos extends VideosSchema {
  status: VideoStatus;
  fail_reason: VideosSchemaFailReason;
  file?: File;
  upload_progress?: number;
  process_progress?: number;
  output_url?: string;
}

export interface VideoInferenceState {
  videos: Videos[];
  loading: boolean;
  currentConfigId?: string;
  error: string | null;
}
