/* eslint-disable */
import { Typography } from 'components/Typography/Typography';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import {
  CircularProgress,
  IconButton,
  Stack,
  styled,
  TextField,
  Tooltip
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

interface EditableLabelProps {
  value: string;
  onChange: (value: string) => void;
  loading?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  error?: string;
  isEditing: boolean;
  gridView?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  fontSize?: number;
  inputError?: string | null;
  isRowEditing?: boolean;
  setIsRowEditing?: any;
}

const StyledInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: '12px',
    padding: '3px',
    paddingLeft: '5px'
  }
}));

const StyledIconButton = styled(IconButton)({
  padding: '2px 2px 1px 0px',
  '& svg': {
    fontSize: '20px',
    fill: '#000'
  }
});

const EditableLabel: React.FC<EditableLabelProps> = ({
  value,
  onChange,
  onCancel,
  loading = false,
  error,
  isEditing,
  gridView,
  setIsEditing,
  onSave,
  fontSize,
  isRowEditing,
  setIsRowEditing,
  inputError
}) => {
  // const displayValue = value.length > 8 ? `${value.slice(0, 8)}...` : value;
  const displayValue = value;

  return (
    <>
      {isEditing ? (
        <>
          <Stack direction="row" spacing={0}>
            <Stack direction="column">
              <StyledInput
                size="small"
                style={{
                  width: '280px',
                  fontSize: '14px',
                  color: '#102633',
                  fontWeight: 400
                }}
                value={value}
                onChange={e => onChange(e.target.value)}
                disabled={loading}
              />
              {inputError && (
                <p style={{ color: 'red', fontSize: '10px' }}>{inputError}</p>
              )}
            </Stack>

            <div
              style={
                !gridView ? { marginRight: !gridView ? '-3vw' : '' } : undefined
              }
            >
              <form
                onSubmit={onSave}
                style={
                  !gridView ? { marginLeft: !gridView ? '1vw' : '' } : undefined
                }
              >
                <Stack direction="row" spacing={1}>
                  {loading ? (
                    <CircularProgress
                      style={{
                        height: 15,
                        width: 15
                      }}
                    />
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <StyledIconButton size="medium" onClick={onCancel}>
                        <HighlightOffIcon />
                      </StyledIconButton>

                      <StyledIconButton size="medium" onClick={onSave}>
                        <SaveOutlinedIcon />
                      </StyledIconButton>
                    </Stack>
                  )}
                </Stack>
              </form>
            </div>
          </Stack>
        </>
      ) : (
        <>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography
              color={'#102633'}
              style={{
                fontSize: '14px',
                color: '#102633',
                fontWeight: 300,
                maxWidth: '100%',
                whiteSpace: 'nowrap'
              }}
              sx={{ color: '#102633' }}
            >
              {displayValue.length > 40
                ? `${displayValue.slice(0, 40)}...`
                : displayValue}
            </Typography>

            <FontAwesomeIcon
              icon={faEdit}
              style={{
                opacity: isRowEditing ? 0.6 : 1,
                cursor: isRowEditing ? 'not-allowed' : 'pointer'
              }}
              onClick={() => {
                if (!isRowEditing) {
                  setIsRowEditing(true);
                  setIsEditing(true);
                }
              }}
            />
          </Stack>
        </>
      )}
    </>
  );
};
export default EditableLabel;
