import { Field, FieldProps } from 'formik';
import React from 'react';

import { TextInput, ITextInputProps } from '../Inputs/TextInput';

export const TextField: React.FC<ITextInputProps> = ({
  name,
  light,
  onChange,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <TextInput
          name={name}
          light={light}
          {...props}
          value={field.value}
          onChange={value => {
            form.setFieldValue(name, value);
            if (onChange) {
              onChange(value);
            }
          }}
          error={form.errors[name]?.toString()}
          helperText={form.errors[name]?.toString()}
        />
      )}
    </Field>
  );
};
