import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Delete from '../icons/deleteIcon.svg';
import User from '../icons/userprofile.svg';
import { useDeleteMember } from 'api/sdk';
import { useConfirm } from 'plugins/confirm';

interface ProfileDetailsProps {}

const ProfileDetails: React.FC<ProfileDetailsProps> = () => {
  // Select profile information from the Redux store
  const profile = useSelector((state: RootState) => state.auth.profile);
  const { isLoading, mutateAsync } = useDeleteMember();
  const confirm = useConfirm();
  const matchesLg = useMediaQuery('(min-width:960px)');

  const handleDeleteClick = () => {
    confirm({
      title: 'Delete Account',
      content: 'Are you sure you want to delete the account?'
    }).then(() => {
      mutateAsync({
        data: {
          user_id: profile?.id || ''
        }
      }).then(() => {
        localStorage.removeItem('token');
        window.location.reload();
      });
    });
  };

  return (
    <Stack direction="column" gap={1} width={'100%'}>
      <Stack
        width={'100%'}
        alignItems={'flex-start'}
        justifyContent="space-between"
        direction="row"
        gap={2}
      >
        <img src={User} alt="user_image" />
        <Stack direction={'column'} gap={1} flex={1}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            gap={2}
          >
            <Typography
              sx={{
                width: '30%',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Name:
            </Typography>
            <Typography>{profile?.name}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            gap={2}
          >
            <Typography
              sx={{
                width: '30%',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Email ID:
            </Typography>
            <Typography>{profile?.email}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            gap={2}
          >
            <Typography
              sx={{
                width: '30%',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Organization:
            </Typography>
            <Typography>{profile?.organization?.name}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            gap={2}
          >
            <Typography
              sx={{
                width: '30%',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Role:
            </Typography>
            <Typography>{profile?.role}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            gap={2}
          >
            <Typography
              sx={{
                width: '30%',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Site:
            </Typography>
            <Typography>{profile?.session_site?.name}</Typography>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          alignItems="flex-end"
          height="100%"
          justifyContent="flex-end"
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<img src={Delete}></img>}
            style={{
              width: '200px'
            }}
            onClick={handleDeleteClick}
          >
            {'Delete My Account'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileDetails;
