import { Alert, Stack } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';

import { useLogin } from 'api/sdk';
import { Button, Typography } from 'components';
import { TextField } from 'components/Form';
import routes from 'routes/urls';
import { Snack } from 'plugins/snack/Snack';
import Swal from 'sweetalert2';

interface ILoginFormValues {
  username: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const loginFormInitialValue: ILoginFormValues = {
    username: '',
    password: ''
  };

  const { mutateAsync, error, isError, isLoading, reset } = useLogin();
  useEffect(() => {
    function getBrowser() {
      const userAgent = navigator.userAgent.toLowerCase();

      if (
        /chrome/.test(userAgent) &&
        !/edg/.test(userAgent) &&
        !/opr/.test(userAgent) &&
        !/brave/.test(userAgent) &&
        !/vivaldi/.test(userAgent)
      ) {
        return 'Chrome';
      }

      if (/edg/.test(userAgent)) {
        return 'Edge';
      }

      if (/firefox/.test(userAgent)) {
        return 'Firefox';
      }

      if (/opr/.test(userAgent)) {
        return 'Opera';
      }

      if (/brave/.test(userAgent)) {
        return 'Brave';
      }

      if (/vivaldi/.test(userAgent)) {
        return 'Vivaldi';
      }

      if (/mozilla/.test(userAgent) && !/firefox/.test(userAgent)) {
        return 'Mozilla';
      }

      return 'Unknown';
    }

    const browser = getBrowser();

    if (browser !== 'Unknown') {
      if (browser !== 'Chrome') {
        Swal.fire({
          position: 'top-end',
          title: 'Browser Detected',
          text: `You are using ${browser} browser, few features might not work seamlessly, for better experience please use Chrome.`,
          icon: 'warning',
          imageHeight: '10px',
          width: '600px',
          background: '000000',
          showCloseButton: true
        });
      }
    } else {
      Swal.fire({
        position: 'top-end',
        title: 'Browser not detected',
        text: 'Could not detect the browser, few features might not work seamlessly, for better experience use Chrome.',
        icon: 'error',
        imageHeight: '100px',
        imageWidth: '200px',
        background: '000000',
        showCloseButton: true
      });
    }
  }, []);

  useEffect(() => {
    // Reset error after 10 seconds
    setTimeout(() => {
      reset();
    }, 10000);
  }, [isError, reset]);

  return (
    <Formik<ILoginFormValues>
      initialValues={loginFormInitialValue}
      onSubmit={values => {
        mutateAsync({
          data: {
            username: values.username,
            password: values.password
          }
        })
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('token', response.data.access_token);
              window.location.reload();
            }
          })
          .catch(response => {
            Snack({
              message: response?.response?.data?.detail,
              severity: 'error'
            });
          });
      }}
      validationSchema={validationSchema}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3} style={{ marginTop: 50 }}>
            <>
              <TextField
                name="username"
                placeholder="Enter Username / Email"
                label="Username"
                type="text"
                required
                boootstrap
                light
              />
              <TextField
                name="password"
                placeholder="Enter Password"
                label="Password"
                type="password"
                required
                boootstrap
                light
              />
              <Typography variant="body2" color="red" align="right">
                {isError && error.response?.status !== 422 ? (
                  <Alert severity="error">
                    {String(error.response?.data.detail)}
                  </Alert>
                ) : (
                  ' '
                )}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ color: '#fff' }}
              >
                <a
                  href={'#'}
                  onClick={e => {
                    e.preventDefault();
                    navigate(routes.RESET_PASSWORD.generate());
                  }}
                  style={{ color: '#fff' }}
                >
                  Forgot password?
                </a>
              </Typography>
              <Button type="submit" actionType="positive" disabled={isLoading}>
                {isLoading ? 'Logging in...' : 'Login'}
              </Button>
              <Typography variant="body2" color="white" align="center">
                Don&apos;t have an Admin Account?{' '}
                <b>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      navigate(routes.REGISTER_ADMIN.generate());
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Create Admin Account
                  </a>
                </b>
              </Typography>
            </>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default LoginPage;
