import React from 'react';
import { useWorkflow } from 'hooks/config/useWorkflow';
import { Button, Card, Modal } from 'components';
import { Box, Grid, Stack, Typography } from '@mui/material';
import DragAndDrop from './DragAndDrop';
import TrainSummaryModal from './summaryModal';
import { useSnack } from 'plugins/snack';
import { useNavigate } from 'react-router-dom';
import DependancyGraph from './dependancyGraph';

interface WorkflowMonitorProps {
  onCancel: () => void;
  onSave: (report: string) => void;
}

const WorkflowMonitor: React.FC<WorkflowMonitorProps> = ({
  onCancel,
  onSave
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [trainingStarted, setTrainingStarted] = React.useState(false);
  const navigate = useNavigate();

  const snack = useSnack();

  const {
    rows,
    setRows,
    setActiveId,
    activeId,
    addActivity,
    handleChangeDependency,
    isLoading,
    handleSort,
    handleSave,
    activities,
    id: configId,
    cameras,
    train,
    trainLoading,
    trainError
  } = useWorkflow();

  const handleTrain = async (model_name: string, epochs: number) => {
    handleSave()
      .then(() => {
        train({
          configId: configId || '',
          data: {
            model_name,
            epochs,
            workflow_config: (rows as any)?.data || []
          }
        })
          .then(() => {
            setTrainingStarted(true);
            snack({
              message: 'Model training started successfully',
              severity: 'success'
            });

            setTimeout(() => {
              navigate('/configuration');
            }, 4000);
          })
          .catch(error => {
            console.log('error', error);
            snack({
              message: error?.response?.data?.detail,
              severity: 'error'
            });
          });
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  return (
    <>
      <Card title="Workflow Monitoring" titleMargin={'0px'}>
        <Stack direction={'column'}>
          <Grid container py={3}>
            <DragAndDrop
              rows={rows?.data || []}
              activeId={activeId}
              setActiveId={setActiveId}
              addActivity={addActivity}
              setRows={setRows}
              handleChangeDependency={handleChangeDependency}
              selectedCameraID=""
              activities={activities}
              handleSort={handleSort}
            />
          </Grid>
        </Stack>
      </Card>

      <Stack direction={'row'} justifyContent={'center'} spacing={3} mt={3}>
        <Button version="light" onClick={onCancel}>
          Back
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading || !rows?.data?.length}
          onClick={() => {
            handleSave().then(() => {
              snack({
                message: 'Workflow saved successfully',
                severity: 'success'
              });
            });
          }}
        >
          Save
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading || !rows?.data?.length}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Continue
        </Button>
      </Stack>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        title={
          !trainingStarted ? (
            <Typography
              width={'100%'}
              textAlign={'center'}
              style={{ fontWeight: 'bold' }}
            >
              Summary
            </Typography>
          ) : (
            ''
          )
        }
        size="md"
      >
        <TrainSummaryModal
          config_id={configId || ''}
          activities={activities}
          camera_length={cameras?.length || 0}
          workflow_data={rows?.data || []}
          onSumbit={handleTrain}
          loading={trainLoading || isLoading}
          trainingStarted={trainingStarted}
        />
      </Modal>
    </>
  );
};

export default WorkflowMonitor;
