import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { PublicLayout } from 'layouts';
import {
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  ValidateRegisterPage
} from 'pages/auth';
import NotFound from 'pages/errors/notFound.tsx';
import { RootState } from 'store';

/**
 * List of routes available for anonymous users
 * Also renders the "Public Layout" composition
 * @routes PublicRoutes
 */
const PublicRoutes = () => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Route to dashboard if token is valid
   * @param {any} (
   * @returns {any}
   */
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/configuration');
    }
    if (!isLoggedIn && !location.pathname.startsWith('/auth')) {
      navigate('/auth/login');
    }
  }, []);

  return (
    <PublicLayout>
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/auth/register" element={<RegisterPage />} />
        <Route
          path="/auth/validate-register"
          element={<ValidateRegisterPage />}
        />
        <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </PublicLayout>
  );
};

export default PublicRoutes;
