/* eslint-disable */
export const deepMerge: (target: any, source: any) => any = (
  target,
  source
) => {
  const isObject = (obj: any): obj is object => obj && typeof obj === 'object';

  const merged: any = {};
  for (const key in target) {
    merged[key] =
      isObject(target[key]) && isObject(source[key])
        ? deepMerge(target[key], source[key])
        : source.hasOwnProperty(key)
        ? source[key]
        : target[key];
  }

  for (const key in source) {
    if (!merged.hasOwnProperty(key)) {
      merged[key] = source[key];
    }
  }

  return merged;
};
