/* eslint-disable  */

import { useState, useCallback, useEffect } from 'react';

export const useWebSocket = (url: string) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<
    'connecting' | 'open' | 'closed'
  >('connecting');
  const [messages, setMessages] = useState<any>();

  const sendMessage = useCallback(
    (message: string) => {
      if (socket) {
        socket.send(message);
      }
    },
    [socket]
  );

  useEffect(() => {
    const newSocket = new WebSocket(url);
    setSocket(newSocket);

    return () => newSocket.close();
  }, [url]);

  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        setConnectionStatus('open');
        setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send('ping');
          }
        }, 5000);
      };

      socket.onclose = () => {
        setConnectionStatus('closed');
      };

      socket.onmessage = event => {
        const incomingMessage = event.data;
        setMessages(incomingMessage);
      };

      return () => {
        socket.close();
      };
    }
  }, [socket]);

  return {
    socket,
    connectionStatus,
    sendMessage,
    messages
  };
};
