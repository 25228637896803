// Dependency imports
import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

// Local imports
import Cameras from './camera/camera';
import { Card } from 'components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const Settings = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Card>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Cameras"
                sx={{ fontSize: '14px', fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Cameras />
          </CustomTabPanel>
        </Box>
      </Card>
    </div>
  );
};

export default Settings;
