import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { TextField } from 'components/Form';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { convertServerErrors } from 'utils/form';
import { Table, Typography, ITableHeadColumn } from 'components';
import { useSnack } from 'plugins/snack';
import { getViewerProfile, useDeleteMember, useRegisterMember } from 'api/sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@mui/icons-material/Delete';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useGetViewerProfile } from 'hooks/slurry/useViewerProfile';
import moment from 'moment';
import { FORMAT_DATE_TIME, dateToString, FORMAT_DATE } from 'utils';
import { useConfirm } from 'plugins/confirm';

interface IChangeViewerFormProps {}

interface IChangeViewerFormValues {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
}

const initialValues: IChangeViewerFormValues = {
  name: '',
  email: '',
  password: '',
  confirm_password: ''
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('password')],
    'Passwords must match'
  )
});

export const ListViewerForm: React.FC<IChangeViewerFormProps> = () => {
  const { data, refetch, isLoading } = useGetViewerProfile();
  const {
    mutateAsync,
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete
  } = useDeleteMember();

  useEffect(() => {
    refetch();
  }, []);

  const snack = useSnack();
  const confirm = useConfirm();

  const handleDelete = async (config: string) => {
    const configId = config?.toString() ?? '';

    mutateAsync({
      data: { user_id: configId }
    })
      .then((res: any) => {
        snack({
          message: 'Configuration deleted successfully',
          severity: 'success'
        });
        refetch();
      })
      .catch((err: any) => {
        snack({
          message: 'Failed to  delete Configuration',
          severity: 'error'
        });
      });
  };

  // Handle Delete Click
  const handleDeleteClick = (recordId: string, recordName: string) => {
    confirm({
      title: 'Delete Configuration',
      content: `Are you sure you want to delete ${recordName} ?`
    })
      .then(() => {
        handleDelete(recordId); // Call handleDelete if the user confirms
      })
      .catch(() => {
        // Do nothing if the user cancels
      });
  };

  const columns: ITableHeadColumn[] = [
    {
      value: 'Name',
      key: 'name',
      render: ({ value }) => {
        if (typeof value === 'string') {
          const truncatedName =
            value.length > 15 ? `${value.slice(0, 15)}...` : value;
          return (
            <Tooltip title={value.length > 15 ? value : ''}>
              <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                {truncatedName}
              </Typography>
            </Tooltip>
          );
        }
        return null; // Or handle other types appropriately
      }
    },
    {
      value: 'Email ID',
      key: 'email'
    },
    {
      value: 'Created Date',
      key: 'created_at'
    },
    {
      value: 'Latest Login Date',
      key: 'latest-login'
    },
    {
      value: 'Actions',
      key: 'actions',
      render: ({ value, record }) => {
        return (
          <IconButton
            onClick={() =>
              record &&
              typeof record?.id === 'string' &&
              typeof record?.name === 'string' &&
              handleDeleteClick(record.id, record?.name)
            }
          >
            <DeleteIcon fontSize="small" color="primary" />
          </IconButton>
        );
      }
    }
  ];
  const rows =
    data?.map((item, index) => ({
      name: item.username,
      email: item.email,
      id: item.id,
      created_at: moment(item.created_at).format(FORMAT_DATE),
      'latest-login': item.last_login
        ? moment(item.last_login).format(FORMAT_DATE)
        : 'No Date '
    })) || [];
  return (
    <>
      {/* Viewer List */}
      <Grid
        item
        xs={12}
        mb={4}
        style={{ maxHeight: '200px', overflow: 'auto' }}
      >
        <div style={{ maxHeight: '100%', overflow: 'auto' }}>
          <Table
            columns={columns}
            rows={rows}
            // expandedRowRender={record => (
            //   <ExpandableList
            //     record={record.cameras as ConfigurationCameraSchema[]}
            //     configId={record.id as string}
            //   />
            // )}
            striped={true}
          />
        </div>
      </Grid>
    </>
  );
};
