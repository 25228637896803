// Dependency imports
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Stack, Typography, IconButton, Slider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

// Local imports
import { Modal } from 'components';
import { NotificationList } from 'pages/workFlowAnnotation/notificationList';

interface InferenceVideoProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  start: number;
  end: number;
  videoRef: React.RefObject<HTMLVideoElement>;
  getVideo: string;
  videoQuery: any;
  setStart: any;
  seek: any;
  videoUrl: string;
  videoName: string;
}

const InferenceVideo: React.FC<InferenceVideoProps> = ({
  modalOpen,
  setModalOpen,
  start,
  end,
  videoRef,
  getVideo,
  videoQuery,
  setStart,
  seek,
  videoUrl,
  videoName
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState<number>(start);
  const [videoDuration, setVideoDuration] = useState<number>(end - start);
  const videoInitialized = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastTime, setLastTime] = useState(0);
  const [isPlay, setPlay] = useState<boolean>(false);
  const [isPause, setPause] = useState<boolean>(true);

  useEffect(() => {
    const checkBuffering = () => {
      const video = videoRef.current;
      if (video) {
        if (end !== 0 && video.currentTime > end && end > lastTime) {
          // setModalOpen(false);
          setLoading(false);
          console.log('its here');
          setPlay(true);
          setPause(false);
        } else if (video.currentTime > videoDuration) {
          setLoading(false);
        } else if (
          video.currentTime === lastTime &&
          video.currentTime !== videoDuration &&
          Math.floor(video.currentTime) !== end
        ) {
          console.log('video current time', Math.floor(video.currentTime));
          console.log('video end time', end);
          setLoading(true);
        }
        setLastTime(video.currentTime);
      }
    };

    const interval = setInterval(checkBuffering, 500); // Check every 500ms

    return () => {
      clearInterval(interval);
    };
  }, [lastTime]);

  // Function to update current time on video time update
  const handleVideoTimeUpdate = (
    event: SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    setCurrentTime(event.currentTarget.currentTime);
    setLoading(false);
  };

  useEffect(() => {
    if (!modalOpen && videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
      videoInitialized.current = false;
    }
  }, [modalOpen, videoRef]);

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = start;
      setVideoDuration(videoRef.current.duration);
    }
  };

  useEffect(() => {
    return () => {
      setPlay(false);
      setPause(true);
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlay = () => {
    setPlay(false);
    setPause(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    setPlay(true);
    setPause(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    const newTime = Array.isArray(value) ? value[0] : value;

    if (newTime === videoDuration) {
      setLoading(false);
    }
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)} size="xl">
      <Stack>
        <Stack
          marginBottom={2}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography sx={{ fontSize: 22, fontWeight: 600 }}>
            {videoName}
          </Typography>
        </Stack>
        {videoUrl && (
          <video
            ref={videoRef}
            disablePictureInPicture
            src={`${videoUrl}#t=${start},${end}`}
            style={{ maxWidth: '100%', maxHeight: '70vh' }}
            autoPlay
            onWaiting={() => {
              setLoading(true);
            }}
            onTimeUpdate={handleVideoTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
            onEnded={() => setModalOpen(false)}
          />
        )}
        {loading && (
          <CircularProgress
            size={60}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}
        <Stack direction={'row'} alignItems={'center'} gap={2} marginTop={3}>
          {isPlay && (
            <IconButton onClick={handlePlay}>
              <PlayArrowIcon />
            </IconButton>
          )}
          {isPause && (
            <IconButton onClick={handlePause}>
              <PauseIcon />
            </IconButton>
          )}
          <Slider
            value={currentTime}
            max={videoDuration}
            onChange={handleSliderChange}
            aria-labelledby="continuous-slider"
            style={{ width: '100%' }}
          />
        </Stack>
      </Stack>
      <Stack>
        <NotificationList
          videoQuery={videoQuery}
          selectedConfig={getVideo}
          getVideoCurrentTime={currentTime}
          setVicdeoCurrentTime={setCurrentTime}
          seek={seek}
          start={start}
        />
      </Stack>
    </Modal>
  );
};

export default InferenceVideo;
