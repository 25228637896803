/* eslint-disable */
import { useCallback, useState } from 'react';
import { getViewerProfile } from 'api/sdk';
import { UserProfileSchema } from 'schemas';

const useGetViewerProfile = () => {
    const [data, setData] = useState<UserProfileSchema[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const refetch = async () => {

        setIsLoading(true);
        try {
            const response = await getViewerProfile({

            });

            setData(response.data);
            setError(null);
        } catch (e) {
            setError(e);
            setData([]);
        } finally {
            setIsLoading(false);
        }
    };
    useCallback(refetch, []);

    return { data, refetch, isLoading, error };

}
export { useGetViewerProfile };
