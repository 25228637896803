import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps
} from '@mui/material';
import React, { PropsWithChildren } from 'react';

export type TypographyProps = MuiTypographyProps & PropsWithChildren;

export const Typography: React.FC<TypographyProps> = props => {
  return <MuiTypography {...props} />;
};
