import { styled } from '@mui/material';
import React from 'react';

import { Check } from 'components/Form';

interface ThumbnailProps {
  thumbnailUrl: string;
  title: string;
  checkable?: boolean;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
  onClick?: () => void;
}
const ImageContainer = styled('div')(({ theme }) => ({
  height: 100,
  width: 100,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  float: 'left',
  marginRight: 10,
  marginBottom: 10,
  border: `1px solid ${theme.palette.grey[300]}`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 5
  }
}));

export const Thumbnail: React.FC<ThumbnailProps> = ({
  thumbnailUrl,
  title,
  checkable,
  checked,
  onCheck,
  onClick
}) => {
  return (
    <ImageContainer>
      {checkable && (
        <Check
          checked={checked}
          onChange={() => onCheck && onCheck(!checked)}
          overlap={true}
        />
      )}
      <img src={thumbnailUrl} alt={title} draggable={false} onClick={onClick} />
    </ImageContainer>
  );
};

export default Thumbnail;
