import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const Security: React.FC<IProps> = ({
  height = 50,
  width = 50
  // color = '#fff'
}) => {
  return (
    <>
      <svg
        id="icons_Q2"
        data-name="icons Q2"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 62.105 75.912"
      >
        <path
          id="Path_64004"
          data-name="Path 64004"
          d="M37.053,9.246C46.2,11.833,56.2,14.939,61.2,16.664V43.749c0,5.865-6.383,16.216-24.152,26.567C19.284,59.792,12.9,49.614,12.9,43.749V16.664c5-1.9,15.009-4.83,24.152-7.418m0-7.246S6,10.8,6,12.351v31.4C6,59.62,28.944,73.594,35.327,77.389a3.105,3.105,0,0,0,3.45,0c6.556-3.623,29.327-17.769,29.327-33.64v-31.4C68.105,10.8,37.053,2,37.053,2Z"
          transform="translate(-6 -2)"
        />
        <path
          id="Path_64005"
          data-name="Path 64005"
          d="M23.66,39.7l-8.626-8.453a3.623,3.623,0,0,1-.345-4.658,3.278,3.278,0,0,1,5.175-.345l6.211,6.211L42.637,15.89a3.45,3.45,0,0,1,4.83,4.83L28.49,39.7a3.278,3.278,0,0,1-4.83,0Z"
          transform="translate(-0.198 7.572)"
        />
      </svg>
    </>
  );
};

export default Security;
