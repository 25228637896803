/* eslint-disable */
import { Box, Grid, IconButton, Skeleton, Stack, styled } from '@mui/material';
import { Button, Modal, Typography } from 'components';
import { useUpdateCamera } from 'hooks';
import useVideo from 'hooks/video';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileUploadResponseSchema, VideoMetadata } from 'schemas';
import { getVideoMeta, secondsToHms } from 'utils';

import EditableLabel from 'components/EditableLabel';

import { getVideoCover } from 'utils/thumbnail';

import { CloseIcon } from '../../../icons';
import UploadProgress from './UploadProgress';
import UploadZone from './uploadZone';
import DeleteDisable from 'components/icons/DeleteDisable';

const Container = styled('div')`
  width: 100%;
`;

interface VideoCompleteResponse {
  url: string;
  path: string;
  meta: VideoMetadata;
}

interface VideoUploadProps {
  name: string;
  label: string;
  cameraId: string;
  configId: string;
  initialVideo?: string | null;
  getPresignedUrlFun: (filename: string) => Promise<FileUploadResponseSchema>;
  onSelectVideo?: (file: File, local_url: String) => void;
  onUploadProgress?: (progress: number) => void;
  onUploadComplete: (data: VideoCompleteResponse) => void;
  onDelete?: () => void;
  deletable?: boolean;
  showBtn?: boolean;
  gridView?: boolean;
  toggleDisable: (value: boolean) => void;
  metadata?: VideoMetadata | null;
}

const ErrorContainer = styled('div')`
  color: red;
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff000010;
  border: 1px solid red;
  padding: 10px;
  text-align: center;
`;

const VideoPreviewContainer = styled('div')<{ hidden?: boolean }>`
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ hidden }) => hidden && 'display: none;'}
`;

const VideoUpload: React.FC<VideoUploadProps> = ({
  name,
  label,
  initialVideo,
  getPresignedUrlFun,
  onSelectVideo,
  onUploadComplete,
  onDelete,
  deletable = false,
  toggleDisable,
  cameraId,
  gridView,
  configId,
  metadata,
  showBtn
}) => {
  const [error, setError] = React.useState<string | undefined>();
  const videoContainerRef = React.useRef<HTMLDivElement>(null);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const {
    videoRef,
    duration,
    resolution: { height, width },
    videoUrl,
    reset
  } = useVideo(initialVideo || null);

  const {
    value: labelValue,
    reset: resetLabel,
    onChange: handleLabelChange,
    isLoading: isLabelLoading,
    error: labelError,
    isEditing: isLabelEditing,
    setIsEditing: setLabelEditing,
    updateCamera: updateCameraName
  } = useUpdateCamera({
    cameraId,
    configId,
    label,
    roi: null,
    crops: null
  });

  const [status, setStatus] = useState<'idle' | 'uploading' | 'done' | 'fail'>(
    videoUrl ? 'done' : 'idle'
  );
  const [cover, setCover] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const controllerRef = React.useRef<AbortController | null>(null);
  const xhrRef = React.useRef<XMLHttpRequest | null>(null);

  const handleOnChange = (file: File) => {
    setStatus('uploading');
    toggleDisable(true);
    setUploadProgress(0);

    const localUrl = URL.createObjectURL(file);
    if (onSelectVideo) {
      onSelectVideo(file, localUrl);
    }
    reset(localUrl);

    getPresignedUrlFun(file.name)
      .then(res => {
        const url = res.signed_url;

        const formData = new FormData();
        formData.append('file', file);

        const xhr = new XMLHttpRequest();
        const controller = new AbortController();

        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.upload.onprogress = e => {
          if (e.lengthComputable) {
            const progress = (e.loaded / e.total) * 100;
            setUploadProgress(progress);
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            setStatus('done');

            const videoMeta: VideoMetadata = {
              duration: videoRef.current
                ? parseInt(videoRef.current.duration.toString(), 10)
                : 0,
              resolution: videoRef.current
                ? [videoRef.current.videoWidth, videoRef.current.videoHeight]
                : [0, 0]
            };
            onUploadComplete({ url: res.url, path: res.path, meta: videoMeta });
            setUploadProgress(100);
          } else {
            setError('Failed to upload video');
            setStatus('fail');
          }
        };

        xhr.onerror = () => {
          setError('Something went wrong while uploading video.');
          setStatus('fail');
        };

        xhr.send(file);

        xhrRef.current = xhr;
        controllerRef.current = controller;
      })
      .catch(() => {
        setError('Something went wrong while uploading video.');
        setStatus('fail');
      });
  };

  const handleCancel = () => {
    if (xhrRef.current) {
      xhrRef.current.abort();
      setStatus('idle');
      setUploadProgress(0);
      toggleDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [videoUrl]);

  const handleDelete = () => {
    URL.revokeObjectURL(videoUrl || '');
    if (onDelete) {
      onDelete();
    }
  };

  useEffect(() => {
    if (videoUrl) {
      getVideoCover(videoUrl)?.then(res => {
        setCover(res);
      });
    }
  }, [videoUrl]);

  useEffect(() => {
    if (uploadProgress && Math.round(uploadProgress) === 100) {
      toggleDisable(false);
    }
  }, [uploadProgress]);

  return (
    <>
      <Container ref={videoContainerRef}>
        <IconButton
          aria-label="close"
          onClick={() => {
            if (status !== 'uploading') {
              onDelete && handleDelete();
            }
          }}
          sx={{
            position: 'relative',
            top: '25px',
            left: 'calc(100% - 25px)',
            color: theme => theme.palette.grey[500]
          }}
          style={{ display: showBtn && deletable ? 'block' : 'none' }}
        >
          {status === 'uploading' ? <DeleteDisable /> : <CloseIcon />}
        </IconButton>

        {status === 'idle' && (
          <UploadZone
            showBtn={showBtn}
            deletable={deletable}
            onChange={handleOnChange}
          />
        )}

        {status === 'uploading' && (
          <UploadProgress value={uploadProgress} onCancel={handleCancel} />
        )}

        <VideoPreviewContainer hidden={status !== 'done'}>
          <Box>
            <Stack spacing={2} direction="column">
              {cover ? (
                <>
                  <img
                    src={cover || ''}
                    style={{
                      width: '100%',
                      objectFit: 'contain'
                    }}
                    draggable={false}
                    onClick={() => setModalOpen(true)}
                  />
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={videoContainerRef.current?.clientWidth}
                  height={150}
                />
              )}

              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <EditableLabel
                      gridView={gridView}
                      value={labelValue}
                      onChange={handleLabelChange}
                      loading={isLabelLoading}
                      onCancel={resetLabel}
                      onSave={updateCameraName}
                      error={labelError}
                      isEditing={isLabelEditing}
                      setIsEditing={setLabelEditing}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2" color="textPrimary">
                    {metadata
                      ? secondsToHms(metadata.duration)
                      : secondsToHms(videoRef.current?.duration || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </VideoPreviewContainer>

        {status === 'fail' && (
          <ErrorContainer>
            <Stack spacing={2} direction="column" alignItems="center">
              <Typography variant="body2">{error}</Typography>

              <Button
                variant="contained"
                color="primary"
                size="small"
                version="light"
                onClick={() => setStatus('idle')}
              >
                Retry
              </Button>
            </Stack>
          </ErrorContainer>
        )}

        <video
          controls
          ref={videoRef}
          autoPlay={false}
          muted
          disablePictureInPicture
          src=""
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            display: 'none'
          }}
        />

        <Modal open={modalOpen} onClose={() => setModalOpen(false)} size="xl">
          <video
            controls
            src={videoUrl || ''}
            autoPlay={false}
            muted
            disablePictureInPicture
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain'
            }}
          />
        </Modal>
      </Container>
    </>
  );
};
export default VideoUpload;
